import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Layouts/AppHeader/AppHeader";
import AdminHeader from "./components/Layouts/HyperTrack/AppHeader/AppHeader";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Router } from "./router/router";
import TagManager from "react-gtm-module";

function App() {
  const location = useLocation();
  const routesWithoutHeader = ["/", "/forgotpassword", "/admin"];
  const routesWithAdminHeader = [
    "/zypptracker",
    "/zypptracker/ridertrack",
    "/zypptracker/distanceReport",
    "/zypptracker/distanceReportYearWise",
  ];

  const shouldRenderHeader = !routesWithoutHeader.includes(location.pathname);
  const shouldRenderAdminHeader = routesWithAdminHeader.some((path) =>
    location.pathname.startsWith(path)
  );

  const tagManagerArgs = {
    gtmId: "G-NWTLSSYVR2",
  };
  TagManager.initialize(tagManagerArgs);
  return (
    <div className="App">
      {shouldRenderHeader && !shouldRenderAdminHeader && <Header />}
      {shouldRenderAdminHeader && <AdminHeader />}{" "}
      <div>
        <Routes>
          {Router.map((el, index) => {
            let shouldRedirect = false;
            let redirectUrl = "/";
            if (el.shouldRedirect) {
              shouldRedirect = el.shouldRedirect();
            }
            if (el.redirectUrl) {
              redirectUrl = el.redirectUrl;
            }
            return (
              <Route
                key={index}
                path={el.path}
                element={
                  <ProtectedRoute
                    shouldRedirect={shouldRedirect}
                    url={redirectUrl}
                    child={el.element}
                  ></ProtectedRoute>
                }
              >
                {el.children &&
                  el.children.map((nested, id) => {
                    return (
                      <Route
                        key={id}
                        path={nested.path}
                        element={
                          <ProtectedRoute
                            shouldRedirect={shouldRedirect}
                            url={redirectUrl}
                            child={nested.element}
                          />
                        }
                      />
                    );
                  })}
              </Route>
            );
          })}
        </Routes>
      </div>
    </div>
  );
}
interface ProtectedRouteProps {
  child: Element | JSX.Element;
  shouldRedirect: boolean;
  url: string;
  children?: any;
}

function ProtectedRoute({
  child,
  shouldRedirect,
  url = "/",
}: ProtectedRouteProps): JSX.Element {
  if (shouldRedirect) {
    return <Navigate to={url} />;
  }
  return <>{child}</>;
}

export default App;

import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router";
import { Typography, Avatar } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/system";
import { useState, useEffect } from "react";
import { ProfilePageApi } from "../../../API/ProfilePageApi";
import { MerchantShopProps } from "../../../@Types/ComponentsInterface/MerchantShopProps";

export default function Profile() {
  const navigate = useNavigate();

  const navigateToHome = () => {
    if (userRole === "ROLE_OPERATION_SUPER_ADMIN") {
      navigate("/managestore");  // Navigate to Manage Store if the user role is ROLE_OPERATION_SUPER_ADMIN
    } else {
      navigate("/Dashboard");  // Navigate to Dashboard for all other roles
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("userData");
    navigate("/");
  };

  const StyledAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: "#00CD5B",
    width: theme.spacing(14),
    height: theme.spacing(14),
    fontSize: "30px",
    fontWeight: "bold",
    color: "#FFFFFF",
    alignItems: "center",
    marginBottom: "6px",
    borderStyle: "solid",
    borderWidth: "5px",
    borderColor: "white",
    borderRadius: "50%",
    boxShadow: "0 0 0 1px #00000040",
  }));

  const [profileInfo, setProfileInfo] = useState<any>();
  const [merchantShops, setMerchantShops] = useState<any>();
  const [selectedShopId, setSelectedShopId] = useState(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [changeHubLoading, setChangeHubLoading] = useState(false);
  const [accountDeactivateLoading, setAccountDeactivateLoading] =
    useState(false);
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    ProfilePageApi.api.merchantShopApi().then((e) => {
      setMerchantShops(e.merchantShops);
    });

    ProfilePageApi.api.profileInfoApi().then((e) => {
      setProfileInfo(e);
    });
  }, []);

  const handleShopNameChange = (
    event: React.ChangeEvent<{}>,
    newValue: MerchantShopProps | null
  ) => {
    if (newValue) {
      const selectedShop = merchantShops.find(
        (shop: MerchantShopProps) => shop.shopName === newValue.shopName
      );
      if (selectedShop) {
        setSelectedShopId(selectedShop.shopId);
      }
    }
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const newValue = event.target.value;
    setNewPassword(newValue);

    if (newValue.length < 8 || newValue.length > 20) {
      setNewPassword("");
      setPasswordError("Password must be between 8 and 20 characters.");
    } else if (newValue.includes("<") || newValue.includes(">")) {
      setNewPassword("");
      setPasswordError("Password should not contain < and >.");
    } else {
      setPasswordError("");
    }
  };

  function stringToPascalCaseWithSpace(inputString: any) {
    const stringWithoutRole = inputString.replace("ROLE_", "");
    const stringWithSpaces = stringWithoutRole.replace(/_/g, " ");
    const words = stringWithSpaces.split(" ");
    const pascalCasedWords = words.map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    const pascalCasedStringWithSpace = pascalCasedWords.join(" ");
    return pascalCasedStringWithSpace;
  }

  const handleHubRequestChange = async () => {
    const profilePageApi = new ProfilePageApi();
    setChangeHubLoading(true);
    try {
      const response = await profilePageApi.changeHubRequestApi(selectedShopId);
      alert("Hub change request sent succesfully");
    } catch (error) {
      console.error("Error:", error);
      alert("Invalid Shop Id");
    } finally {
      setChangeHubLoading(false);
    }
  };
  const userRole = localStorage.getItem("userRole"); // Replace with your role-fetching logic
  const handlePasswordChangeFunction = async () => {
    const profilePageApi = new ProfilePageApi();
    setPasswordLoading(true);
    if (newPassword && newPassword !== "") {
      try {
        const response = await profilePageApi.changePasswordApi(newPassword);
        alert("Password changed Successfully");
        handleLogout();
      } catch (error) {
        console.error("Error:", error);
        alert("Error while changing password");
      } finally {
        setPasswordLoading(false);
      }
    } else {
      alert("Password could not be changed");
      setPasswordLoading(false);
    }
  };

  const handleAccountDeactivate = async () => {
    setAccountDeactivateLoading(true);
    const profilePageApi = new ProfilePageApi();
    try {
      const response = await profilePageApi.changeAccountDeactivateRequestApi();
      alert("Account deactivate request sent succesfully");
      handleLogout();
    } catch (error) {
      console.error("Error:", error);
      alert("Error while sending account deactivate request");
    } finally {
      setAccountDeactivateLoading(false);
    }
  };

  function avatarName(fullName: string | null) {
    if (fullName === null) {
      return "Default";
    }
    const names = fullName.split(" ");
    if (names.length === 1) {
      return names[0].slice(0, 1);
    } else {
      return names[0][0] + names[names.length - 1][0];
    }
  }

  return (
    <>
      <div className="bg-gradient-to-r from-slate-100 to-slate-50 pl-8 pr-8">
        <div className="border-10 bg-gradient-to-r from-slate-100 to-slate-50 ">
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
            className="bg-gradient-to-r from-slate-100 to-slate-50"
          >
            {
              <Typography
                sx={{ flex: "1 1 100%", textAlign: "left" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                <a
                  onClick={navigateToHome}
                  className="cursor-pointer text-black"
                >
                  <ArrowBackIcon className="mr-4 mb-1 ml-7" />
                </a>
                <b>Profile</b>
              </Typography>
            }
            {
              
              <Typography
                sx={{
                  flex: "1 1 100%",
                  textAlign: "right",
                  marginRight: "48px",
                }}
                component="div"
              >
                <HomeIcon className="mb-1" />
                
                {userRole !== "ROLE_OPERATION_SUPER_ADMIN" && (
    <>
      <span style={{ color: "#FF725E" }}>Dashboard</span>
      {" / "}
    </>
  )}
                {" / "}
                Profile
              </Typography>
            }
          </Toolbar>
        </div>
        <div
          className="border rounded-xl border-green-500 pt-8 m-4 ml-12 mr-12 pr-8 pb-8 mb-4"
          style={{
            background:
              "linear-gradient(to bottom, #00CD5B1A 0%, #00CD5B1A 40%, #FFFFFF 40%, #FFFFFF 100%)",
          }}
        >
          <div className="">
            <div className="flex items-center ">
              {profileInfo && profileInfo.name ? (
                <StyledAvatar aria-label="recipe" className=" p-0.5 ml-8 ">
                  {avatarName(profileInfo?.name).toUpperCase()}
                </StyledAvatar>
              ) : (
                <StyledAvatar aria-label="recipe" className=" p-0.5 ml-8 ">
                  Z
                </StyledAvatar>
              )}

              <div className="ml-4 flex mt-8">
                <div className="mt-3 ml-8">
                  {profileInfo && profileInfo.name ? (
                    <p className="font-bold text-l text-left text-xl">
                      {profileInfo?.name}
                    </p>
                  ) : (
                    <p className="font-bold text-l text-left text-xl">-</p>
                  )}
                </div>
                <div className="flex mt-4">
                  <div className="flex ml-24">
                    <div>
                      <img
                        src={"/assets/icons/mobileIcon.svg"}
                        alt=""
                        className="mt-3 mr-4 h-5"
                      />
                    </div>
                    <div>
                      <p className="text-sm font-bold text-left">Mobile</p>
                      {profileInfo && profileInfo.mobile ? (
                        <p className="text-base text-left text-slate-600">
                          {profileInfo?.mobile}
                        </p>
                      ) : (
                        <p className="text-base text-left text-slate-600">--</p>
                      )}
                    </div>
                  </div>
                  <div className="flex ml-24">
                    <div>
                      <img
                        src={"/assets/icons/mailIcon.svg"}
                        alt=""
                        className="mt-3 mr-4 h-5"
                      />
                    </div>
                    <div>
                      <p className="text-sm font-bold text-left">Email</p>
                      {profileInfo && profileInfo.email ? (
                        <p className="text-base text-left text-slate-600">
                          {profileInfo?.email}
                        </p>
                      ) : (
                        <p className="text-base text-left text-slate-600">-</p>
                      )}
                    </div>
                  </div>
                  <div className="flex ml-24">
                    <div>
                      <img
                        src={"/assets/icons/userIcon.svg"}
                        alt=""
                        className="mt-3 mr-4 h-5"
                      />
                    </div>
                    <div>
                      <p className="text-sm font-bold text-left">User Role</p>

                      {profileInfo && profileInfo.authority ? (
                        <p className="text-base text-left text-slate-600">
                          {stringToPascalCaseWithSpace(profileInfo?.authority)}
                        </p>
                      ) : (
                        <p className="text-base text-left text-slate-600">-</p>
                      )}
                      {}
                    </div>
                  </div>
                  {/* <div className="flex ml-12">
                    <div>
                      <img
                        src={"/assets/icons/locationIcon.svg"}
                        alt=""
                        className="mt-3 mr-4 h-5"
                      />
                    </div>
                    <div>
                      <p className="text-sm font-bold text-left">Location</p>
                      <p className="text-base text-left text-slate-600">
                        Sector 44, Gurugram
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border rounded-xl border-gray-300 bg-white pt-8 m-4  ml-12 mr-12 pr-8 pb-8">
          <div className="border-b-2 ml-8">
            <p className="text-left text-base font-bold ml-2 mb-2">
              Edit Details
            </p>
          </div>
          <div className="flex mt-4 ml-10 ">
            <div className="mr-56">
              <p className="text-left mb-4 text-sm font-bold">New Password</p>
              <TextField
                id="outlined-basic"
                label="New Password"
                variant="outlined"
                className="w-72"
                onChange={handlePasswordChange}
              />
              <input
                type="text"
                style={{ display: "none" }}
                autoComplete="off"
              />
            </div>
            {/* <div>
              <p className="text-left mb-4 text-sm font-bold">
                Change Hub Request
              </p>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={merchantShops}
                getOptionLabel={(option: MerchantShopProps) => option.shopName}
                className="w-72"
                onChange={handleShopNameChange}
                renderInput={(params) => (
                  <TextField {...params} label="Select Hub" />
                )}
              />
            </div> */}
            <div className="ml-72">
              <button
                className="bg-[#FE5B44] px-12 py-4 mt-8 text-white rounded-lg"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
          <p style={{ color: "red" }} className="-ml-80 mr-80">
            {passwordError}
          </p>
          <div className="flex mt-4 ml-10">
            <div className="mr-20">
              <button
                className="w-72 bg-[#FE5B44] py-4 mt-8 text-white rounded-lg"
                onClick={handlePasswordChangeFunction}
              >
                {passwordLoading ? "Loading..." : "Change Password"}
              </button>
            </div>
            {/* <div className="">
              <button
                className="w-72 ml-8 bg-[#FE5B44] w-72 py-4 mt-8 text-white rounded-lg"
                onClick={handleHubRequestChange}
              >
                {changeHubLoading ? "Loading..." : "Submit Request"}
              </button>
            </div> */}
            <div className="ml-60">
              <button
                className=" ml-48 text-right mt-8 font-bold text-slate-400"
                onClick={handleAccountDeactivate}
              >
                {accountDeactivateLoading ? "Loading..." : "Account Deactivate"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

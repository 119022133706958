import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  TicketAndGrievancesResponse,
  HubManagerListResponse,
} from "../@Types/DRO/ApiResponses";
import { CreateTicketRequestBody } from "../@Types/DTO/CreateTicket";
export class TicketAndGrievancesApi {
  public static api = new TicketAndGrievancesApi();
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: "https://stag-micro.bcykal.com/mobycy/clientDash/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("access_token");
  }

  ticketList(
    page: number,
    pageSize: number,
    status?: number,
    searchParam?: string
  ): Promise<TicketAndGrievancesResponse> {
    let apiUrl = "tickets";
    if (searchParam) {
      apiUrl += `${searchParam}`;
    }
    return this.instance
      .get<TicketAndGrievancesResponse>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          page: page,
          pageSize: pageSize,
          ...(status !== undefined && { status }),
        },
      })
      .then((e) => {
        return e.data;
      });
  }

  hubManagerListApi(): Promise<HubManagerListResponse> {
    return this.instance
      .get<HubManagerListResponse>("get/hubManager/list", {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }

  createTicketApi(
    ticketDetails: CreateTicketRequestBody
  ): Promise<AxiosResponse> {
    const data = { ticketDetails };
    const config = {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    };
    return this.instance.post("create/ticket", data, config);
  }
}

import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import TeamAndSpocsCard from "../../UI/TeamAndSpocsCards/TeamAndSpocsCard";
import { TeamAndSpocsProps } from "../../../@Types/ComponentsInterface/TeamAndSpocsProps";
import { useNavigate } from "react-router";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { TeamAndSpocsResponse } from "../../../@Types/DRO/ApiResponses";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import { TeamAndSpocsApi } from "../../../API/TeamAndSpocsApi";

export default function TeamAndSpocs() {
  const navigate = useNavigate();
  const [teamAndSpocsData, setTeamAndSpocsData] =
    useState<TeamAndSpocsResponse | null>(null);

  const [selectedCity, setSelectedCity] = useState<string | null>(null);

  useEffect(() => {
    const apiUrl = `${selectedCity ? `?city=${selectedCity}` : ""}`;

    TeamAndSpocsApi.api.teamAndSpocs(apiUrl).then((e) => {
      setTeamAndSpocsData(e);
    });
  }, [selectedCity]);
  const navigateToHome = () => {
    navigate("/Dashboard");
  };

  const groupedData: { [key: number]: TeamAndSpocsProps[] } = {};

  if (teamAndSpocsData) {
    teamAndSpocsData.teamAndSpocsData.forEach((data) => {
      const level = data.level || 0;
      if (!groupedData[level]) {
        groupedData[level] = [];
      }
      groupedData[level].push(data);
    });
  }

  const levels = Object.keys(groupedData)
    .map(Number)
    .sort((a, b) => a - b);

  const [expandedLevels, setExpandedLevels] = useState<{
    [key: number]: boolean;
  }>({});

  const toggleLevel = (level: number) => {
    setExpandedLevels((prevState) => ({
      ...prevState,
      [level]: !prevState[level],
    }));
  };

  return (
    <>
      <div style={{ height: "40px", width: "100%" }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
          className="bg-gradient-to-r from-slate-100 to-slate-50"
        >
          {
            <Typography
              sx={{ flex: "1 1 75%", textAlign: "left" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              <a onClick={navigateToHome} className="cursor-pointer">
                <ArrowBackIcon className="mr-4 mb-1 ml-7" />
              </a>
              <b>Teams & Spocs</b>
            </Typography>
          }

          {
            <Typography
              sx={{ flex: "1 1 100%", textAlign: "right", marginRight: "48px" }}
              component="div"
            >
              <HomeIcon className="mb-1" />
              {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span>{" "}
              {" / "}
              Teams & Spocs
            </Typography>
          }
        </Toolbar>
        <div className="float-right mr-8 mt-2">
          <FormControl fullWidth size="small" className="">
            <InputLabel className="bg-white" id="demo-simple-select-label">
              City
            </InputLabel>
            <Select
              className="w-32 bg-white"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="city"
              value={selectedCity || ""}
              onChange={(e) => {
                setSelectedCity(e.target.value as string | null);
              }}
            >
              <MenuItem value="">
                <em>Clear</em>
              </MenuItem>
              <MenuItem value="BLR">Bangalore</MenuItem>
              <MenuItem value="NCR">Delhi NCR</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="bg-gradient-to-r from-slate-100 to-slate-50">
          <div className="border-0 rounded-xl bg-white">
            {levels.map((level) => (
              <div className="mt-12" key={level}>
                <p className="font-semibold text-xl">Level {level}</p>
                <div />
                <div className="border-0 rounded-xl flex flex-wrap justify-evenly bg-white mt-8">
                  {groupedData[level].map(
                    (data: TeamAndSpocsProps, index: number) => (
                      <div
                        key={index}
                        className={`p-2 ${
                          level === 1 || expandedLevels[level] ? "" : "hidden"
                        }`}
                      >
                        <TeamAndSpocsCard
                          name={data.name}
                          designation={data.designation}
                          email={data.email}
                          mobile={data.mobile}
                          level={data.level}
                          hubName={data.hubName}
                        />
                      </div>
                    )
                  )}
                </div>
                {level >= 2 && (
                  <button
                    className="text-blue-500 underline mt-4"
                    onClick={() => toggleLevel(level)}
                  >
                    {expandedLevels[level] ? (
                      <CancelIcon className="text-red-500" />
                    ) : (
                      <AddIcon className="text-green-500" />
                    )}
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

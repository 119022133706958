import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Stack,
} from "@mui/material";
import { DistanceReport } from "../../../API/HyperTrack/DistanceReport";
import RangeDatePicker from "../../UI/AppDatePicker/AppDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import MapIcon from "@mui/icons-material/Map";
import DashboardFrontCardHyperTrack from "../DashboardFrontCardHyperTrack/DashboardFrontCard";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 740,
  height: 500,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "2px",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

interface CycleIdModalProps {
  cycleId: number | undefined;
}

const CycleIdModal: React.FC<CycleIdModalProps> = ({ cycleId }) => {
  const tableHeaderCellStyle = {
    fontWeight: 600,
    fontSize: "14px",
    color: "#455A64",
    font: "Rubik",
  };
  const tableBodyCellStyle = {
    fontWeight: 400,
    fontSize: "14px",
    color: "#A4A2A2",
    font: "Rubik",
  };
  const calculateRelativeDate = (days: number): Date => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - days);
    return currentDate;
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [cycleIdData, setCycleIdData] = useState<any>();
  const [startDate, setStartDate] = useState<Date | null>(
    calculateRelativeDate(8)
  );
  const [endDate, setEndDate] = useState<Date | null>(calculateRelativeDate(2));
  const [totalCount, setTotalCount] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [summary, setSummary] = useState<any>();
  const pageRange = 5;
  const handleOpen = () => {
    fetchData();
    setOpen(true);
  };
  const navigate = useNavigate();
  const handleAdd = (uid: {
    cycleId: any;
    userId: any;
    Date: any;
    row: { original: { userId: any; Date: any; cycleId: any } };
  }) => {
    //   setUserId(uid);
    navigate("/zypptracker/ridertrack", {
      state: { userid: uid.userId, date: uid.Date, cycleid: uid.cycleId },
    });
  };
  const handleDateChange = (dates: [Date | null, Date | null]) => {
    // Handle date range change
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  useEffect(() => {
    // Fetch data whenever any of the parameters change
    fetchData();
  }, [cycleId, startDate, endDate, page, rowsPerPage]);

  const fetchData = () => {
    DistanceReport.api
      .cycleIdModalData(page + 1, rowsPerPage, cycleId, startDate, endDate)
      .then((e) => {
        setCycleIdData(e.data);
        setSummary(e.summary);
        if (e.summary && typeof e.summary.total_record !== "undefined") {
          setTotalCount(e.summary.total_record);
        }
      });
  };

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(1, Math.min(newPage + 1, pageCount));
    setPage(newPageNumber - 1);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index + 1;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber - 1)}
          className={page === pageNumber - 1 ? "active" : "unactive"}
          style={{ marginLeft: "6px" }}
        >
          {pageNumber}
        </span>
      );
    });
  };
  function formatDate(inputDate: Date | undefined) {
    if (!inputDate) {
      return "N/A";
    }
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <div>
      <Link sx={{ cursor: "pointer" }} onClick={handleOpen}>
        <Typography sx={{ color: "#00CD5B" }}>{cycleId}</Typography>
      </Link>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="flex items-center mr-8 justify-between">
            <div>
              <Typography variant="h5" sx={{ m: 2, fontWeight: 400 }}>
                CycleID : {cycleId}
              </Typography>
            </div>
            <div>
              <p className="mr-2 font-semibold mb-2">Date Range</p>
              <RangeDatePicker
                onChange={handleDateChange}
                maxDaysAdd={-2}
                minDaysSub={92}
                defaultStartDate={8}
                defaultEndDate={-2}
              />
            </div>
          </div>

          {summary ? (
            <div className="flex w-full text-left my-8">
              <DashboardFrontCardHyperTrack
                fromColorClass="from-[#0CAF55]"
                toColorClass="to-[#92E3A9]"
                textFirstLine="Total Distance"
                totalcount={summary?.total_distance}
                avgcount={summary?.average_distance}
                avgtext="Avg Distance"
                shorttext="km"
                imageURL="/assets/hyper-track-icon/delivery-bike.svg"
              />
              <DashboardFrontCardHyperTrack
                fromColorClass="from-[#0CAF55]"
                toColorClass="to-[#92E3A9]"
                textFirstLine="CO2 Saved"
                avgtext="Avg Distance"
                shorttext="Kg"
                totalcount={summary?.total_carbon_saved}
                avgcount={summary?.average_carbon}
                imageURL="/assets/hyper-track-icon/world.svg"
              />
            </div>
          ) : (
            <p>No summary data available.</p>
          )}
          <>
            <TableContainer className="px-2">
              <Table>
                <TableHead>
                  <TableRow sx={{ display: "flex" }}>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 0.5 }}>
                      S.No
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1.5 }}>
                      Date
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                      IOT Distance(KM)
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1.5 }}>
                      Carbon Saved (KG)
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                      Map
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cycleIdData?.map((row: any) => (
                    <TableRow sx={{ display: "flex" }} key={row.index}>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 0.5 }}>
                        {row.index}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1.5 }}>
                        {formatDate(row.Date)}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        {row.iot_distance}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1.5 }}>
                        {row.carbon_saved}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        <Stack direction="row" spacing={0}>
                          <Link
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              handleAdd(row);
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "#00CD5B" }}
                            >
                              <MapIcon />
                            </Typography>
                          </Link>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pl-2 pt-4 pb-2 pr-16">
              <div
                className="pagination"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 0}
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "20px",
                      marginRight: "6px",
                    }}
                  >
                    {"<"}
                  </button>
                  {renderPageNumbers()}
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page >= pageCount - 1}
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "20px",
                      marginLeft: "6px",
                    }}
                  >
                    {">"}
                  </button>
                </div>
              </div>
            </div>
            {/* <Snackbar
              open={showSnackbar}
              autoHideDuration={6000}
            >
              <Alert
                sx={{ width: "100%" }}
                severity="error"
              >
                No data found
              </Alert>
            </Snackbar> */}
          </>
        </Box>
      </Modal>
    </div>
  );
};
export default CycleIdModal;

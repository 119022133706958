import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
  //Polygon
} from "@react-google-maps/api";
import moment from "moment";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import "./style.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Path } from "./googleMapLive";
import { Link } from "@mui/material";
//import axios from 'axios';

function Map({
  paths,
  homeLat,
  homeLng,
  workLat,
  workLng,
  workhomelocation,
}: {
  workhomelocation: { work_home_data: Path[], stops:Path[] }; // Assuming workhomelocation is an object containing work_home_data property
  paths: Path[];
  workLat: number;
  workLng: number;
  homeLat: number;
  homeLng: number;
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [positionIndex, setPositionIndex] = useState(0);

  const [startAddress, setStartAddress] = useState<string | null>(null);
  const [endAddress, setEndAddress] = useState<string | null>(null);
  const [riderstops, setRiderStops] = useState<{ [key: number]: string | null }>({});
  const [allhomeAddress, setAllHomeAddress] = useState<{ [key: number]: string | null }>({});
  const [allworkAddress, setAllWorkAddress] = useState<{ [key: number]: string | null }>({});
  const [selectedMarkerinfo, setSelectedMarkerinfo] = useState<number | null>(
    null
  );
  const [stopriderMarkerinfo, setStopRiderMarkerinfo] = useState<number | null>(null);

  const [selectedMarkerinfowork, setSelectedMarkerinfowork] = useState<number | null>(null);
  const [selectedMarkerstart, setSelectedMarkerstart] = useState<number | null>(null);
  const [selectedMarkerend, setSelectedMarkerend] = useState<number | null>(null);

  const googleMapApiKey = "AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg";
  let mapPaths: Path[] = paths;

  const workhome: Path[] = workhomelocation.work_home_data;

  const riderstopdata: Path[] = workhomelocation.stops;

  let interval: NodeJS.Timer | undefined;
  const icon1 = {
    url: "https://zypp.app/mailer/Map-icon.png",
    scaledSize: new window.google.maps.Size(35, 35),
    anchor: new window.google.maps.Point(20, 20),
    scale: 0,
  };

  const mid = Math.floor(mapPaths.length / 2);
  const centerPathLat = mapPaths[mid]?.latitude;
  const centerpathLng = mapPaths[mid].longitude;
  const startLat = mapPaths[0].latitude;
  const startLng = mapPaths[0].longitude;
  const end = mapPaths.length - 1;
  const endPathLat = mapPaths[end].latitude;
  const endpathLng = mapPaths[end].longitude;

  const starttime = mapPaths[0].createdTime;
  const endtime = mapPaths[end].createdTime;

  let StartDate = moment(starttime).format("DD-MM-YYYY");
  let EndDate = moment(endtime).format("DD-MM-YYYY");

  const convertPathToLatLngLiteral = (
    path: Path[]
  ): google.maps.LatLngLiteral[] => {
    return path.map((point) => ({
      lat: point.latitude,
      lng: point.longitude,
      distance: point.Distance,
    }));
  };

  const [mapPath, setMapPath] = useState<google.maps.LatLngLiteral[]>([]);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    const pathAsLatLngLiteral = convertPathToLatLngLiteral(paths);
    setMapPath(pathAsLatLngLiteral);
  }, [paths]);
  async function getLocality(
    apiKey: string,
    latitude: number | undefined,
    longitude: number | undefined
  ): Promise<string | null> {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch address. Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const result = data.results[0];
        // Extract the name of the first locality and sublocality if available
        const localityComponentone = result.address_components.find(
          (component: any) => component.types.includes("sublocality_level_2")
        );
           const localityComponent = result.address_components.find(
          (component: any) => component.types.includes("sublocality_level_1")
        );
        const cityComponent = result.address_components.find((component: any) =>
          component.types.includes("locality")
        );
        const postalCodeComponent = result.address_components.find(
          (component: any) => component.types.includes("postal_code")
        );

        // Return the combined result of city, sublocality, and postal code if found
        if (localityComponent && cityComponent && postalCodeComponent && localityComponentone) {
          const localityone = localityComponentone.long_name;
          const locality = localityComponent.long_name;
          const city = cityComponent.long_name;
          const postalCode = postalCodeComponent.long_name;
          return `${localityone},${locality}, ${city}, ${postalCode}`;
        } else {
          // If any of the components is missing, return the formatted address
          return result.formatted_address;
        }
      } else {
        throw new Error(`No results found for the provided coordinates.`);
      }
    } catch (error: any) {
      console.error(`Error fetching locality: ${error.message}`);
      return null;
    }
  }
  const fetchData = async () => {
    try {
      const fetchedallWorkAddress = await getLocality(
        googleMapApiKey,
        startLat,
        startLng
      );
      const fetchedallHomeAddress = await getLocality(
        googleMapApiKey,
        endPathLat,
        endpathLng
      );

      setStartAddress(fetchedallWorkAddress);

      setEndAddress(fetchedallHomeAddress);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [workLat]);

  useEffect(() => {
    let intervalId: NodeJS.Timer | undefined;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setPositionIndex((prevIndex) => {
          if (prevIndex === mapPath.length - 1) {
            setIsPlaying(false); // Stop playing if reached the end
            clearInterval(intervalId);
            return prevIndex;
          }
          return prevIndex + 1;
        });
      }, 1000); // Adjust the interval time as needed
    }

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [isPlaying, mapPath.length]);

  let iconMarkerStart = "https://zypp.app/clientdashboard-mailer/startpath.png";
  let iconMarkerEnd = "https://zypp.app/clientdashboard-mailer/endpath.png";

  const polylineOptionsBlue = {
    strokeColor: "#0826e5",
    strokeWeight: 2,
    strokeOpacity: 0.6,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 99999,
    icons: [
      {
        icon: {
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        },
        repeat: "40px",
        offset: "100%",
      },
    ],
  };
  const polylineOptions = {
    strokeColor: "#ffa500",
    strokeOpacity: 1,
    strokeWeight: 3,
    icons: [
      {
        icon: {
          path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
          scale: 3,
          fillColor: "#ffa500",
          fillOpacity: 1,
          strokeColor: "#ffa500",
        },
        offset: "100%",
      },
    ],
  };
  const riderstopMarker = {
    icon: "/assets/hyper-track-icon/all-work-location.png", // Replace with the path to your home marker icon
  };
  const homeMarker = {
    icon: "/assets/hyper-track-icon/home-location-new.png", // Replace with the path to your home marker icon
  };
  const workMarker = {
    icon: "/assets/hyper-track-icon/work-location-new.png",
  };
  const handleStopRiderMarker = (index: number) => {
    setStopRiderMarkerinfo(index);
  };
  const handleMarkerHover = (index: number) => {
    setSelectedMarkerinfo(index);
  };
  const handleMarkerHoverWork = (index: number) => {
    setSelectedMarkerinfowork(index);
  };
  const handleMarkerStart = (e: any) => {
    setSelectedMarkerstart(e);
  };
  const handleMarkerEnd = (e: any) => {
    setSelectedMarkerend(e);
  };
  const starttimeFormat = (date: string) => {
    return date?.split(" ")[-1] || "";
  };
  function StopCircleIcon(location: Path, index: number) { 
    let Datebar = moment(location.Date).format("DD-MM-YYYY");
  
    const fetchLocationData = async (lat: number, long: number) => {
      const fetchedAddress = await getLocality(googleMapApiKey, lat, long);
      return fetchedAddress;
    };
  
    useEffect(() => {
      const fetchDataForLocation = async () => {
        const fetchedHomeAddress = await fetchLocationData(
          location.home_lat,
          location.home_long
        );
        const fetchedWorkAddress = await fetchLocationData(
          location.work_lat,
          location.work_long
        );
        setAllHomeAddress((prevAddresses) => {
          return { ...(prevAddresses || {}), [index]: fetchedHomeAddress };
        });
        setAllWorkAddress((prevAddresses) => {
          return { ...(prevAddresses || {}), [index]: fetchedWorkAddress };
        });
      };
      fetchDataForLocation();
    }, [index]);
  
    return (
      <>
        <Marker
          key={index}
          position={{
            lat: location.home_lat,
            lng: location.home_long,
          }}
          icon={homeMarker.icon}
          label={`${index + 1}`}
          onClick={() => handleMarkerHover(index)} // No semicolon needed here
        />
  
        {selectedMarkerinfo === index && (
          <>
            <InfoWindow
              options={{ pixelOffset: new window.google.maps.Size(0, -50) }}
              onCloseClick={() => setSelectedMarkerinfo(null)}
              position={{
                lat: location.home_lat,
                lng: location.home_long,
              }}
            >
              <div>
                <p>
                  <b>{Datebar}</b>
                </p>
                <p>
                  <b>Home: </b>
                  {allhomeAddress[index] || "Unknown Address"}
                </p>
                <p>
                  <b>Latitude:</b> {location.home_lat} <b>Longitude:</b>{" "}
                  {location.home_long}
                </p>
                <p>
                  <b>Google Maps Link:</b>{" "}
                  <a
                    target="_blank"
                    href={`https://maps.google.com/?q=${location.home_lat},${location.home_long}`}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    https://maps.google.com/?q={location.home_lat},
                    {location.home_long}
                  </a>
                </p>
              </div>
            </InfoWindow>
          </>
        )}
  
        <Marker
          key={index}
          position={{
            lat: location.work_lat,
            lng: location.work_long,
          }}
          icon={workMarker.icon}
          label={`${index + 1}`}
          onClick={() => handleMarkerHoverWork(index)} // Pass index to handleMarkerHoverWork
        />
  
        {selectedMarkerinfowork === index && (
          <InfoWindow
            options={{ pixelOffset: new window.google.maps.Size(0, -50) }}
            onCloseClick={() => setSelectedMarkerstart(null)}
            position={{
              lat: location.work_lat,
              lng: location.work_long,
            }}
          >
            <div>
              <p>
                <b>{Datebar}</b>
              </p>
              <p>
                <b>Work: </b>
                {allworkAddress[index] || "Unknown Address"}
              </p>
              <p>
                <b>Latitude:</b> {location.work_lat} <b>Longitude:</b>{" "}
                {location.work_long}
              </p>
              <p>
                <b>Google Maps Link:</b>{" "}
                <a
                  target="_blank"
                  href={`https://maps.google.com/?q=${location.work_lat},${location.work_long}`}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  https://maps.google.com/?q={location.work_lat},
                  {location.work_long}
                </a>
              </p>
            </div>
          </InfoWindow>
        )}
      </>
    );
  }
  function StopRider(location: Path, index: number) { 
    //let Datebar = moment(location.date).format("DD-MM-YYYY");

    const startTimeFormatted = moment(location.Start_time, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY, hh:mm:ss");
    const endTimeFormatted = moment(location.End_time, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY, hh:mm:ss");


    const calculateTimeDiff = (seconds: number) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      return { hours, minutes, seconds: remainingSeconds };
    };
  
    const TimeDiff = calculateTimeDiff(location.time_diff);

  
    const fetchLocationData = async (lat: number, long: number) => {
      const fetchedAddress = await getLocality(googleMapApiKey, lat, long);
      return fetchedAddress;
    };
  
    useEffect(() => {
      const fetchDataForLocation = async () => {
        const fetchedRiderStop = await fetchLocationData(
          location.latitude,
          location.longitude
        );
        
        setRiderStops((prevAddresses) => {
          return { ...(prevAddresses || {}), [index]: fetchedRiderStop };
        });
        
      };
      fetchDataForLocation();
    }, [index]);
  
    return (
      <>
        <Marker
          key={index}
          position={{
            lat: location.latitude,
            lng: location.longitude,
          }}
          icon={riderstopMarker.icon}
          label={`${index + 1}`}
          onClick={() => handleStopRiderMarker(index)} // No semicolon needed here
        />
  
        {stopriderMarkerinfo === index && (
          <>
            <InfoWindow
              options={{ pixelOffset: new window.google.maps.Size(0, -50) }}
              onCloseClick={() => setStopRiderMarkerinfo(null)}
              position={{
                lat: location.latitude,
                lng: location.longitude,
              }}
            >
              <div>
                <p style={{marginBottom:5}}>
                <b>Ride Stop Time:</b> {startTimeFormatted}
                </p>
                <p style={{marginBottom:5}}>
                <b>Ride Start Time: </b>  {endTimeFormatted}
                </p>
                <p style={{marginBottom:5}}><b>Time Difference:</b> {TimeDiff.hours} hours, {TimeDiff.minutes} minutes, {TimeDiff.seconds} seconds</p>

                <p style={{marginBottom:5}}>
                  <b>Home: </b>
                  {riderstops[index] || "Unknown Address"}
                </p>
                <p style={{marginBottom:5}}>
                  <b>Latitude:</b> {location.latitude} <b>Longitude:</b>{" "}
                  {location.longitude}
                </p>
                <p>
                  <b>Google Maps Link:</b>{" "}
                  <a
                    target="_blank"
                    href={`https://maps.google.com/?q=${location.latitude},${location.longitude}`}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    https://maps.google.com/?q={location.latitude},
                    {location.longitude}
                  </a>
                </p>
              </div>
            </InfoWindow>
          </>
        )}
  
        
  
        
      </>
    );
  }
  

  return (
    <div>
      {/* <div style={{textAlign:'right', display:'flex', justifyContent:'flex-end'}}>
      <Button
            onClick={tooglePlay}
            style={{ backgroundColor: "#00CD5B", marginBottom: 10, color: "#fff" }}
          >
            {isPlaying ? "Pause" : "Play"}
          </Button>
          </div> */}
      <Card variant="outlined">
        <div className="gMapCont">
          <GoogleMap
            onLoad={(mapDetails: google.maps.Map) => {
              setMap(mapDetails); // Map object stored
            }}
            zoom={11}
            center={{ lat: workLat, lng: workLng }}
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
            }}
            // journeydetails={journeydetails}
            mapContainerStyle={{
              width: "100%",
              height: "44rem",
            }}
          >
            {workhome && workhome && (
              <>
                {workhome.map((location: any, index: number) =>
                  StopCircleIcon(location, index)
                )}
              </>
            )}
            {riderstopdata && riderstopdata && (
              <>
                {riderstopdata.map((location: any, index: number) =>
                  StopRider(location, index)
                )}
              </>
            )}

            <Polyline path={mapPath} options={polylineOptionsBlue} />

            {mapPath && (
              <>
                <Polyline
                  path={mapPath.slice(0, positionIndex + 1)}
                  options={polylineOptions}
                />

                <Marker icon={icon1} position={{ lat: 0, lng: 0 }} />
              </>
            )}
            <Marker
              // label={new window.google.maps.Label("")}
              icon={iconMarkerStart}
              position={{ lat: startLat, lng: startLng }}
              onClick={() => handleMarkerStart(workhome)}
            />

            {selectedMarkerstart !== null && (
              <InfoWindow
                options={{ pixelOffset: new window.google.maps.Size(0, -100) }}
                onCloseClick={() => setSelectedMarkerstart(null)}
                position={{
                  lat: startLat,
                  lng: startLng,
                }}
              >
                <div>
                  <p>
                    <b>{StartDate}</b>
                  </p>
                  <p>
                    <b>Work: </b>
                    {startAddress || "Unknown Address"}
                  </p>
                  <p>
                    <b>Latitude:</b> {startLat} <b>Longitude:</b> {startLng}
                  </p>
                  <p>
                    <b>Google Maps Link:</b>{" "}
                    <a
                      target="_blank"
                      href={`https://maps.google.com/?q=${startLat},${startLng}`}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      https://maps.google.com/?q={startLat},{startLng}
                    </a>
                  </p>
                </div>
              </InfoWindow>
            )}
            <Marker
              // name={"SOMA"}
              icon={iconMarkerEnd}
              position={{ lat: endPathLat, lng: endpathLng }}
              onClick={() => handleMarkerEnd(workhome)}
            />
            {selectedMarkerend !== null && (
              <InfoWindow
                options={{ pixelOffset: new window.google.maps.Size(0, -100) }}
                onCloseClick={() => setSelectedMarkerend(null)}
                position={{
                  lat: endPathLat,
                  lng: endpathLng,
                }}
              >
                <div>
                  <p>
                    <b>{EndDate}</b>
                  </p>
                  <p>
                    <b>Work: </b>
                    {endAddress || "Unknown Address"}
                  </p>
                  <p>
                    <b>Latitude:</b> {endPathLat} <b>Longitude:</b> {endpathLng}
                  </p>
                  <p>
                    <b>Google Maps Link:</b>{" "}
                    <a
                      target="_blank"
                      href={`https://maps.google.com/?q=${endPathLat},${endpathLng}`}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      https://maps.google.com/?q={endPathLat},{endpathLng}
                    </a>
                  </p>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </div>
      </Card>
    </div>
  );
}

// const WrappedMap = withScriptjs(withGoogleMap(Map));

export default Map;

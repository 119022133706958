import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  InputAdornment,
  TextField,
  Button,
  Snackbar,
  Alert,
  LinearProgress,
} from "@mui/material";
import { useNavigate } from "react-router";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { RiderDetailTrackingApi } from "../../../API/RiderDetailTrackingApi";
import RiderDetailPopup from "../../UI/AppModal/RiderDetailAndTracking/RiderDetailPopup";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import { RiderDetailsExportDataProps } from "../../../@Types/ComponentsInterface/RiderDetailsExportDataProps";
import { RiderDetailTrackingProps } from "../../../@Types/ComponentsInterface/RiderDetailsTrackingProps";
import "./RiderDetailsTracking.css";
import CircularProgress from "@mui/material/CircularProgress";

const tableHeaderCellStyle = {
  fontWeight: 600,
  fontSize: "14px",
  color: "#455A64",
  font: "Rubik",
};

const tableBodyCellStyle = {
  fontWeight: 400,
  fontSize: "14px",
  color: "#A4A2A2",
  font: "Rubik",
};

export default function RiderDetailTracking() {
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);

  const [riderDetailTrackingData, setRiderDetailTrackingData] = useState<
    RiderDetailTrackingProps[]
  >([]);
  const [totalCount, setTotalCount] = useState<any>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pageRange = 5;
  const [open, setOpen] = React.useState(false);
  const [riderDetailCompleteData, setRiderDetailCompleteData] = useState<
    RiderDetailsExportDataProps[]
  >([]);

  const navigateToHome = () => {
    navigate("/Dashboard");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (qrCode: string) => {
    if (selectedRows.includes(qrCode)) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row !== qrCode)
      );
      setRiderDetailCompleteData((prevData) =>
        prevData.filter((row) => row.qrCode !== qrCode)
      );
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, qrCode]);
      const selectedRowData = riderDetailTrackingData.find(
        (row) => row.qrCode === qrCode
      );

      // Check if selectedRowData is not undefined before updating the state
      if (selectedRowData) {
        setRiderDetailCompleteData((prevData) => [
          ...prevData,
          selectedRowData,
        ]);
      }
    }
  };

  const handleSelectAllClick = () => {
    if (selectedRows.length === riderDetailTrackingData.length) {
      // If all rows are selected, clear the selection
      setSelectedRows([]);
    } else {
      // Otherwise, select all rows
      const allQrCodes = riderDetailTrackingData.map((row) => row.qrCode);
      setSelectedRows(allQrCodes);
    }
  };

  useEffect(() => {
    setLoading(true);
    const apiUrl = `${searchVal ? `?search=${searchVal}` : ""}`;
    RiderDetailTrackingApi.api
      ?.riderDetailTrackingList(page + 1, rowsPerPage, apiUrl)
      .then((e) => {
        setLoading(false);
        if (Array.isArray(e.riderList) && e.riderList?.length > 0) {
          setRiderDetailTrackingData(e.riderList);
          setTotalCount(e.totalCount);
        } else {
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });
  }, [page, rowsPerPage, searchVal]);

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(1, Math.min(newPage + 1, pageCount));
    setPage(newPageNumber - 1);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index + 1;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber - 1)}
          className={page === pageNumber - 1 ? "active" : "unactive"}
          style={{ marginLeft: "6px" }}
        >
          {pageNumber}
        </span>
      );
    });
  };

  const convertToCSV = (data: RiderDetailsExportDataProps[]) => {
    const header = Object.keys(data[0]).join("\t");
    const rows = data.map((row) => Object.values(row).join("\t"));

    return `${header}\n${rows.join("\n")}`;
  };

  const downloadCSV = (csvData: string) => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    link.href = URL.createObjectURL(blob);
    link.download = "rider_data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportButtonClick = () => {
    if (selectedRows.length > 0) {
      setDataLoading(true);
      // If any row is selected, filter the complete data based on selected rows
      setRiderDetailCompleteData(
        riderDetailTrackingData
          .filter((row) => selectedRows.includes(row.qrCode))
          .map((selectedRow) => {
            // Map and create a new object with desired properties
            const { profilePicId, status, ...exportData } = selectedRow;
            return exportData;
          })
      );
    } else {
      alert("No rows selected for export");
    }
  };

  useEffect(() => {
    if (riderDetailCompleteData.length > 0) {
      const csvData = convertToCSV(riderDetailCompleteData);
      downloadCSV(csvData);
      setDataLoading(false);
    }
  }, [riderDetailCompleteData]);

  function formatName(name: string) {
    const words = name?.split(" ");
    if (words?.length > 2) {
      return `${words[0]} ${words[words.length - 1]}`;
    } else {
      return name;
    }
  }

  function capitalizeFirstLetter(str: string): string {
    const words: string[] = str.split(" ");
    const capitalizedWords: string[] = words.map((word: string) => {
      return formatName(
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
    });
    return formatName(capitalizedWords.join(" "));
  }

  return (
    <div
      style={{ height: "100%", width: "100%" }}
      className="border-10 bg-gradient-to-r from-slate-100 to-slate-50"
    >
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
        className="bg-gradient-to-r from-slate-100 to-slate-50"
      >
        {
          <Typography
            sx={{ flex: "1 1 100%", textAlign: "left" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <a onClick={navigateToHome} className="cursor-pointer text-black">
              <ArrowBackIcon className="mr-4 mb-1 ml-7" />
            </a>
            <b>Rider Details & Tracking</b>
          </Typography>
        }
        {
          <Typography
            sx={{ flex: "1 1 100%", textAlign: "right", marginRight: "48px" }}
            component="div"
          >
            <HomeIcon className="mb-1" />
            {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span> {" / "}
            Rider Tracking
          </Typography>
        }
      </Toolbar>

      <div className="border-0 rounded-3xl pt-8 m-4 bg-white ml-12 mr-12">
        <div className="flex justify-between mb-4 pl-12 pr-8">
          <div className="flex">
            <TextField
              placeholder="Search Qr Code or Name"
              id="outlined-basic"
              variant="outlined"
              onChange={(search) => setSearchVal(search.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              // onKeyDown={handleKeyPress}
            />
            {/* <Button
              onClick={handleClickOpen}
              sx={{
                bgcolor: "#FF725E33",
                color: "black",
                ml: 2,
                px: 2,
                textTransform: "none",
                "&:hover": { bgcolor: "#FF725E16" },
              }}
              startIcon={<img src="/assets/icons/filter.svg" />}
            >
              Filter
            </Button> */}
          </div>
          <div className="flex" onClick={handleExportButtonClick}>
            {dataLoading ? (
              <Button
                sx={{
                  bgcolor: "#FF725E33",
                  color: "black",
                  ml: 2,
                  px: 2,
                  textTransform: "none",
                  "&:hover": { bgcolor: "#FF725E16" },
                }}
              >
                <CircularProgress sx={{ color: "#FF725E" }} size="1.5rem" />
              </Button>
            ) : (
              <Button
                sx={{
                  bgcolor: "#FF725E33",
                  color: "black",
                  ml: 2,
                  px: 2,
                  textTransform: "none",
                  "&:hover": { bgcolor: "#FF725E16" },
                }}
                startIcon={<img src="/assets/icons/exportIcon.svg" />}
              >
                Export
              </Button>
            )}
          </div>
        </div>
        {loading ? (
          <LinearProgress
            className="pl-4 pr-4"
            sx={{
              backgroundColor: "white",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#00CD5B",
              },
            }}
          />
        ) : (
          <>
            <TableContainer
              className="pl-5 pr-5"
              style={{ width: "100%", overflowX: "auto" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < riderDetailTrackingData.length
                        }
                        checked={
                          selectedRows.length === riderDetailTrackingData.length
                        }
                        onChange={handleSelectAllClick}
                        style={{ color: "#A4A2A2" }}
                      />
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle}>Qr Code</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Name</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Mobile</TableCell>
                    <TableCell style={tableHeaderCellStyle}>
                      Carbon Saved
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle}>
                      Working Days
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle}>
                      Total Orders
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle}>
                      Distance(KMs)
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {riderDetailTrackingData?.map((row) => (
                    <TableRow key={row.qrCode}>
                      <TableCell>
                        <Checkbox
                          checked={selectedRows.includes(row.qrCode)}
                          onChange={() => handleCheckboxChange(row.qrCode)}
                          style={{ color: "#A4A2A2" }}
                        />
                      </TableCell>
                      <TableCell style={tableBodyCellStyle}>
                        {row.qrCode}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle}>
                        <div className="flex ">
                          <img
                            src={`https://dash.bcykal.com//mobycy/api/media/file/${row.profilePicId}`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/assets/icons/zypp-evs.svg";
                            }}
                            alt="Icon"
                            className="w-9 h-9 mr-2 items-center border rounded-full border-green-500 "
                          />
                          {capitalizeFirstLetter(row.userName)}
                        </div>
                      </TableCell>
                      <TableCell style={tableBodyCellStyle}>
                        {row.mobile}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle}>
                        {row.carbonSaved}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle}>
                        {row.workingDays}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle}>
                        {row.totalOrders}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle}>
                        {row.distance}
                      </TableCell>
                      <TableCell>
                        <RiderDetailPopup rowData={row} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pl-6 pt-4 pb-2 pr-16">
              <div
                className="pagination"
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <select
                  className="pl-2 pr-2 rounded"
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(Number(e.target.value))}
                  style={{
                    backgroundColor: "#EBEBEB",
                    fontFamily: "Rubik",
                    fontSize: "16px",
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                <div>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 0}
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "20px",
                      marginRight: "6px",
                    }}
                  >
                    {"<"}
                  </button>
                  {renderPageNumbers()}
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page >= pageCount - 1}
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "20px",
                      marginLeft: "6px",
                    }}
                  >
                    {">"}
                  </button>
                </div>
              </div>
            </div>
            <Snackbar
              open={showSnackbar}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                sx={{ width: "100%" }}
                onClose={handleClose}
                severity="error"
              >
                No data found
              </Alert>
            </Snackbar>{" "}
          </>
        )}
      </div>
    </div>
  );
}

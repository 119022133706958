import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer
} from "@react-google-maps/api";
import { HyperTrackHome } from "../../../API/HyperTrack/HomePage";
import LinearProgress from "@mui/material/LinearProgress";
const containerStyle = {
  width: "100%",
  height: "100%",
};


interface MapProps {
  selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
}

const GoogleMapApi: React.FC<MapProps> = ({
  selectedCityValue,
  selectedClientValue,
  selectedVehicleTypeValue,
  selectedDisconnectionValue,
  selectedOptions
  

}) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedMarker, setSelectedMarker] = useState<number | null>(null);

  useEffect(() => {
    HyperTrackHome.api.googleMapsIotLocation( selectedCityValue,
      selectedClientValue,
      selectedVehicleTypeValue,
      selectedDisconnectionValue,
      selectedOptions)
    .then((e) => {
      setData(e.status_wise_map_details);
      setLoading(false);
    })

    
  }, [
    selectedCityValue,
    selectedClientValue,
    selectedVehicleTypeValue,
    selectedDisconnectionValue,
    selectedOptions,
  ]);

  const calculateCenter = () => {
    if (data.length === 0) {
      console.error("No data available");
      return { lat: 0, lng: 0 }; // Return default center
    }

    const mid = Math.floor(data.length / 2);
    const centerMarker = data[mid];

    return {
      lat: parseFloat(centerMarker.last_latitude),
      lng: parseFloat(centerMarker.last_longitude),
    };
  };

  const center = calculateCenter();


 


  const getCustomMarkerIcon = (connectionStatus: string) => {
    if (connectionStatus === 'Connected') {
      return 'https://zypp.app/clientdashboard-mailer/green-dot.png'; // Provide the URL to your green dot icon
    } else if (connectionStatus === 'Disconnected') {
      return 'https://zypp.app/clientdashboard-mailer/red-dot.png'; // Provide the URL to your red dot icon
    }
  };
  const getBackgroundColor = (connectionStatus: string) => {
    return connectionStatus === 'Connected' ? 'bg-green-500' : 'bg-red-500';
  };
  const handleMarkerClick = (index: number) => {
    setSelectedMarker(index);
  };

 
 
  return (
    <>
      {data ? (
       <GoogleMap
       mapContainerStyle={containerStyle}
       center={center}
       zoom={12}
     >
      <MarkerClusterer averageCenter enableRetinaIcons gridSize={60} >
  {(clusterer) => (
    <>
       {data.map((item, index) => {
         const customIconUrl = getCustomMarkerIcon(item.connection_status);
         if (customIconUrl) {
           return (
             <Marker
               key={index}
               position={{
                 lat: parseFloat(item.last_latitude),
                 lng: parseFloat(item.last_longitude),
               }}
               icon={{
                 url: customIconUrl,
               }}
               onClick={() => handleMarkerClick(index)}
               clusterer={clusterer}
             />
           );
         } else {
           // Handle case where icon URL is undefined
           return null;
         }
       })}
        </>
  )}
</MarkerClusterer>
       <div>
         {/* Conditionally render the InfoWindow */}
         {selectedMarker !== null && (
           <InfoWindow
               position={{
               lat: parseFloat(data[selectedMarker].last_latitude),
               lng: parseFloat(data[selectedMarker].last_longitude),
             }}
             onCloseClick={() => setSelectedMarker(null)}
           >
             <div className="flex items-center">
               <div>
               <p className={`p-2 text-white	font-bold text-left text-slate-700 ${getBackgroundColor(data[selectedMarker].connection_status)}`}>
                   <b>{data[selectedMarker].qr_code}</b>
                 </p>
                 <p className="font-medium text-left text-slate-700 flex items-center mt-2">
                   <span className="mr-2">Last Lat - </span>
                   {data[selectedMarker].last_latitude}
                 </p>
                 <p className="font-medium text-slate-700 flex items-center mt-1">
                   <span className="mr-2">Last Long - </span>
                   {data[selectedMarker].last_longitude}
                 </p>
               </div>
             </div>
           </InfoWindow>
         )}
       </div>
     </GoogleMap>
      ) : (
        <div className="w-full">
          <LinearProgress
            sx={{
              backgroundColor: "white",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#00CD5B",
              },
            }}
          />
        </div>
      )}
    </>
  );
}

export default GoogleMapApi;

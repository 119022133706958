import axios, { AxiosInstance, AxiosResponse } from "axios";
import { hubFleetRequestBody } from "../@Types/DTO/HubfleetRequest";
import {
  hubFleetRequestListResponse,
  hubListResponse,
  hubFleetManagerListResponse,
} from "../@Types/DRO/ApiResponses";
export class NewFleetDemandApi {
  public static api = new NewFleetDemandApi();
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: "https://stag-micro.bcykal.com/mobycy/clientDash/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("access_token");
  }

  addHubFleetRequest(
    fleetRequest: hubFleetRequestBody
  ): Promise<AxiosResponse<any>> {
    const data = { fleetRequest };
    const config = {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    };
    return this.instance.post<any>("client/fleet/request", data, config);
  }
  hubList(): Promise<hubListResponse> {
    return this.instance
      .get<hubListResponse>("getMerchantShop", {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  hubFleetRequestList(
    searchParam?: string
  ): Promise<hubFleetRequestListResponse> {
    let apiUrl = "get/client/fleet/request";
    if (searchParam) {
      apiUrl += `${searchParam}`;
    }
    return this.instance
      .get<hubFleetRequestListResponse>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  hubFleetManagerList(
    shopId: number | null
  ): Promise<hubFleetManagerListResponse> {
    return this.instance
      .get<hubFleetManagerListResponse>(
        `get/hubManager/list?shopId=${shopId}`,
        {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
}

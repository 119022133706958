import React, { useEffect, useRef, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router";
import GoogleMapApi from "../CreatestoreMaps/CreateStoreMaps";
import MapAutoComplete from "../CreatestoreMaps/FormAddessSearch/AutoCompleteForm";
import { Place } from "../../../@Types/ComponentsInterface/CreateHubProps";
import { RiderKycApi } from "../../../API/RiderKycApi";

import {
  Alert,
  Button,
  Snackbar,
  TextField,
  FormControl,
} from "@mui/material";

export default function DataTable() {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/Dashboard");
  };

  const [loading, setLoading] = useState(true);
  const [selectedPlace, setSelectedPlace] = useState<Place | undefined>();
  const [hubName, setHubName] = useState<string>("");
  const [selectShop, setSelectShop] = useState<string>("");
  const [selectMerchant, setSelectMerchant] = useState<string>("");
  const [merchantList, setMerchantList] = useState<any[]>([]);
  const [shopCategory, setShopCategory] = useState<any[]>([]);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");

  const [hubNameError, setHubNameError] = useState(""); // State for city error
  const [addressError, setAddressError] = useState(""); // State for merchant error
  const [merchantError, setMerchantError] = useState(""); // State for merchant error
  const [shopError, setShopError] = useState(""); // State for city error

  const handlePlaceSelect = (place: Place | null) => {
    setSelectedPlace(place || undefined);
  };

  const extractStreetAddress = (fullAddress: string) => {
    const addressParts = fullAddress.split(",");
    const streetAddress = addressParts.slice(0, 3).join(", ").trim();
    return streetAddress;
  };

  const extractAreaFromAddress = (fullAddress: string) => {
    const addressParts = fullAddress.split(",");
    const cityIndex = addressParts.length - 3;
    const area = addressParts[cityIndex - 1]?.trim();
    return area;
  };

  useEffect(() => {
    RiderKycApi.api?.merchantDataList()
      .then((response) => {
        setLoading(false);
        if (response.data.length > 0) {
          setMerchantList(response.data);
        } else {
          setMerchantList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });

    RiderKycApi.api?.shopCategory()
      .then((response) => {
        setLoading(false);
        if (response.data.length > 0) {
          setShopCategory(response.data);
        } else {
          setShopCategory([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });
  }, []);

  const formRef = useRef<HTMLFormElement | null>(null);
  const token = localStorage.getItem("access_token");

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
   
    if (!hubName) {
      setHubNameError("Please fill hub name");

    } else {
      setHubNameError("");
    }
  
    if (!selectMerchant) {
      setMerchantError("Please select a merchant");

    } else {
      setMerchantError("");
    }
    if (!selectedPlace) {
      setAddressError("Please select a address");

    } else {
      setAddressError("");
    }
  
    if (!selectShop) {
      setShopError("Please select a shop");

    } else {
      setShopError("");
    }
    

    // Prepare the request body
    const requestBody = {
      shopName: hubName,
      merchantId: selectMerchant,
      latitude: selectedPlace?.lat,
      longitude: selectedPlace?.lng,
      city: selectedPlace?.city,
      shopAddr: extractStreetAddress(selectedPlace?.address || ""),
      shopArea: extractAreaFromAddress(selectedPlace?.address || ""),
      shopState: selectedPlace?.administrative_area_level_1,
      pincode: selectedPlace?.postcode,
      category: selectShop,
    };

    try {
      const response = await fetch(
        "https://stag-micro.bcykal.com/mobycy/clientDash/add/merchantShop",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        setShowSuccessSnackbar(true);
      } else {
        const errorData = await response.json();
        setSnackbarMessage(errorData.message || "Error creating store");
        setShowErrorSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Network error");
      setShowErrorSnackbar(true);
    }
  
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
  };
  const isFormValid = () => {
    return hubName && selectMerchant && selectedPlace && selectShop;
  };

  return (
    <div style={{ height: "100%", width: "100%" }} className="border-10 bg-gradient-to-r from-slate-100 to-slate-50 pl-6 pr-6">
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, display: "flex", alignItems: "center" }} className="bg-gradient-to-r from-slate-100 to-slate-50">
        <Typography sx={{ flex: "1 1 100%", textAlign: "left" }} variant="h6" id="tableTitle" component="div">
          <a onClick={navigateToHome} className="cursor-pointer text-black">
            <ArrowBackIcon className="mr-4 mb-1" />
          </a>
          <b>Create New Store</b>
        </Typography>
        <Typography sx={{ flex: "1 1 100%", textAlign: "right", marginRight: "48px" }} component="div">
          <HomeIcon className="mb-1" />
          {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span> {" / "} Create New Store
        </Typography>
      </Toolbar>

      <div className="border-0 rounded-xl m-1 bg-white px-12 pt-10">
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="flex w-full mb-8">
            <div className="w-3/4">
              <div className="flex justify-between mr-4">
                <div className="w-2/4 mr-6">
                  <p className="text-left mb-4 text-sm font-bold">Store Name <sup style={{ color: 'red' }}>*</sup></p>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    placeholder="Enter Hub Name"
                    className="text-slate-300"
                    onChange={(e) => {
                      setHubName(e.target.value);
                    }}
                    value={hubName}
                  />
                    {hubNameError && <div className="text-left text-[#cf3917]">{hubNameError}</div>}

                </div>
                <div className="w-2/4">
                  <p className="text-left mb-4 text-sm font-bold">Select Address <sup style={{ color: 'red' }}>*</sup></p>
                  <MapAutoComplete value={selectedPlace} onPlaceSelect={handlePlaceSelect} />
                  {addressError && <div className="text-left text-[#cf3917]">{addressError}</div>}

                </div>
              </div>

              <div className="flex justify-between mr-4 mt-6">
                <div className="w-2/4 mr-6">
                  <p className="text-left mb-4 text-sm font-bold">Name of Merchant <sup style={{ color: 'red' }}>*</sup></p>
                  <FormControl fullWidth>
                    <select
                      id="merchant"
                      onChange={(e) => {
                        setSelectMerchant(e.target.value);
                      }}
                      style={{ border: 1, borderColor: "#c0baba", borderStyle: "solid", padding: 7, borderRadius: 3, height: 56 }}
                    >
                      <option value="" selected>Select New</option>
                      {merchantList.map((TL, index) => (
                        <option key={index} value={TL.merchantId}>
                          {TL.merchantName}
                        </option>
                      ))}
                    </select>
                    {merchantError && <div className="text-left text-[#cf3917]">{merchantError}</div>}

                  </FormControl>
                </div>
                <div className="w-2/4">
                  <p className="text-left mb-4 text-sm font-bold">Shop Category <sup style={{ color: 'red' }}>*</sup></p>
                  <FormControl fullWidth>
                    <select
                      id="category"
                      onChange={(e) => {
                        setSelectShop(e.target.value);
                      }}
                      style={{ border: 1, borderColor: "#c0baba", borderStyle: "solid", padding: 7, borderRadius: 3, height: 56 }}
                    >
                      <option value="" selected>Select New</option>
                      {shopCategory.map((TL, index) => (
                        <option key={index} value={TL.categoryId}>
                          {TL.categoryName}
                        </option>
                      ))}
                    </select>
                    {shopError && <div className="text-left text-[#cf3917]">{shopError}</div>}

                  </FormControl>
                </div>
              </div>

              <div className="flex justify-end mr-4 mt-6">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!isFormValid()} // Disable button if form is invalid
                  sx={{
                    textTransform: "none",
                    bgcolor: "#FF725E",
                    py: 1,
                    px: 10,
                    mt: 2,
                    "&:hover": { bgcolor: "#FF725E95" },
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
            <div>
              <GoogleMapApi
                onPinChange={(changedLocationDetails) => {
                  setSelectedPlace(changedLocationDetails);
                }}
                latit={selectedPlace?.lat}
                lngit={selectedPlace?.lng}
              />
            </div>
          </div>
        </form>

        <Snackbar open={showSuccessSnackbar} autoHideDuration={6000} onClose={handleClose}>
          <Alert sx={{ width: "100%" }} onClose={handleClose} severity="success">
            HUB CREATED SUCCESSFULLY!
          </Alert>
        </Snackbar>
        <Snackbar open={showErrorSnackbar} autoHideDuration={6000} onClose={handleClose}>
          <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
            {SnackbarMessage.replace(/_/g, " ")}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  InputAdornment,
  TextField,
  Button,
  Snackbar,
  Alert,
  LinearProgress,
} from "@mui/material";
import "./TicketAndGrievances.css";
import CreateTicketModal from "../../UI/AppModal/TicketAndGrievances/CreateTicketModal";
import UploadTicketModal from "../../UI/AppModal/TicketAndGrievances/UploadTicketAndGrievances";
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { TicketAndGrievancesApi } from "../../../API/TicketAndGrievancesApi";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TicketAndGrievancesProps } from "../../../@Types/ComponentsInterface/TicketAndGrievancesProps";

const tableHeaderCellStyle = {
  fontWeight: 600,
  fontSize: "14px",
  color: "#455A64",
  font: "Rubik",
};

const tableBodyCellStyle = {
  fontWeight: 400,
  fontSize: "14px",
  color: "#A4A2A2",
  font: "Rubik",
};

function getStatusColor(status: number) {
  if (status === 0) {
    return "#F75C3D1F"; // Escalated
  } else if (status === 1) {
    return "#FFBB0D26"; // Open
  } else if (status === 2) {
    return "#00CD5B1F"; // Closed
  }
  // Default color
  return "";
}

function getStatusTextColor(status: number) {
  if (status === 0) {
    return "#F75C3D"; // Escalated
  } else if (status === 1) {
    return "#FFBB0D"; // Open
  } else if (status === 2) {
    return "#00CD5B"; // Closed
  }
  // Default text color
  return "";
}

function getStatusText(status: number) {
  if (status === 0) {
    return "Escalated";
  } else if (status === 1) {
    return "Open";
  } else if (status === 2) {
    return "Closed";
  }
  // Default status text
  return "";
}

function getStatus(status: string) {
  if (status === "Escalated") {
    return 0;
  } else if (status === "Open") {
    return 1;
  } else if (status === "Closed") {
    return 2;
  }
  // Default status text
  return;
}

export default function TicketAndGrievances() {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/Dashboard");
  };

  const [loading, setLoading] = useState(true);
  const [ticketAndGrievancesData, setTicketAndGrievancesData] = useState<
    TicketAndGrievancesProps[]
  >([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [totalCount, setTotalCount] = useState<any>();

  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState<number | undefined>();

  const pageRange = 5; // Number of pages to display in the pagination

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(1, Math.min(newPage + 1, pageCount));
    setPage(newPageNumber - 1);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index + 1;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber - 1)}
          className={page === pageNumber - 1 ? "active" : "unactive"}
          style={{ marginLeft: "6px" }}
        >
          {pageNumber}
        </span>
      );
    });
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePop = (status: string) => () => {
    setSelectedStatus(getStatus(status));
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const handleRowSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const selectedIndex = selectedRows.indexOf(id);
    if (selectedIndex === -1) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((row) => row !== id));
    }
  };

  useEffect(() => {
    setLoading(true);
    const apiUrl = `${searchVal ? `?search=${searchVal}` : ""}`;
    TicketAndGrievancesApi.api
      ?.ticketList(page + 1, rowsPerPage, selectedStatus, apiUrl)
      .then((e) => {
        setLoading(false);
        if (Array.isArray(e.list) && e.list?.length > 0) {
          setTicketAndGrievancesData(e.list);
          setTotalCount(e.totalCount);
        } else {
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });
  }, [page, rowsPerPage, selectedStatus, searchVal]);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (/^\d*$/.test(inputValue)) {
      setSearchVal(inputValue);
      setErrorMessage("");
    } else {
      setErrorMessage("Please enter only numbers.");
    }
  };
  const handleSnackBarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  return (
    <>
      <div className="bg-gradient-to-r from-slate-100 to-slate-50">
        <div className="border-10 bg-gradient-to-r from-slate-100 to-slate-50 ">
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
            className="bg-gradient-to-r from-slate-100 to-slate-50"
          >
            {
              <Typography
                sx={{ flex: "1 1 100%", textAlign: "left" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                <a
                  onClick={navigateToHome}
                  className="cursor-pointer text-black"
                >
                  <ArrowBackIcon className="mr-4 mb-1 ml-7" />
                </a>
                <b>Tickets & Grievances</b>
              </Typography>
            }
            {
              <Typography
                sx={{
                  flex: "1 1 100%",
                  textAlign: "right",
                  marginRight: "48px",
                }}
                component="div"
              >
                <HomeIcon className="mb-1" />
                {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span>{" "}
                {" / "}
                Tickets & Grievances
              </Typography>
            }
          </Toolbar>
        </div>
        <div className="border-0 rounded-3xl pt-8 m-4 bg-white ml-12 mr-12">
          <div className="flex justify-between mb-4 pl-8 pr-8">
            <div className="flex">
              <TextField
                placeholder="Search"
                id="outlined-basic"
                variant="outlined"
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                helperText={errorMessage} // Display error message below the input
                error={Boolean(errorMessage)}
                // onKeyDown={handleKeyPress}
              />
              <Button
                sx={{
                  bgcolor: "#FF725E33",
                  color: "black",
                  ml: 2,
                  px: 2,
                  height: "2.15rem",
                  textTransform: "none",
                  "&:hover": { bgcolor: "#FF725E16" },
                }}
                startIcon={<img src="/assets/icons/filter.svg" />}
                onClick={handleClick}
              >
                Filter
              </Button>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePop}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <List>
                  <ListItem button onClick={handleClosePop("All")}>
                    All
                  </ListItem>
                  <ListItem button onClick={handleClosePop("Open")}>
                    Open
                  </ListItem>
                  <ListItem button onClick={handleClosePop("Escalated")}>
                    Escalated
                  </ListItem>
                  <ListItem button onClick={handleClosePop("Closed")}>
                    Closed
                  </ListItem>
                </List>
              </Popover>
            </div>
            <div className="flex">
              <CreateTicketModal />
              {/* <UploadTicketModal /> */}
            </div>
          </div>
          {loading ? (
            <LinearProgress
              className="pl-5 pr-5 rounded-3xl"
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
              }}
            />
          ) : (
            <>
              <TableContainer
                className="pl-5 pr-5"
                style={{ width: "100%", overflowX: "auto" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={tableHeaderCellStyle}>
                        Ticket ID
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>
                        Hub Location
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>
                        Remarks
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>
                        Contact Email
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ticketAndGrievancesData?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell style={tableBodyCellStyle}>
                          {row.id}
                        </TableCell>
                        <TableCell style={tableBodyCellStyle}>
                          {row.shopName}
                        </TableCell>
                        <TableCell style={tableBodyCellStyle}>
                          {row.message}
                        </TableCell>
                        <TableCell style={tableBodyCellStyle}>
                          {row.email}
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              backgroundColor: getStatusColor(row.status),
                              color: getStatusTextColor(row.status),
                              paddingInline: "18px",
                              paddingBlock: "4px",
                              borderRadius: "14px",
                              width: "100px",
                              font: "Rubik",
                              fontSize: "14px",
                            }}
                          >
                            {getStatusText(row.status)}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="pl-6 pt-4 pb-2 pr-16">
                <div
                  className="pagination"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <select
                    className="pl-2 pr-2 rounded"
                    value={rowsPerPage}
                    onChange={(e) => setRowsPerPage(Number(e.target.value))}
                    style={{
                      backgroundColor: "#EBEBEB",
                      fontFamily: "Rubik",
                      fontSize: "16px",
                    }}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                  </select>
                  <div>
                    <button
                      onClick={() => handlePageChange(page - 1)}
                      disabled={page === 0}
                      style={{
                        fontFamily: "Rubik",
                        fontSize: "20px",
                        marginRight: "6px",
                      }}
                    >
                      {"<"}
                    </button>
                    {renderPageNumbers()}
                    <button
                      onClick={() => handlePageChange(page + 1)}
                      disabled={page >= pageCount - 1}
                      style={{
                        fontFamily: "Rubik",
                        fontSize: "20px",
                        marginLeft: "6px",
                      }}
                    >
                      {">"}
                    </button>
                  </div>
                </div>
              </div>
              <Snackbar
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackBarClose}
              >
                <Alert
                  sx={{ width: "100%" }}
                  onClose={handleSnackBarClose}
                  severity="error"
                >
                  No data found
                </Alert>
              </Snackbar>
            </>
          )}
        </div>
      </div>
    </>
  );
}

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import * as moment from "moment";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Typography,
  useMediaQuery,
  Grid,
  LinearProgress,
  Link,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import onRide from "../../../../assets/icons/onride.svg";
import stop from "../../../../assets/icons/stop.svg";
import pause from "../../../../assets/icons/pause.svg";

const apiKeyTracker = process.env.REACT_APP_DATABASE_URL;
const key = process.env.REACT_APP_TRACKER_KEY;

// ==============================|| CHAT DRAWER ||============================== //

function ChatDrawer(props) {
  const [journeydetails, setJourneydetails] = useState([]);
  const [loaderpassword, setLoaderpassword] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const theme = useTheme();

  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const drawerBG = theme.palette.mode === "dark" ? "dark.main" : "white";
  const selectdate = props.mapdate;
  const calenderselectdate = props.selectedDate;

  let selectDatebar = moment(selectdate).format("DD-MM-YYYY");

  const starttimeFormat = (dt) => {
    // debugger; // eslint-disable-line no-debugger
    //alert('lllrr', dt)

    let fdate = "";
    if (dt) {
      fdate = dt.split(" ");
      if (fdate.length) {
        fdate = fdate[fdate.length - 1];
      }
    }
    return fdate;
  };

  useEffect(() => {
    setSelectedDate(calenderselectdate);
    setLoaderpassword(true);

    let mapdataurl = `https://data.bcykal.com/tracker/map_data/${props.cycleid}/`;

    if (selectdate) {
      if (calenderselectdate) {
        let selectdatebarcalender =
          moment(calenderselectdate).format("DD-MM-YYYY");
        mapdataurl = `https://data.bcykal.com/tracker/map_data/${props.cycleid}/${selectdatebarcalender}`;
      } else {
        let selectdatebar = moment(selectdate).format("DD-MM-YYYY");
        mapdataurl = `https://data.bcykal.com/tracker/map_data/${props.cycleid}/${selectdatebar}`;
      }
      fetch(mapdataurl, {})
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            // Check if API call was successful
            setJourneydetails(data.data);
            setLoaderpassword(false);
          } else {
            setJourneydetails([]); // Clear the timeline data
            setLoaderpassword(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching API data:", error);
          setJourneydetails([]); // Clear the timeline data
          setLoaderpassword(false);
        });
    } else {
      if (calenderselectdate) {
        let dateapiselect = moment(calenderselectdate).format("DD-MM-YYYY");
        mapdataurl = `https://data.bcykal.com/tracker/map_data/${props.cycleid}/${dateapiselect}`;
      } else {
        let dateapi = moment(props.mapdate).format("DD-MM-YYYY");
        mapdataurl = `https://data.bcykal.com/tracker/map_data/${props.cycleid}/${dateapi}`;
      }
      fetch(mapdataurl, {})
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            // Check if API call was successful
            setJourneydetails(data.data);
            setLoaderpassword(false);
          } else {
            setJourneydetails([]); // Clear the timeline data
            setLoaderpassword(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching API data:", error);
          setJourneydetails([]); // Clear the timeline data
          setLoaderpassword(false);
        });
    }
  }, [calenderselectdate]);
  let riderdata = journeydetails;


  let selectdatebarcalender = moment(calenderselectdate).format("DD-MM-YYYY");

  const addNumberKey = (data) => {
    let number = 1; // Starting number value
    for (let i = 0; i < data.length; i++) {
      if (data[i].ride_status === "off_stop") {
        data[i].number = number; // Add the "number" key to the object
        number++; // Increment the number value for the next object
      }
    }
  };
  addNumberKey(riderdata);

  const handleMarkerFocus = (item, index) => {
    
    props.setSelectedMarker?.(item);
  };
  const handleMarkerFocusStop = (item, index) => {
    props.setSelectedMarker?.(item);
  };

  function Timelinedata(item, index) {
    let time1 = starttimeFormat(item.End_time);
    let time2 = starttimeFormat(item.Start_time);
    let timelinestarttime = moment(time2, ["hh:mm:ss"]).format("HH:mm");
    let timelineendtime = moment(time1, ["hh:mm:ss"]).format("HH:mm");

    const markerColor = item.time_diff <= "00:20:00" ? "orange" : "violet";

    return (
      <>
        <TimelineItem key={index}>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="left"
            variant="body2"
            color="text.secondary"
          >
            <p style={{ margin: 0, color: "#000" }}>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {timelinestarttime} - {timelineendtime}
              </span>
            </p>
            {item.ride_status == "on_ride" ? (
              <>
                <p style={{ margin: 0, color: "#000" }}>
                  <span style={{ fontWeight: "bold" }}>Dis:</span>
                  {item.Distance} KM
                </p>
              </>
            ) : (
              <></>
            )}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />

            {item.ride_status == "on_ride" ? (
              <>
                <Link
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleMarkerFocus(item, index)}
                >
                  <TimelineDot
                    sx={{
                      backgroundColor: "#ccf5de",
                      padding: 0,
                      width: 40,
                      height: 40,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      align="center"
                      sx={{
                        height: 25,
                        width: 25,
                      }}
                      alt=""
                      src={onRide}
                    />
                  </TimelineDot>
                </Link>
              </>
            ) : item.ride_status == "off_stop" ? (
              <>
                <Link
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleMarkerFocusStop(item, index)}
                >
                  {item.number}
                  <TimelineDot
                    key={index}
                    sx={{
                      backgroundColor: markerColor,
                      mt: 0,
                      padding: 0,
                      width: 40,
                      height: 40,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      align="center"
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                      alt=""
                      src={stop}
                    />
                  </TimelineDot>
                </Link>
              </>
            ) : (
              <>
                <Link
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleMarkerFocus(item, index)}
                >
                  <TimelineDot
                    sx={{
                      backgroundColor: "#ffe3df",
                      padding: 0,
                      width: 40,
                      height: 40,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      align="center"
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                      alt=""
                      src={pause}
                    />
                  </TimelineDot>
                </Link>
              </>
            )}

            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "25px", px: 2, fontSize: "0.75rem" }}>
            <p style={{ margin: 0, color: "#000" }}>
              <span style={{ fontWeight: "bold" }}>Dur:</span> {item.time_diff}
            </p>
          </TimelineContent>
        </TimelineItem>
      </>
    );
  }
  return (
    <Box sx={{ pb: 1 }}>
      <div
        style={{
          width: "370px",
          backgroundColor: "#fff",
        }}
        border={!matchDownLG}
        content={false}
      >
        <Grid item sm={12} sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ ml: 1, color: "#CCCCCC", fontSize: 16 }}>
            Rider Journey
            {selectedDate && selectedDate ? (
              <b> {selectdatebarcalender}</b>
            ) : (
              <b> {selectDatebar} </b>
            )}
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          sx={{ display: "flex", alignItems: "center", py: 2, ml: 1 }}
        >
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Box
              component="img"
              align="center"
              sx={{
                height: 20,
                width: 20,
              }}
              alt=""
              src={onRide}
            />
            <Typography sx={{ ml: 1, fontSize: 14 }}>On Ride</Typography>
          </Grid>
          <Grid item sx={{ display: "flex", alignItems: "center", ml: 1 }}>
            <Box
              component="img"
              align="center"
              sx={{
                height: 15,
                width: 15,
              }}
              alt=""
              src={pause}
            />
            <Typography sx={{ ml: 1, fontSize: 14 }}>On Stop</Typography>
          </Grid>
          <Grid item sx={{ display: "flex", alignItems: "center", ml: 1 }}>
            <Box
              component="img"
              align="center"
              sx={{
                height: 15,
                width: 15,
              }}
              alt=""
              src={stop}
            />
            <Typography sx={{ ml: 1, fontSize: 14 }}>Off Stop</Typography>
          </Grid>
        </Grid>

        <div
          style={{
            bgcolor: "#fff",
            borderRadius: "4px 0 0 4px",
            borderRight: "none",
            height: "630px",
            overflowY: "auto",
            overflowX: "hidden",
            zIndex: 9,
            backgroundColor: "#fff",
            scrollbarWidth: "thin",
          }}
          border={!matchDownLG}
          content={false}
        >
          <Grid item sm={12}>
            {loaderpassword && (
              <LinearProgress
                sx={{
                  backgroundColor: "white",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#00CD5B",
                  },
                }}
              />
            )}
          </Grid>
          {/* Length {riderdata.length} */}
          {riderdata && riderdata.length > 0 ? (
            <Timeline position="alternate">
              {riderdata.map((item, index) =>
                index !== riderdata.length - 1 ? (
                  Timelinedata(item, index)
                ) : index !== riderdata.length - 1 ? (
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Ride End{" "}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Ride In progress
                  </Typography>
                )
              )}
            </Timeline>
          ) : (
            <>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography sx={{ fontSize: "0.8rem" }}>
                  No Data Found
                </Typography>
              </Grid>
            </>
          )}
        </div>
      </div>
    </Box>
  );
}

ChatDrawer.propTypes = {
  openChatDrawerNew: PropTypes.bool,
  setUser: PropTypes.func,
};

export default ChatDrawer;

import axios, { AxiosInstance, AxiosResponse } from "axios";
// import { response } from "express";
import {
  distanceListResponse,
  distanceListDayWiseResponse,
  distanceListYearWiseResponse,
  cycleIdModalResponse,
} from "../../@Types/DRO/ApiResponses";

export class DistanceReport {
  public static api = new DistanceReport();
  private instance: AxiosInstance;
  static googleMapsOnDutyRiders() {
    throw new Error("Method not implemented.");
  }
  constructor() {
    this.instance = axios.create({
      baseURL: "https://data.bcykal.com/distance/",
    });
  }
  formatDate = (date: Date | null): string | null => {
    if (!date) return null;

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adjust month to be 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };
  distanceReportList(
    page: number,
    pageSize: number,
    startDate: Date | null,
    endDate: Date | null
  ): Promise<distanceListResponse> {
    const formattedStartDate = startDate ? this.formatDate(startDate) : null;
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    let apiUrl = "distanceList";
    if (endDate) {
      apiUrl += `?Date=${formattedStartDate}&Date2=${formattedEndDate}`;
    }
    return this.instance
      .get<distanceListResponse>(apiUrl, {
        headers: {
          key: "secret-key",
        },
        params: {
          page: page,
          size: pageSize,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  distanceReportDayWiseList(
    page: number,
    pageSize: number,
    date: string
  ): Promise<distanceListDayWiseResponse> {
    let apiUrl = "distanceList/day";
    if (date) {
      apiUrl += `/${date}`;
    }
    return this.instance
      .get<distanceListDayWiseResponse>(apiUrl, {
        headers: {
          key: "secret-key",
        },
        params: {
          page: page,
          size: pageSize,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  cycleIdModalData(
    page: number,
    pageSize: number,
    cycleId: number | undefined,
    startDate: Date | null,
    endDate: Date | null
  ): Promise<cycleIdModalResponse> {
    const formattedStartDate = startDate ? this.formatDate(startDate) : null;
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    let apiUrl = "distanceList/listCycleId";
    if (endDate) {
      apiUrl += `/${cycleId}?Date=${formattedStartDate}&Date2=${formattedEndDate}`;
    }
    return this.instance
      .get<cycleIdModalResponse>(apiUrl, {
        headers: {
          key: "secret-key",
        },
        params: {
          page: page,
          size: pageSize,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  distanceReportYearWiseList(
    year: string,
    month: string | undefined
  ): Promise<distanceListYearWiseResponse> {
    let apiUrl = "distanceList/year";
    const selectedyear = year ? year : null;
    const selectedmonth = month ? month : null;
    return this.instance
      .get<distanceListYearWiseResponse>(apiUrl, {
        headers: {
          key: "secret-key",
        },
        params: {
          year: selectedyear,
          month: selectedmonth,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
}

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Toolbar,
  Typography,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { RiderKycApi } from "../../../API/RiderKycApi";
import { RiderKycList } from "../../../@Types/ComponentsInterface/RiderDetailsKycProps";
import "./TlCmTable.css";

const tableHeaderCellStyle = {
  fontWeight: 600,
  fontSize: "14px",
  color: "#455A64",
};

const tableBodyCellStyle = {
  fontWeight: 400,
  fontSize: "14px",
  color: "#A4A2A2",
};

export default function RiderDetailTracking() {
  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [allUserReport, setAllUserReport] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userId;
  const name = location.state?.name;
  const mobile = location.state?.mobile;

  

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  useEffect(() => {
    setLoading(true);
    RiderKycApi.api?.alluserReport(searchVal, page + 1, rowsPerPage, userid)
      .then((response) => {
        setLoading(false);
        if (response.data.fleetAttendance.length > 0) {
          setAllUserReport(response.data.fleetAttendance);
          setTotalCount(response.data.records);
        } else {
          setAllUserReport([]);
          setTotalCount(1);
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });
  }, [page, rowsPerPage, searchVal, userid]);

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    return date.toLocaleTimeString("en-GB", options);
  };
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate(); // Get the day (1-31)
    
    // Define month abbreviations
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    
    const monthIndex = date.getMonth(); // Get the month index (0-11)
    const year = date.getFullYear(); // Get the year
    
    return `${day}/${monthNames[monthIndex]}/${year}`; // Format as "Date/DD/Mon/YYYY"
  };
  const handlePageChange = (newPage: number) => {
    setPage(Math.max(0, Math.min(newPage, Math.ceil(totalCount / rowsPerPage) - 1)));
  };

  const renderPageNumbers = () => {
    const pageCount = Math.ceil(totalCount / rowsPerPage);
    return Array.from({ length: pageCount }, (_, index) => (
      <span
        key={index}
        onClick={() => handlePageChange(index)}
        className={`pagination-number ${page === index ? "active" : "unactive"}`}
        style={{ marginLeft: "6px", cursor: "pointer" }}
      >
        {index + 1}
      </span>
    ));
  };
 
  const formatAvailableTime = (timeString: string | undefined | null) => {
    if (!timeString) {
      return 'Invalid time string'; // Handle invalid or missing time strings
    }
  
    const [hours, minutes, seconds] = timeString.split(":").map(Number); // Split and convert to numbers
  
    return `${hours} hour${hours !== 1 ? 's' : ''}, ` + // Handle pluralization for hours
           `${String(minutes).padStart(2, '0')} minute${minutes !== 1 ? 's' : ''}, ` + // Handle pluralization for minutes
           `${String(seconds).padStart(2, '0')} second${seconds !== 1 ? 's' : ''}`; // Handle pluralization for seconds
  };
  

  return (
    <div style={{ height: "100%", width: "100%" }} className="border-10 bg-gradient-to-r from-slate-100 to-slate-50">
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} className="bg-gradient-to-r from-slate-100 to-slate-50">
        <Typography sx={{ flex: "1 1 100%", textAlign: "left" }} variant="h6">
          <a onClick={() => navigate("/alluserReport")} className="cursor-pointer text-black">
            <ArrowBackIcon className="mr-4 mb-1 ml-7" />
          </a>
          <b>Punch In Details</b>
        </Typography>
        <Typography sx={{ flex: "1 1 100%", textAlign: "right", marginRight: "48px" }}>
          <HomeIcon className="mb-1" /> {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span> {" / "} Punch In Details
        </Typography>
      </Toolbar>

      <div className="border-0 rounded-3xl pt-8 m-4 bg-white ml-12 mr-12">
       
        
        {loading ? (
          <LinearProgress sx={{ backgroundColor: "white", "& .MuiLinearProgress-bar": { backgroundColor: "#00CD5B" } }} />
        ) : (
          <>
            <TableContainer className="pl-5 pr-5" style={{ width: "100%", overflowX: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={tableHeaderCellStyle}>User ID</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Name</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Mobile</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Date</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Clock In</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Clock Out</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Total Hours</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allUserReport.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell style={tableBodyCellStyle}>{userid}</TableCell>
                      <TableCell style={tableBodyCellStyle}>{name}</TableCell>
                      <TableCell style={tableBodyCellStyle}>{mobile}</TableCell>
                      <TableCell style={tableBodyCellStyle}>{formatDate(row.punchInTimeIST)}</TableCell>
                      <TableCell style={tableBodyCellStyle}>
                        {formatTime(row.punchInTimeIST)}</TableCell>
                      <TableCell style={tableBodyCellStyle}>
                      {row.punchOutTimeIST ? formatTime(row.punchOutTimeIST) : "No available Clock Out"}

                        </TableCell>
                      <TableCell style={tableBodyCellStyle}>
                      {row.availableTime ? formatAvailableTime(row.availableTime) : "No available time"}

        
                        </TableCell>
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div className="pl-6 pt-4 pb-2 pr-16">
              <div className="pagination" style={{ display: "flex", justifyContent: "space-between" }}>
                <select
                  className="pl-2 pr-2 rounded"
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(parseInt(e.target.value))}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
                <div className="flex items-center">
                  {renderPageNumbers()}
                </div>
              </div>
            </div>
          </>
        )}

        <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: "100%" }}>
            No Data Found
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { DistanceReport } from "../../../API/HyperTrack/DistanceReport";
import RangeDatePicker from "../../UI/AppDatePicker/AppDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Alert,
  Button,
  LinearProgress,
  Link,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DistanceReportDayWiseList from "./DistanceReportDayWise";
import DashboardFrontCardHyperTrack from "../DashboardFrontCardHyperTrack/DashboardFrontCard";
import { CSVLink } from "react-csv";

const DistanceReportList = () => {
  const csvHeaders = [
    { label: "S.No", key: "index" },
    { label: "Date", key: "Date" },
    { label: "Total Rider", key: "total_rider" },
    { label: "IOT Distance (KM)", key: "iot_distance" },
    { label: "Carbon Saved (KG)", key: "carbon_saved" },
  ];
  const tableHeaderCellStyle = {
    fontWeight: 600,
    fontSize: "14px",
    color: "#455A64",
    font: "Rubik",
  };
  const tableBodyCellStyle = {
    fontWeight: 400,
    fontSize: "14px",
    color: "#A4A2A2",
    font: "Rubik",
  };
  const calculateRelativeDate = (days: number): Date => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - days);
    return currentDate;
  };
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [distanceReportData, setDistanceReportData] = useState<any>();
  const [startDate, setStartDate] = useState<Date | null>(
    calculateRelativeDate(8)
  );
  const [endDate, setEndDate] = useState<Date | null>(calculateRelativeDate(2));
  const [totalCount, setTotalCount] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [summary, setSummary] = useState<any>();
  const pageRange = 5;
  const [showDistancereportdaywise, setShowDistancereportdaywise] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();

  const dateClick = (date: Date) => {
    setSelectedDate(date);
    setShowDistancereportdaywise(true);
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    // Handle date range change

    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };
  useEffect(() => {
    setLoading(true);
    DistanceReport.api
      .distanceReportList(page + 1, rowsPerPage, startDate, endDate)
      .then((e) => {
        if (Array.isArray(e.data) && e.data.length > 0) {
          setDistanceReportData(e.data);
          setLoading(false);
          setSummary(e.summary);
          if (e.summary && typeof e.summary.total_record !== "undefined") {
            setTotalCount(e.summary.total_record);
          }
        } else {
          setShowSnackbar(true);
        }
      });
  }, [endDate, page, rowsPerPage]);
  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(1, Math.min(newPage + 1, pageCount));
    setPage(newPageNumber - 1);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index + 1;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber - 1)}
          className={page === pageNumber - 1 ? "active" : "unactive"}
          style={{ marginLeft: "6px" }}
        >
          {pageNumber}
        </span>
      );
    });
  };
  function formatDate(inputDate: Date) {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  function roundToDecimalPlaces(number: number) {
    const multiplier = 10 ** 2;
    return Math.round(number * multiplier) / multiplier;
  }
  return (
    <>
      <div className="flex justify-between py-2 px-6 pl-10 items-center mt-5">
        <div className="font-semibold ml-8 font-rubik">
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            Distance Report
          </Typography>
        </div>
        <div>
          <div className="flex items-center mr-8">
            <p className="mr-2 font-semibold">Date Range: </p>
            <RangeDatePicker
              onChange={handleDateChange}
              maxDaysAdd={-2}
              minDaysSub={92}
              defaultStartDate={8}
              defaultEndDate={-2}
            />
            <div>
              <CSVLink
                data={distanceReportData || []}
                headers={csvHeaders}
                filename={`distance_report_data.csv`}
              >
                <Button
                  style={{
                    backgroundColor: "#00CD5B",
                    padding: "8px 8px",
                    color: "#fff",
                    borderRadius: "5px",
                    width: "100%",
                    textTransform: "none",
                    marginLeft: "15px",
                  }}
                >
                  Download CSV
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex flex-wrap">
          <div className="flex w-full text-left my-8 px-16">
            <p>Loading summary data...</p>
          </div>
        </div>
      ) : (
        <div className="flex w-full text-left justify-evenly my-8 px-16">
          <DashboardFrontCardHyperTrack
            fromColorClass="from-[#0CAF55]"
            toColorClass="to-[#92E3A9]"
            textFirstLine="Total Fleets"
            totalcount={roundToDecimalPlaces(summary?.total_fleet)}
            avgcount={0}
            shorttext=""
            avgtext=""
            imageURL="/assets/hyper-track-icon/delivery-boy.svg"
          />

          <DashboardFrontCardHyperTrack
            fromColorClass="from-[#0CAF55]"
            toColorClass="to-[#92E3A9]"
            textFirstLine="Moving Fleets"
            totalcount={roundToDecimalPlaces(summary?.moving_fleet)}
            avgcount={0}
            avgtext=""
            shorttext=""
            imageURL="/assets/hyper-track-icon/delivery-boy.svg"
          />

          <DashboardFrontCardHyperTrack
            fromColorClass="from-[#0CAF55]"
            toColorClass="to-[#92E3A9]"
            textFirstLine="Total Distance"
            totalcount={roundToDecimalPlaces(summary?.total_distance)}
            avgcount={roundToDecimalPlaces(summary?.average_distance)}
            avgtext="Avg Distance"
            shorttext="km"
            imageURL="/assets/hyper-track-icon/delivery-bike.svg"
          />
          <DashboardFrontCardHyperTrack
            fromColorClass="from-[#0CAF55]"
            toColorClass="to-[#92E3A9]"
            textFirstLine="CO2 Saved"
            shorttext="Kg"
            totalcount={roundToDecimalPlaces(summary?.total_carbon_saved)}
            avgcount={roundToDecimalPlaces(summary?.average_carbon)}
            avgtext="Avg Carbon Saved"
            imageURL="/assets/hyper-track-icon/world.svg"
          />
        </div>
      )}
      <div className="flex flex-wrap animate__animated animate__fadeInLeft">
        {loading ? (
          <LinearProgress
            className="pl-6 pr-6"
            sx={{
              backgroundColor: "white",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#00CD5B",
              },
              width: "100%",
            }}
          />
        ) : (
          <TableContainer className="px-16">
            <Table className="px-8">
              <TableHead>
                <TableRow sx={{ display: "flex" }}>
                  <TableCell style={tableHeaderCellStyle} sx={{ flex: 0.5 }}>
                    S.No
                  </TableCell>
                  <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                    Date
                  </TableCell>
                  <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                    Total Rider
                  </TableCell>
                  <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                    IOT Distance (KM)
                  </TableCell>
                  <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                    Carbon Saved (KG)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {distanceReportData?.map((row: any) => (
                  <TableRow sx={{ display: "flex" }} key={row.index}>
                    <TableCell style={tableBodyCellStyle} sx={{ flex: 0.5 }}>
                      {row.index}
                    </TableCell>
                    <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          dateClick(row.Date);
                        }}
                      >
                        <Typography sx={{ color: "#00CD5B" }}>
                          {formatDate(row.Date)}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                      {row.total_rider}
                    </TableCell>
                    <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                      {row.day_distance}
                    </TableCell>
                    <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                      {row.carbon_saved}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <div className="pl-16 pt-4 pb-2">
          <div
            className="pagination"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 0}
                style={{
                  fontFamily: "Rubik",
                  fontSize: "20px",
                  marginRight: "6px",
                }}
              >
                {"<"}
              </button>
              {renderPageNumbers()}
              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page >= pageCount - 1}
                style={{
                  fontFamily: "Rubik",
                  fontSize: "20px",
                  marginLeft: "6px",
                }}
              >
                {">"}
              </button>
            </div>
          </div>
        </div>
        <Snackbar open={showSnackbar} autoHideDuration={6000}>
          <Alert sx={{ width: "100%" }} severity="error">
            No data found
          </Alert>
        </Snackbar>
        <div style={{width:'100%'}}>
          {showDistancereportdaywise && (
            <DistanceReportDayWiseList selectedDate={selectedDate} />
          )}
        </div>
      </div>
    </>
  );
};

export default DistanceReportList;

import { FormControl, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { Place } from "../../../../@Types/ComponentsInterface/CreateHubProps";
interface GPlaceProps {
  onPlaceSelect: (place: Place | null) => void;
  value?: Place;
}
const GPlace: React.FC<GPlaceProps> = ({ onPlaceSelect, value }) => {
  const placeInputRef = useRef<HTMLInputElement>(null);
  const [apiLoaded, setApiLoaded] = useState(false);

  const [textFieldValue, setTextFieldValue] = useState(value?.address || "");

  useEffect(() => {
    setTextFieldValue(value?.address || "");
  }, [value]);

  useEffect(() => {
    loadGoogleMapScript();
  }, []);
  const googleMapApiKey = "AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg";
  const loadGoogleMapScript = () => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}&libraries=places`;
      googleMapScript.onload = () => {
        setApiLoaded(true);
      };
      document.body.appendChild(googleMapScript);
    } else {
      setApiLoaded(true);
    }
  };

  useEffect(() => {
    if (apiLoaded && placeInputRef.current) {
      initPlaceAPI();
    }
  }, [apiLoaded]);

  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef.current as HTMLInputElement,
      {}
    );

    autocomplete.addListener("place_changed", function () {
      let place = autocomplete.getPlace();
      const offset = place?.utc_offset_minutes
        ? place.utc_offset_minutes / 60
        : 0;

      let address1 = value?.address || "";
      let postcode = value?.postcode || "";
      let country = value?.country || "";
      let administrative_area_level_1 =
        value?.administrative_area_level_1 || "";
      let city = value?.city || "";

      for (const component of place?.address_components || []) {
        address1 = `${address1} ${component.long_name}`;
        const componentType = component.types[0];

        switch (componentType) {
          case "administrative_area_level_1":
            administrative_area_level_1 = `${component.long_name}`;
            break;
          case "postal_code":
            postcode = `${component.long_name}`;
            break;
          case "locality":
            city = `${component.long_name}`;
            break;
          default:
            country = component.long_name;
            break;
        }
      }

      const selectedPlace: Place = {
        country,
        postcode,
        utc_offset: offset,
        address: address1,
        administrative_area_level_1,
        city,
        lat: place?.geometry?.location?.lat() || 0,
        lng: place?.geometry?.location?.lng() || 0,
      };

      onPlaceSelect(selectedPlace);
    });
  };

  return (
    <>
      <FormControl fullWidth>
        <TextField
          sx={{ bgcolor: "white" }}
          type="text"
          inputRef={placeInputRef}
          value={textFieldValue}
          onChange={(e) => {
            setTextFieldValue(e.currentTarget.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MapOutlinedIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </>
  );
};

export default GPlace;

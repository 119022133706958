import React from "react";
import WaveLoader from "../../WaveLoader/wave";
import Loader from "@mui/material/CircularProgress";
import { DashboardFrontCardProps } from "../../../@Types/ComponentsInterface/DashboardFrontMobileCountCardProps";

const DashboardFrontCard: React.FC<DashboardFrontCardProps> = ({
  textFirstBox,
  textSecondBox,
  firstclientData,
  secondclientData,
  imageURL,
  secondimageURL,
  lineImageURL,
}) => {
  return (
    <div>
    <div className={`flex  w-12/12 rounded-2xl bg-gradient-to-r m-4 bg-white text-black p-4`} >
      <div className="flex text-left">
        <div className="pr-2">
          <img src={imageURL} alt="" className="w-16 h-16" />
        </div>
        <div>
          <img src={lineImageURL} alt="" className="h-16" />
        </div>
        <div className="pl-2">
          <p className="font-xs text-[#727597] text-xs">{textFirstBox} </p>

          <p className="font-semibold text-[#1E1E1E] text-4xl mt-2">
            {firstclientData}
          </p>
        </div>
      </div>
    </div>
     <div  className={`flex  rounded-2xl bg-gradient-to-r m-4 bg-white text-black p-4 mt-4`} >
     <div className="flex text-left">
       <div className="pr-2">
         <img src={secondimageURL} alt="" className="w-16 h-16" />
       </div>
       <div>
         <img src={lineImageURL} alt="" className="h-16" />
       </div>
       <div className="pl-2">
         <p className="font-medium text-[#727597] text-xs">{textSecondBox} </p>

         <p className="font-semibold text-[#1E1E1E] text-4xl mt-2">
           {secondclientData}
         </p>
       </div>
     </div>
   </div>
   </div>
  );
};
export default DashboardFrontCard;

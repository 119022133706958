import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ActionModal from "../../Modals/FleetDemandActionModal";
import {
  InputAdornment,
  TextField,
  Button,
  LinearProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
// import BasicModal from "../../Modals/NewFleetDemandModal";
import "./NewFleetDemand.css";
import { useNavigate } from "react-router";
import { NewFleetDemandApi } from "../../../API/NewFleetDemand";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
const tableHeaderCellStyle = {
  fontWeight: 600,
  fontSize: "14px",
  color: "#455A64",
  font: "Rubik",
};

const tableBodyCellStyle = {
  fontWeight: 400,
  fontSize: "14px",
  color: "#A4A2A2",
  font: "Rubik",
};
export default function NewFleetDemand() {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/Dashboard");
  };
  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);

  const [fleetDemandListData, setFleetDemandListData] = useState<any>();
  const [totalCount, setTotalCount] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pageRange = 5; // Number of pages to display in the pagination

  useEffect(() => {
    setLoading(true);
    const apiUrl = `${searchVal ? `?search=${searchVal}` : ""}`;
    NewFleetDemandApi.api
      ?.hubFleetRequestList(apiUrl)
      .then((e) => {
        setLoading(false);
        if (
          Array.isArray(e.fleetRequestList) &&
          e.fleetRequestList.length > 0
        ) {
          setFleetDemandListData(e.fleetRequestList);
          setTotalCount(e.totalCount);
        } else {
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });
  }, [searchVal]);
  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(1, Math.min(newPage + 1, pageCount));
    setPage(newPageNumber - 1);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );
    const defaultPage = page <= -1 ? 0 : page;

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index + 1;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber - 1)}
          className={defaultPage === pageNumber - 1 ? "active" : "unactive"}
          style={{ marginLeft: "6px" }}
        >
          {pageNumber}
        </span>
      );
    });
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  return (
    <>
      <div className="bg-gradient-to-r from-slate-100 to-slate-50">
        <div className="border-10 bg-gradient-to-r from-slate-100 to-slate-50">
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
            className="bg-gradient-to-r from-slate-100 to-slate-50"
          >
            {
              <Typography
                sx={{ flex: "1 1 100%", textAlign: "left" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                <a
                  onClick={navigateToHome}
                  className="cursor-pointer text-black"
                >
                  <ArrowBackIcon className="mr-4 mb-1 ml-7" />
                </a>
                <b>New Fleet Demand</b>
              </Typography>
            }
            {
              <Typography
                sx={{
                  flex: "1 1 100%",
                  textAlign: "right",
                  marginRight: "48px",
                }}
                component="div"
              >
                <HomeIcon className="mb-1" />
                {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span>{" "}
                {" / "}
                New Fleet Demand
              </Typography>
            }
          </Toolbar>
        </div>
        <div className="border-0 rounded-3xl pt-8 m-4 bg-white ml-12 mr-12">
          <div className="flex justify-between mb-4 pl-8 pr-8">
            <div className="flex">
              <TextField
                placeholder="Search"
                id="outlined-basic"
                variant="outlined"
                onChange={(search) => setSearchVal(search.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                // onKeyDown={handleKeyPress}
              />
              {/* <Button
                sx={{
                  bgcolor: "#FF725E33",
                  color: "black",
                  ml: 2,
                  textTransform: "none",
                  "&:hover": { bgcolor: "#FF725E16" },
                }}
                startIcon={<img src="/assets/icons/filter.svg" />}
              >
                Filter
              </Button> */}
            </div>
            <div className="flex">
              {/* <BasicModal /> */}
              <Button
                sx={{
                  bgcolor: "#FF725E33",
                  color: "black",
                  ml: 2,
                  mr: 2,
                  textTransform: "none",
                  "&:hover": { bgcolor: "#FF725E16" },
                }}
                startIcon={
                  <img src="/assets/icons/scooter.svg" className="h-4" />
                }
                onClick={() => {
                  navigate("/NewFleetDemandForm");
                }}
              >
                New Fleet Demand
              </Button>
            </div>
          </div>
          {loading ? (
            <LinearProgress
              className="pl-6 pr-6"
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
              }}
            />
          ) : (
            <>
              <TableContainer
                style={{ width: "100%", overflowX: "auto" }}
                className="pl-5 pr-5"
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={tableHeaderCellStyle}>
                        Hub Name
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>
                        Manager's
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>
                        Hub Address
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>
                        Contact
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>
                        Fleet Demand
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>
                        Time Shift
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>
                        Start Date
                      </TableCell>
                      <TableCell style={tableHeaderCellStyle}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fleetDemandListData
                      .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                      .map((row: any) => (
                        <TableRow key={row.demandId}>
                          <TableCell style={tableBodyCellStyle}>
                            {row.shopName}
                          </TableCell>
                          <TableCell style={tableBodyCellStyle}>
                            {row.name}
                          </TableCell>
                          <TableCell style={tableBodyCellStyle}>
                            <div>
                              {row.shopAddr}
                              <LocationOnOutlinedIcon
                                sx={{ color: "#3183FF" }}
                              />
                            </div>
                          </TableCell>
                          <TableCell style={tableBodyCellStyle}>
                            {row.hmMobile}
                          </TableCell>
                          <TableCell style={tableBodyCellStyle}>
                            {row.noOfPilot}
                          </TableCell>
                          <TableCell style={tableBodyCellStyle}>
                            {row.startTime}-{row.endTime}
                          </TableCell>
                          <TableCell style={tableBodyCellStyle}>
                            {row.startDate}
                          </TableCell>
                          <TableCell style={tableBodyCellStyle}>
                            <ActionModal rowData={row} />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="pl-6 pt-4 pb-2 pr-16">
                <div
                  className="pagination"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <select
                    className="pl-2 pr-2 rounded"
                    value={rowsPerPage}
                    onChange={(e) => setRowsPerPage(Number(e.target.value))}
                    style={{
                      backgroundColor: "#EBEBEB",
                      fontFamily: "Rubik",
                      fontSize: "16px",
                    }}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                  </select>
                  <div>
                    <button
                      onClick={() => handlePageChange(page - 1)}
                      disabled={page === 0}
                      style={{
                        fontFamily: "Rubik",
                        fontSize: "20px",
                        marginRight: "6px",
                      }}
                    >
                      {"<"}
                    </button>
                    {renderPageNumbers()}
                    <button
                      onClick={() => handlePageChange(page + 1)}
                      disabled={page >= pageCount - 1}
                      style={{
                        fontFamily: "Rubik",
                        fontSize: "20px",
                        marginLeft: "6px",
                      }}
                    >
                      {">"}
                    </button>
                  </div>
                </div>
              </div>
              <Snackbar
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  sx={{ width: "100%" }}
                  onClose={handleClose}
                  severity="error"
                >
                  No data found
                </Alert>
              </Snackbar>
            </>
          )}
        </div>
      </div>
    </>
  );
}

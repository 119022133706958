import React, { useState } from "react";
import WrappedMap from "./gmap";
import moment from "moment";
import { Box, Button, Grid, InputAdornment, LinearProgress, Snackbar, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import RangeDatePicker from "../../../UI/AppDatePickerClick/AppDatePicker";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export interface Path {
  time_stamp: any;
  home_long: number;
  home_lat: number;
  rideId: number;
  cycleId: number;
  latitude: number;
  longitude: number;
  createdTime: string;
  Distance: number;
  Location: number;
  work_long: number;
  work_lat: number;
  Date: string;
  Start_time: string;
  End_time: string;
  time_diff: number;
  date: string;
}

function App(props: any) {
  const [mapdata, setMapData] = useState<Path[]>([]);
  
  const [workhomelocation, setWorkHomeLocation] = useState<any | null>(null);
  const [loaderpassword, setLoaderpassword] = useState(false);
  const [searchValInput, setSearchValInput] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleSearchClick = () => {
    setLoaderpassword(true);

    // Convert start and end dates to the desired format (assuming YYYY-MM-DD for this example)
    const formattedStartDate = startDate ? moment(startDate).format("DD-MM-YYYY") : "";
    const formattedEndDate = endDate ? moment(endDate).format("DD-MM-YYYY") : "";

    fetch(`https://data.bcykal.com/tracker/mobile_data/get/details/user_id/${searchValInput}?start_date=${formattedStartDate}&end_date=${formattedEndDate}`)
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          setMapData(data?.data);
          
          setWorkHomeLocation(data);
          setLoaderpassword(false);
        } else {
          setSnackbarMessage("No data found");
          setLoaderpassword(false);
          setSnackbarOpen(true);
          setMapData([]);
        }
      });
  };

  const handleClearSearch = () => {
    setSearchValInput("");
  };

  const handleDateChange = (start: Date | null, end: Date | null) => {
    setStartDate(start || null);
    setEndDate(end || null) ;
  };

  return (
    <div style={{ height: 600, width: `100%` }}>
      <Grid container>
        {loaderpassword && (
          <Grid item sm={12} lg={12}>
            <LinearProgress
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
              }}
            />
          </Grid>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={9000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
        />
        <div className="flex m-4 mt-6 ml-6">
          <TextField
            placeholder="Search QRCode"
            id="outlined-basic"
            variant="outlined"
            onChange={(search: any) => setSearchValInput(search.target.value)}
            value={searchValInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" onClick={handleClearSearch}>
                  <ClearIcon style={{ cursor: "pointer" }} />
                </InputAdornment>
              ),
              style: { height: "40px" },
            }}
          />
          <div className="flex items-center ml-8">
            <p className="mr-2 font-semibold">Select Date Range: </p>
            <RangeDatePicker
              onChange={handleDateChange}
              maxDaysAdd={0}
              minDaysSub={92}
              defaultStartDate={8}
              defaultEndDate={-2}
            />
          </div>
          <Button
            sx={{
              bgcolor: "#FF725E33",
              color: "black",
              ml: 2,
              mr: 2,
              textTransform: "none",
              "&:hover": { bgcolor: "#FF725E16" },
            }}
            type="submit"
            onClick={handleSearchClick}
          >
            Search
          </Button>
        </div>
      </Grid>
      <div style={{ position: 'relative' }}>
        {mapdata && mapdata.length > 0 ? (
          <div style={{ height: "100%", width: "100%" }}>
            
            <WrappedMap
              paths={mapdata.sort((o1, o2) => o1.Distance - o2.Distance)}
            />
          </div>
        ) : (
          <Box sx={{ width: "100%" }}>
            <Typography variant="subtitle1">Please Search User ID</Typography>
          </Box>
        )}
      </div>
    </div>
  );
}

export default App;

import React from "react";
import WrappedMap from "./gmap";
import moment from "moment";
import { Box, Button, Grid, InputAdornment, LinearProgress, Snackbar, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
export interface Path {
  home_long: number;
  home_lat: number;
  rideId: number;
  cycleId: number;
  latitude: number;
  longitude: number;
  createdTime: string;
  Distance: number;
  Location:number;
  work_long: number;
  work_lat: number;
  Date:string;
  Start_time:string;
  End_time:string;
  time_diff:number;
  date:string;
 
}

function App(props: any) {
  const [mapdata, setMapData] = useState<Path[]>([]);
  const [workLat, setWorkLat] = useState<any | null>(null);
  const [homeLat, setHomeLat] = useState<any | null>(null);
  const [workLng, setWorkLng] = useState<any | null>(null);
  const [homeLng, setHomeLng] = useState<any | null>(null);
  const [workhomelocation, setWorkHomeLocation] = useState<any | null>(null);
  const [loaderpassword, setLoaderpassword] = useState(false);
  const [searchValInput, setSearchValInput] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  


  const handleSearchClick = () => {
    setLoaderpassword(true);

    fetch(`https://data.bcykal.com/tracker/whole_journey/${searchValInput}`)
        .then((data) => data.json())
        .then((data) => {
          if (data.success === true) {
            setMapData(data?.data);
            setHomeLng(data.work_home_data[0]?.home_long);
            setWorkLng(data.work_home_data[0]?.work_long);
            setHomeLat(data.work_home_data[0]?.home_lat);
            setWorkLat(data.work_home_data[0]?.work_lat);
            setWorkHomeLocation(data)
            setLoaderpassword(false);
          }
          else {
            setSnackbarMessage("No data found");
            setLoaderpassword(false); 
            setSnackbarOpen(true);
            setMapData([]);
          }
          
        });
};

 
  const handleClearSearch = () => {
    setSearchValInput("");
  };
  
  //const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${config.mapsKey}`;
  return (
    <div style={{ height: 600, width: `100%` }}>
      <Grid container>
      
        {loaderpassword && (
          <Grid item sm={12} lg={12}>
            <LinearProgress
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
              }}
            />
          </Grid>
        )}
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={9000}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
          />
        <div className="flex m-4 mt-6 ml-6">
            <TextField
              placeholder="Search  QR Code"
              id="outlined-basic"
              variant="outlined"
              onChange={(search: any) => setSearchValInput(search.target.value)}
              value={searchValInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" onClick={handleClearSearch}>
                    <ClearIcon style={{ cursor: "pointer" }} />
                  </InputAdornment>
                ),
                style: { height: "40px" },
              }}
              // onKeyDown={handleKeyPress}
            />
            <Button
              sx={{
                bgcolor: "#FF725E33",
                color: "black",
                ml: 2,
                mr: 2,
                textTransform: "none",
                "&:hover": { bgcolor: "#FF725E16" },
              }}
              type="submit"
              onClick={handleSearchClick}
            >
              Search
            </Button>
          </div>
      </Grid>
      <div style={{position:'relative'}}>
      
   
      {mapdata && mapdata.length > 0 ? (
        <div style={{ height: "100%", width: "100%" }}>
          <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            sx={{
              alignItems: "center",
              my: 2,
              position: "absolute",
              right: 15,
              top: 10,
              zIndex: 9999,
              backgroundColor: "#fff",
              padding: 1,
              borderRadius: 2,
              border: 1,
              borderColor: "#CCCCCC",
            }}
          >
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#3c96ff",
                  borderRadius: 50,
                }}
              >
                &nbsp;
              </Typography>
              <Typography sx={{ ml: 1 }}>Home Location</Typography>
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#e96762",
                  borderRadius: 50,
                }}
              >
                &nbsp;
              </Typography>
              <Typography sx={{ ml: 1 }}>Work Location</Typography>
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#ef4c45",
                  borderRadius: 50,
                }}
              >
                &nbsp;
              </Typography>
              <Typography sx={{ ml: 1 }}>Rider Stop Location</Typography>
            </Grid>
           
          </Grid>
        </Grid>    
          <WrappedMap
            paths={mapdata.sort((o1, o2) => o1.Distance - o2.Distance)}
            workhomelocation={workhomelocation}
            workLat={workLat}
            workLng={workLng}
            homeLat={homeLat}
            homeLng={homeLng}
          />
        </div>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Typography variant="subtitle1">Please Search Rider EB Number</Typography>
        </Box>
      )}
    </div>
    </div>
  );
}

export default App;

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, FormControl, Snackbar, Alert } from "@mui/material";
import { ProfilePageApi } from "../../../../API/ProfilePageApi";
import { MerchantShopProps } from "../../../../@Types/ComponentsInterface/MerchantShopProps";
import { useState, useEffect } from "react";
import { HubManagerListProps } from "../../../../@Types/ComponentsInterface/HubManagerListProps";
import { TicketAndGrievancesApi } from "../../../../API/TicketAndGrievancesApi";
import { useRef } from "react";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 740,
  height: 500,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

export default function CreateTicketModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [merchantShops, setMerchantShops] = useState<any>();
  const [selectedShopId, setSelectedShopId] = useState<any>(null);
  const [additionalComment, setAdditionalComment] = useState<string | "">("");
  const [commentError, setCommentError] = useState("");

  const [hubManager, setHubManager] = useState<any>();
  const [hubManagerUserId, setHubMangerUserId] = useState(null);
  const [contact, setContact] = useState<string | "">("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const formRef = useRef<HTMLFormElement | null>(null); // Correctly initialize the ref

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile(file || null);
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
  };

  useEffect(() => {
    ProfilePageApi.api.merchantShopApi().then((e) => {
      setMerchantShops(e.merchantShops);
    });

    // if (selectedShopId) {
    //   NewFleetDemandApi.api.hubFleetManagerList(selectedShopId).then((e) => {
    //     setHubManager(e);
    //   });
    // }
  }, []);

  const handleShopNameChange = (
    event: React.ChangeEvent<{}>,
    newValue: MerchantShopProps | null
  ) => {
    if (newValue) {
      const selectedShop = merchantShops.find(
        (shop: MerchantShopProps) => shop.shopName === newValue.shopName
      );
      if (selectedShop) {
        setSelectedShopId(selectedShop.shopId);
      }
    }
  };

  const handleHubManagerChange = (
    event: React.ChangeEvent<{}>,
    newValue: HubManagerListProps | null
  ) => {
    if (newValue) {
      const selectedHubManager = hubManager.find(
        (manager: HubManagerListProps) => manager.name === newValue.name
      );
      if (selectedHubManager) {
        setHubMangerUserId(selectedHubManager.userId);
      }
    }
  };
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };

  const handleAdditionalCommentsChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    const newValue = event.target.value;
    setAdditionalComment(newValue);

    if (newValue.includes("<") || newValue.includes(">")) {
      setAdditionalComment("");
      setCommentError("Please do not use '<' and '>' characters");
    } else {
      setCommentError("");
    }
  };

  // const handleCreateTicket = async () => {
  //   const ticketandGrievancesApi = new TicketAndGrievancesApi();
  //   setLoading(true);
  //   if (selectedShopId) {
  //     try {
  //       const response = await ticketandGrievancesApi.createTicketApi(
  //         selectedShopId,
  //         hubManagerUserId,
  //         additionalComment
  //       );
  //       alert("Ticket raised successfully");
  //     } catch (error) {
  //       console.error("Error:", error);
  //       alert("Error while raising ticket");
  //     } finally {
  //       setLoading(false);
  //     }
  //   } else {
  //     alert("Please select Hub and HUb Manager");
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const ticketandGrievancesApi = new TicketAndGrievancesApi();
    const responsebody = {
      shopId: selectedShopId,
      email: contact,
      message: additionalComment,
    };
    try {
      const apiResponse = await ticketandGrievancesApi.createTicketApi(
        responsebody
      );
      setShowSuccessSnackbar(true);
    } catch (error: any) {
      setShowErrorSnackbar(true);
      setSnackbarMessage(error.response.data.errorCode);
    }
  };

  const handleContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue)) {
      setContact(emailValue);
      setErrorMessage("");
    } else {
      setErrorMessage("Please enter a valid email address.");
    }
  };

  return (
    <div>
      <Button
        sx={{
          bgcolor: "#FF725E33",
          color: "black",
          mx: 2,
          px: 2,
          textTransform: "none",
          "&:hover": { bgcolor: "#FF725E16" },
        }}
        startIcon={<img src="/assets/icons/tickets.svg" className="h-4" />}
        onClick={handleOpen}
      >
        Create Ticket & Grievances
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="flex justify-between">
            <div className="flex">
              <Typography>
                <p className="text-2xl font-semibold text-slate-600">
                  Create Ticket
                </p>
              </Typography>
            </div>
            <div className="flex">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="flex flex-row mt-8 mb-12">
              <div className="basis-1/3">
                <FormControl>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#455A64",
                    }}
                  >
                    Select Hub
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={merchantShops}
                    getOptionLabel={(option: MerchantShopProps) =>
                      option.shopName
                    }
                    sx={{ width: 300 }}
                    onChange={handleShopNameChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Hub"
                        required={true}
                      />
                    )}
                    className="mt-2"
                  />
                </FormControl>
              </div>
              <div className="basis-1/3 ml-16">
                <FormControl>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#455A64",
                      marginBottom: "8px",
                    }}
                  >
                    Contact Email
                  </Typography>
                  <TextField
                    placeholder="Contact Email"
                    sx={{ width: 300 }}
                    onChange={handleContactChange}
                    required={true}
                    helperText={errorMessage}
                    error={Boolean(errorMessage)}
                  />
                </FormControl>
              </div>
            </div>
            <FormControl>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "#455A64",
                  marginBottom: "8px",
                }}
              >
                Additional Comments
              </Typography>
              <TextField
                id="outlined-multiline-static"
                placeholder="Additional Comments"
                multiline
                rows={4}
                sx={{ width: "664px" }}
                onChange={handleAdditionalCommentsChange}
                required={true}
              />

              <p style={{ color: "red" }} className="">
                {commentError}
              </p>
            </FormControl>
            <Button
              sx={{
                textTransform: "none",
                backgroundColor: "#FF725E",
                color: "#FFFFFF",
                marginLeft: "524px",
                marginTop: "28px",
                paddingInline: "46px",
                fontSize: "14px",
                paddingBlock: "14px",
                borderRadius: "8px",
                borderBlockWidth: "0px",
                fontWeight: "bold",
                "&:hover": { bgcolor: "#FF725E95" },
              }}
              type="submit"
            >
              Submit
            </Button>
          </form>
          <Snackbar
            open={showSuccessSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              sx={{ width: "100%" }}
              onClose={handleSnackbarClose}
              severity="success"
            >
              STATUS UPDATED!
            </Alert>
          </Snackbar>
          <Snackbar
            open={showErrorSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              sx={{ width: "100%" }}
              onClose={handleSnackbarClose}
              severity="error"
            >
              {SnackbarMessage.replace(/_/g, " ")}
            </Alert>
          </Snackbar>
        </Box>
      </Modal>
    </div>
  );
}

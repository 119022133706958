import React, { useEffect, useState, useRef } from "react";
import {
  FormControl,
  Toolbar,
  Typography,
  Autocomplete,
  TextField,
  Button,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router";
import "./NewFleetDemand.css";
import { NewFleetDemandApi } from "../../../API/NewFleetDemand";
import { AppHeatMap } from "../GoogleMaps/HeatMap";
import { hubFleetRequestBody } from "../../../@Types/DTO/HubfleetRequest";
interface FleetAndShiftGroupProps {
  onRemove: () => void;
  isLast: boolean;
  onTimeSlotChange: (newTimeSlot: any) => void;
}

const FleetAndShiftGroup: React.FC<FleetAndShiftGroupProps> = ({
  onTimeSlotChange,
}) => {
  const [timeSlot, setTimeSlot] = useState({
    noOfPilots: "",
    startDate: "",
    startTime: "",
    endTime: "",
  });

  const handleTimeSlotChangeLocal = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setTimeSlot((prevTimeSlot) => ({ ...prevTimeSlot, [name]: value }));
    onTimeSlotChange({ ...timeSlot, [name]: value });
  };
  return (
    <>
      <div className="flex w-full mb-4">
        <div className="flex w-full">
          <div className="w-2/8">
            <FormControl fullWidth sx={{ p: 3 }}>
              <label className="text-left mb-3">No of Fleet:</label>
              <TextField
                placeholder="eg. 10"
                value={timeSlot.noOfPilots}
                name="noOfPilots"
                onChange={handleTimeSlotChangeLocal}
              />
            </FormControl>
          </div>
          <div className="w-3/8">
            <FormControl fullWidth sx={{ p: 3, px: 4 }}>
              <label className="text-left mb-3">Start Date:</label>
              <TextField
                name="startDate"
                id="fdate"
                type="date"
                value={timeSlot.startDate}
                onChange={handleTimeSlotChangeLocal}
                // InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </FormControl>
          </div>
          <div className="w-3/8">
            <FormControl sx={{ p: 3 }}>
              <label htmlFor="email" className="text-left mb-3">
                Select Shift Time:
              </label>
              <div className="flex items-center border border-gray-400 p-4 rounded-md focus:border-black">
                <TextField
                  id="starttime"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  name="startTime"
                  value={timeSlot.startTime}
                  onChange={handleTimeSlotChangeLocal}
                />
                <TextField
                  id="endtime"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  sx={{ ml: 1 }}
                  name="endTime"
                  value={timeSlot.endTime}
                  onChange={handleTimeSlotChangeLocal}
                />
              </div>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
};
const NewFleetDemandForm: React.FC = () => {
  const [fleetAndShiftGroups, setFleetAndShiftGroups] = useState([{ id: 0 }]);
  const [timeSlots, setTimeSlots] = useState([
    { noOfPilots: 0, startDate: "", startTime: "", endTime: "" },
  ]);

  const handleTimeSlotChange = (index: number, newTimeSlot: any) => {
    const updatedTimeSlots = [...timeSlots];
    updatedTimeSlots[index] = newTimeSlot;
    setTimeSlots(updatedTimeSlots);
  };
  const navigate = useNavigate();

  const navigateToNFD = () => {
    navigate("/NewFleetDemand");
  };
  const handleAddGroup = () => {
    const newId = fleetAndShiftGroups.length;
    setFleetAndShiftGroups([...fleetAndShiftGroups, { id: newId }]);
    setTimeSlots([
      ...timeSlots,
      { noOfPilots: 0, startDate: "", startTime: "", endTime: "" },
    ]);
  };
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const formRef = useRef<HTMLFormElement | null>(null);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
  };
  const handleRemoveLastGroup = () => {
    if (fleetAndShiftGroups.length > 1) {
      setFleetAndShiftGroups((prevGroups) => prevGroups.slice(0, -1));
      setTimeSlots((prevSlots) => prevSlots.slice(0, -1));
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newFleetDemand = new NewFleetDemandApi();
    const responsebody = {
      shopId: selectedHub?.shopId,
      address: selectedHub?.shopAddr,
      hmUserName: selectedManagername,
      hmMobile: mobileno,
      requirement: requirement,
      timeSlots: timeSlots,
    };
    try {
      const apiresponse = await newFleetDemand.addHubFleetRequest(responsebody);
      setShowSuccessSnackbar(true);
      window.location.reload();
    } catch (error: any) {
      setSnackbarMessage(error.response.data.errorCode);
      setShowErrorSnackbar(true);
    }
  };

  const [hubListData, setHubListData] = useState<any>();
  const [selectedHub, setSelectedHub] = useState<any>();
  const [requirement, setRequirement] = useState<any>();
  const [mobileno, setMobileno] = useState<any>();
  const [selectedManagername, setSelectedManagername] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    NewFleetDemandApi.api.hubList().then((e) => {
      setHubListData(e);
    });
  }, []);
  return (
    <div className="bg-gradient-to-r from-slate-100 to-slate-50">
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
        className="bg-gradient-to-r from-slate-100 to-slate-50"
      >
        {
          <Typography
            sx={{ flex: "1 1 100%", textAlign: "left" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <a onClick={navigateToNFD} className="cursor-pointer text-black">
              <ArrowBackIcon className="mr-4 mb-1 ml-7" />
            </a>
            <b>New Fleet Demand</b>
          </Typography>
        }
        {
          <Typography
            sx={{ flex: "1 1 100%", textAlign: "right", marginRight: "48px" }}
            component="div"
          >
            <HomeIcon className="mb-1" />
            {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span> {" / "}
            New Fleet Demand
          </Typography>
        }
      </Toolbar>
      <div className="border-0 rounded-3xl p-4 m-4 bg-white flex">
        {/* Form (2/3 of the screen) */}
        <div className="w-2/3">
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="flex w-full">
              <FormControl fullWidth sx={{ p: 3 }}>
                <label htmlFor="email" className="text-left mb-3">
                  Select Hub:
                </label>

                <Autocomplete
                  options={hubListData || []}
                  getOptionLabel={(option: any) => option.shopName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      name="hub"
                      id="hub"
                    />
                  )}
                  value={selectedHub}
                  onChange={(e, hubList) => {
                    setSelectedHub(hubList);
                  }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#00CD5B", pt: 1 }}
                >
                  <Link
                    sx={{ color: "#00CD5B", textDecoration: "none" }}
                    href="../../CreateHub"
                  >
                    {" "}
                    Create Hub if Not Listed
                  </Link>
                </Typography>
              </FormControl>

              <FormControl fullWidth sx={{ p: 3 }}>
                <label htmlFor="email" className="text-left mb-3">
                  Hub Address:
                </label>
                <TextField
                  placeholder="Address"
                  disabled
                  value={
                    selectedHub && selectedHub.shopAddr
                      ? selectedHub.shopAddr
                      : "No Address Available"
                  }
                ></TextField>
              </FormControl>
            </div>
            <div className="flex w-full">
              <FormControl required fullWidth sx={{ p: 3 }}>
                <label htmlFor="email" className="text-left mb-3">
                  Contact Name:
                </label>
                <TextField
                  placeholder="Contact"
                  onChange={(e) => {
                    setSelectedManagername(e.target.value);
                  }}
                  value={selectedManagername}
                  required
                />
              </FormControl>
              {/* <FormControl fullWidth sx={{ p: 3 }}>
                <label htmlFor="email" className="text-left mb-3">
                  Name of Hub Manager:
                </label>

                <Autocomplete
                  options={selectedHubManagers || []}
                  getOptionLabel={(option: any) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Hub"
                      name="hub"
                      id="hub"
                    />
                  )}
                  value={selectedManagername}
                  onChange={(e, managerName) => {
                    setSelectedManagername(managerName);
                  }}
                />
              </FormControl> */}
              <FormControl required fullWidth sx={{ p: 3 }}>
                <label htmlFor="email" className="text-left mb-3">
                  Mobile No.:
                </label>
                <TextField
                  placeholder="Mobile No."
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    if (/^\d*$/.test(inputValue)) {
                      setMobileno(inputValue);
                      setErrorMessage("");
                    } else {
                      setErrorMessage("Please enter only numbers.");
                    }
                  }}
                  value={mobileno}
                  required
                  helperText={errorMessage} 
                  error={Boolean(errorMessage)}
                />
              </FormControl>
            </div>
            <div className="flex justify-between">
              <div>
                {fleetAndShiftGroups.map((group, index) => (
                  <FleetAndShiftGroup
                    key={group.id}
                    isLast={index === fleetAndShiftGroups.length - 1}
                    onRemove={handleRemoveLastGroup}
                    onTimeSlotChange={(newTimeSlot) =>
                      handleTimeSlotChange(index, newTimeSlot)
                    }
                  />
                ))}
              </div>
            </div>
            <div className="flex justify-end itens-center mr-6">
              <Button
                variant="contained"
                sx={{ textTransform: "none", p: 1 }}
                onClick={handleAddGroup}
              >
                Add more Fleets & Shift
              </Button>
              {fleetAndShiftGroups.length > 1 && (
                <FormControl sx={{ ml: 2 }}>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{
                      textTransform: "none",
                      bgcolor: "#FF725E",
                      py: 1,
                      px: 5,
                      "&:hover": { bgcolor: "#FF725E95" },
                    }}
                    onClick={handleRemoveLastGroup}
                  >
                    Remove
                  </Button>
                </FormControl>
              )}
            </div>
            <FormControl fullWidth sx={{ p: 3 }}>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                placeholder="Additional Comments"
                onChange={(e) => {
                  setRequirement(e.target.value);
                }}
                value={requirement}
              />
            </FormControl>
            <div className="flex justify-end mr-6">
              <Button
                variant="contained"
                type="submit"
                sx={{
                  textTransform: "none",
                  bgcolor: "#FF725E",
                  py: 1,
                  px: 5,
                  mt: 2,
                  "&:hover": { bgcolor: "#FF725E95" },
                }}
              >
                Submit
              </Button>
            </div>
          </form>
          <Snackbar
            open={showSuccessSnackbar}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              sx={{ width: "100%" }}
              onClose={handleClose}
              severity="success"
            >
              FLEET REQUESTED SUCCESSFULLY!
            </Alert>
          </Snackbar>
          <Snackbar
            open={showErrorSnackbar}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              sx={{ width: "100%" }}
              onClose={handleClose}
              severity="error"
            >
              {SnackbarMessage.replace(/_/g, " ")}
            </Alert>
          </Snackbar>
        </div>

        {/* Image (1/3 of the screen) */}
        <div
          className="w-1/3 max-h-screen"
          style={{ flex: "1", backgroundColor: "lightgray" }}
        >
          <AppHeatMap />
        </div>
      </div>
    </div>
  );
};

export default NewFleetDemandForm;

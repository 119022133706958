import {
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Box,
} from "@mui/material";

import MailOutline from "@mui/icons-material/MailOutline";

function ForgotPassword() {
  return (
    <Box sx={{ minHeight: "60vh" }}>
      <Grid container>
        <Grid item xs={false} sm={4} md={6}>
          <div className="flex items-center">
            <img
              src={"/assets/icons/invoice-logo.svg"}
              alt="Logo"
              className="ml-4 mt-4"
            />
          </div>
          <Box
            component="img"
            className="w-full mt-2"
            sx={{
              height: 800,
              maxHeight: { xs: 200, md: 467 },
              maxWidth: { xs: 800, md: 1150 },
            }}
            alt="The house from the offer."
            src="/assets/login-bg.svg"
          />
        </Grid>
        <Grid
          item
          sm={8}
          md={6}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sm={8} md={7}>
            <Grid
              container
              spacing={3}
              className="w-full flex justify-between items-center"
            >
              <Grid item xs={12}>
                <p className="text-left	font-bold text-3xl text-gray-500">
                  Forgot Password ?
                </p>
                <p className="text-left	mb-8 mt-2 text-gray-500">
                  Please enter your valid email, we will send you an Auto
                  generated password on your email inbox.{" "}
                </p>
                <Stack spacing={1}>
                  <InputLabel className="text-left" htmlFor="email">
                    Enter Username
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="emailInstant"
                    name="emailInstant"
                    placeholder="Enter email address"
                    className="text-slate-300"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className="text-slate-300"
                        >
                          <MailOutline />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Button
                  sx={{
                    backgroundColor: "#00CD5B",
                    "&:hover": {
                      backgroundColor: "#63dd99",
                    },
                  }}
                  className="bg-green-500 hover:bg-green-400"
                  fullWidth
                  size="large"
                  variant="contained"
                  type="submit"
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
export default ForgotPassword;

import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import { addDays, subDays } from "date-fns";
import "./AppDatePicker.css";

registerLocale("en-GB", enGB);

interface RangeDatePickerProps {
  onChange: (start: Date | null, end: Date | null) => void; // Adjust the function signature
  minDaysSub: number;
  maxDaysAdd: number;
  defaultStartDate: number;
  defaultEndDate: number;
}

const RangeDatePicker: React.FC<RangeDatePickerProps> = ({
  onChange,
  minDaysSub,
  maxDaysAdd,
  defaultStartDate,
  defaultEndDate,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(
    subDays(new Date(), defaultStartDate)
  );
  const [endDate, setEndDate] = useState<Date | null>(
    addDays(new Date(), defaultEndDate)
  );

  const handleDateChange = (dates: [Date | null, Date | null] | null) => {
    if (dates !== null) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      onChange(start, end); // Pass both start and end dates
    } else {
      // Handle the case where both dates are cleared
      setStartDate(null);
      setEndDate(null);
      onChange(null, null);
    }
  };
  

  return (
    <DatePicker
      selectsRange
      dateFormat="dd/MM/yyyy"
      startDate={startDate}
      endDate={endDate}
      onChange={handleDateChange} // Pass the handleDateChange function
      isClearable
      minDate={subDays(new Date(), minDaysSub)}
      maxDate={addDays(new Date(), maxDaysAdd)}
      locale="en-GB"
      className="mr-1 px-4 py-1 border rounded"
    />
  );
};

export default RangeDatePicker;

import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  HeatmapLayer,
  useJsApiLoader,
} from "@react-google-maps/api";
import LinearProgress from "@mui/material/LinearProgress";
import { useLocation } from "react-router-dom";
import { HyperTrackHome } from "../../../API/HyperTrack/HomePage";

interface AppHeatMapProps {
  startDate: Date | null;
  endDate: Date | null;
  city: string | null;
  merchant: string | null;
}

const AppHeatMapComponent: React.FC<AppHeatMapProps> = ({
  startDate,
  endDate,
  city,
  merchant,
}) => {
  const location = useLocation();
  const isNewFleetDemandRoute = location.pathname.includes(
    "/NewFleetDemandForm"
  );

  const containerStyle = {
    width: "100%",
    height: isNewFleetDemandRoute ? "44rem" : "16.5rem",
  };

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [heatmapData, setHeatmapData] = useState<google.maps.LatLng[]>([]);

  useEffect(() => {
    HyperTrackHome.api
      .googleMapsOnDutyRidersHyperTrack(startDate, endDate, merchant, city)
      .then((data) => {
        setHeatmapData(
          data
            .filter((e) => e.lat && e.lng)
            .map((e) => ({
              lat: parseFloat(e.lat.toString()),
              lng: parseFloat(e.lng.toString()),
            }))
            .map((e) => new window.google.maps.LatLng(e.lat, e.lng))
        );
      });
  }, [startDate, endDate, city, merchant]);

  useEffect(() => {
    if (map && heatmapData.length > 0) {
      const centerLatLng = heatmapData[0];
      const center = {
        lat: centerLatLng.lat(),
        lng: centerLatLng.lng(),
      };
      map.setCenter(center);
    }
  }, [map, heatmapData]);

  const onLoad = (map: google.maps.Map) => {
    setMap(map);
  };

  const onUnmount = () => {
    setMap(null);
  };
  const calculateHeatmapCenter = (
    heatmapData: google.maps.LatLng[]
  ): google.maps.LatLng | google.maps.LatLngLiteral | undefined => {
    if (heatmapData.length === 0) {
      return undefined;
    }

    const sum = heatmapData.reduce(
      (accumulator, point) => {
        return {
          lat: accumulator.lat + point.lat(),
          lng: accumulator.lng + point.lng(),
        };
      },
      { lat: 0, lng: 0 }
    );

    return {
      lat: sum.lat / heatmapData.length,
      lng: sum.lng / heatmapData.length,
    };
  };
  const center = calculateHeatmapCenter(heatmapData);

  return (
    <>
      {heatmapData && heatmapData.length > 0 ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={8}
          options={{
            fullscreenControl: true,
            zoomControl: true,
          }}
          center={center}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onZoomChanged={() => {
            // console.log(map?.getZoom());
          }}
        >
          <HeatmapLayer
            data={heatmapData}
            options={{
              radius: 40,
              opacity: 0.8,
              map,
              //   gradient: ["#00ff00", "#ffff00", "#ff0000"],
            }}
          />
        </GoogleMap>
      ) : (
        <div className="w-full">
          <LinearProgress
            sx={{
              backgroundColor: "white",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#00CD5B",
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export const AppHeatMap = React.memo(AppHeatMapComponent);

import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { NewsCardProps } from "../../../@Types/ComponentsInterface/NewsCardProps";

const truncateText = (text: string, wordsCount: number) => {
  const words = text?.split(" ");
  if (words?.length > wordsCount) {
    return words?.slice(0, wordsCount)?.join(" ") + " ...";
  }
  return text;
};

const NewsAndFeedCard: React.FC<NewsCardProps> = ({
  fileId,
  body,
  heading,
  url,
}) => {
  const truncatedHeading = truncateText(heading, 7);
  const truncatedBody = truncateText(body, 10);
  return (
    <Card sx={{ maxWidth: 236, maxHeight: 320, boxShadow: "0" }}>
      <CardMedia
        component="img"
        height="60"
        sx={{height:'100px'}}
        image={`https://dash.bcykal.com//mobycy/api/media/file/${fileId}`}
        className="rounded-md"
      />

      <CardContent>
        <Typography>
          <p className="text-left text-sm font-medium mb-2">
            {truncatedHeading}
          </p>
        </Typography>

        <Typography variant="body2" color="text.primary" className="text-left">
          <p
            className="text-left"
            // style={{ maxHeight: "3.1em", overflow: "hidden" }}
          >
            {truncatedBody}
          </p>
        </Typography>
      </CardContent>

      <CardActions className="mb-4 ml-2 items-baseline">
        <Link href={`${url}`} target="_blank" underline="hover" variant="body2">
          Read More
        </Link>
      </CardActions>
    </Card>
  );
};

export default NewsAndFeedCard;

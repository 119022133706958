import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GoogleMap, MarkerF as Marker } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";

interface WorkHomeLocationModalProps {
  work_latit: number | undefined;
  work_lngit: number | undefined;
  home_latit: number | undefined;
  home_lngit: number | undefined;
  userId: number | undefined;
  modalOpen: boolean;
  onClose: () => void;
  cycleId: string;
  date: Date | null;
}
const googleMapApiKey = "AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const containerStyle = {
  width: "40vw",
  height: "19.5rem",
};
async function getLocality(
  apiKey: string,
  latitude: number | undefined,
  longitude: number | undefined
): Promise<string | null> {
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      throw new Error(`Failed to fetch address. Status: ${response.status}`);
    }

    const data = await response.json();

    if (data.status === "OK" && data.results.length > 0) {
      const result = data.results[0];
      // Extract the name of the first locality and sublocality if available
      const localityComponent = result.address_components.find(
        (component: any) => component.types.includes("sublocality_level_1")
      );
      const cityComponent = result.address_components.find((component: any) =>
        component.types.includes("locality")
      );
      const postalCodeComponent = result.address_components.find(
        (component: any) => component.types.includes("postal_code")
      );

      // Return the combined result of city, sublocality, and postal code if found
      if (localityComponent && cityComponent && postalCodeComponent) {
        const locality = localityComponent.long_name;
        const city = cityComponent.long_name;
        const postalCode = postalCodeComponent.long_name;
        return `${locality}, ${city}, ${postalCode}`;
      } else {
        // If any of the components is missing, return the formatted address
        return result.formatted_address;
      }
    } else {
      throw new Error(`No results found for the provided coordinates.`);
    }
  } catch (error: any) {
    console.error(`Error fetching locality: ${error.message}`);
    return null;
  }
}

const WorkHomeLocationModal: React.FC<WorkHomeLocationModalProps> = ({
  work_latit,
  work_lngit,
  home_latit,
  home_lngit,
  userId,
  modalOpen,
  onClose,
  cycleId,
  date,
}) => {
  const [homeAddress, setHomeAddress] = useState<string | null>(null);
  const [workAddress, setWorkAddress] = useState<string | null>(null);
  const [loadingWorkAddress, setLoadingWorkAddress] = useState(true);
  const [loadingHomeAddress, setLoadingHomeAddress] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const fetchedWorkAddress = await getLocality(
          googleMapApiKey,
          work_latit,
          work_lngit
        );
        const fetchedHomeAddress = await getLocality(
          googleMapApiKey,
          home_latit,
          home_lngit
        );

        if (isMounted) {
          setWorkAddress(fetchedWorkAddress);
          setLoadingWorkAddress(false);

          setHomeAddress(fetchedHomeAddress);
          setLoadingHomeAddress(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoadingWorkAddress(false);
        setLoadingHomeAddress(false);
      }
    };

    if (modalOpen) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [modalOpen, work_latit, work_lngit, home_latit, home_lngit]);

  let center = {
    lat: work_latit || 28.7136,
    lng: work_lngit || 77.1294,
  };
  const navigate = useNavigate();
  const handleNavigate = (
    cycleId: string,
    userId: number | undefined,
    Date: Date | null
  ) => {
    navigate("/zypptracker/ridertrack", {
      state: { userid: userId, date: Date, cycleid: cycleId },
    });
  };
  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-between  items-center">
            <div className="flex">
              <p>
               <b> Work Home Location of CycleID: {cycleId}</b>
                </p>
            </div>
            <div>
              <div className="flex justify-end">
                <Button
                  style={{
                    backgroundColor: "#00CD5B",
                    padding: "8px 8px",
                    color: "#fff",
                    borderRadius: "5px",
                    width: "100%",
                    textTransform: "none",
                    marginLeft: "15px",
                  }}
                  onClick={() => handleNavigate(cycleId, userId, date)}
                >
                View Rider Journey
                </Button>
              </div>
            </div>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Grid className="flex items-center mb-2">
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#3c96ff",
                  borderRadius: 50,
                }}
              >
                &nbsp;
              </Typography>
              <Typography sx={{ ml: 1 }}>Home Location</Typography>
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center", ml:4 }}>
              <Typography
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#ef4c45",
                  borderRadius: 50,
                }}
              >
                &nbsp;
              </Typography>
              <Typography sx={{ ml: 1 }}>Work Location</Typography>
            </Grid>
          </Grid>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={12}
          >
            {!loadingHomeAddress && !loadingWorkAddress && (
              <>
                {home_latit !== undefined && home_lngit !== undefined && (
                  <Marker
                    title={`Home: ${homeAddress || "Address Not Found"}`}
                    icon={"/assets/hyper-track-icon/home-location.png"}
                    position={{ lat: home_latit, lng: home_lngit }}
                  />
                )}
                {work_latit !== undefined && work_lngit !== undefined && (
                  <Marker
                    title={`Work: ${workAddress || "Address Not Found"}`}
                    icon={"/assets/hyper-track-icon/work-location.png"}
                    position={{ lat: work_latit, lng: work_lngit }}
                  />
                )}
              </>
            )}
          </GoogleMap>
        </Box>
      </Modal>
    </div>
  );
};

export default WorkHomeLocationModal;

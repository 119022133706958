import React, { useEffect, useState } from "react";
import { HyperTrackHome } from "../../../API/HyperTrack/HomePage";
import RangeDatePicker from "../../UI/AppDatePicker/AppDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import WrappedMap from "../../Layouts/HyperTrack/3wWholejurney/gmap";
import WrappedMapLive from "../../Layouts/HyperTrack/3wRiderLiveLoction/gmap";

import {
  Alert,
  FormControl,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Link,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  Button,
  Grid,
  Box,
} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export interface Path {
  latitude: number;
  longitude: number;
  createdTime: string;
 
}
const HyperTrackHomepage = () => {
 
  const tableHeaderCellStyle = {
    fontWeight: 600,
    fontSize: "14px",
    color: "#455A64",
    font: "Rubik",
  };
  const tableBodyCellStyle = {
    fontWeight: 400,
    fontSize: "14px",
    color: "#A4A2A2",
    font: "Rubik",
  };
  async function getLocality(
    apiKey: string,
    latitude: number,
    longitude: number
  ): Promise<string | null> {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch address. Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const result = data.results[0];
        // Extract the name of the first locality and sublocality if available
        const localityComponent = result.address_components.find(
          (component: any) => component.types.includes("sublocality_level_1")
        );
        const cityComponent = result.address_components.find((component: any) =>
          component.types.includes("locality")
        );
        const postalCodeComponent = result.address_components.find(
          (component: any) => component.types.includes("postal_code")
        );

        // Return the combined result of city, sublocality, and postal code if found
        if (localityComponent && cityComponent && postalCodeComponent) {
          const locality = localityComponent.long_name;
          const city = cityComponent.long_name;
          const postalCode = postalCodeComponent.long_name;
          return `${locality}, ${city}, ${postalCode}`;
        } else {
          // If any of the components is missing, return the formatted address
          return result.formatted_address;
        }
      } else {
        throw new Error(`No results found for the provided coordinates.`);
      }
    } catch (error: any) {
      console.error(`Error fetching locality: ${error.message}`);
      return null;
    }
  }
  
  const calculateRelativeDate = (days: number): Date => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - days);
    return currentDate;
  };
  const [loading, setLoading] = useState(false);
  const [loadingMap, setLoadingMap] = useState(false);
  const [loadingLive, setLoadingLive] = useState(false);


  const [showSnackbar, setShowSnackbar] = useState(false);
  const [threewheelerListData, setThreewheelerListData] = useState<any>();
  const [threewheelerJourneyData, setThreewheelerJourneyData] = useState<any>();
  const [threewheelerLiveLocation, setThreewheelerLiveLocation] = useState<any>();


  const [startDate, setStartDate] = useState<Date | null>(calculateRelativeDate(0));
  const [endDate, setEndDate] = useState<Date | null>(calculateRelativeDate(0));

  const [totalCount, setTotalCount] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [hotspots, setHotspots] = useState<any>();
  const [addressData, setAddressData] = useState<
    Array<{ location: string | null; totalOrders: number }>
  >([]);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [searchValInput, setSearchValInput] = useState<string | null>(null);

  const fetchHotspotAddresses = async () => {
    const newAddressData: Array<{
      location: string | null;
      totalOrders: number;
    }> = [];

    for (const hotspot of hotspots) {
      try {
        const addresses = await getLocality(
          googleMapApiKey,
          hotspot.Hotspot_Lat,
          hotspot.Hotspot_Long
        );
        const location = addresses !== null ? addresses : "Unknown Address";
        newAddressData.push({ location, totalOrders: hotspot.totalOrders });
      } catch (error) {
        console.error(error);
        newAddressData.push({
          location: "Unknown Address",
          totalOrders: hotspot.totalOrders,
        });
      }
    }

    setAddressData(newAddressData);
  };

  useEffect(() => {
    if (hotspots && hotspots.length > 0) {
      fetchHotspotAddresses();
    }
  }, [hotspots]);
  const pageRange = 5;
  const navigate = useNavigate();

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    // Handle date range change

    setStartDate(dates[0]);
    setEndDate(dates[1]);
   

  };
  
  const handleSearchClick = () => { 
    setSearchVal(searchValInput);
    setLoading(true); // Set loading state while fetching data
    setLoadingMap(true); // Set loading state while fetching data
    setLoadingLive(true); // Set loading state while fetching data


    const formattedStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
    const formattedEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";





   HyperTrackHome.api
        .threewheelerTrackerTabledata(formattedStartDate, formattedEndDate, page + 1, searchValInput)
        .then((e) => {
            const voltageData = e.data.loader_voltage_data;
            const recordsData = e.data.records;

            // Update state with fetched data
            setThreewheelerListData(voltageData);
            setTotalCount(recordsData);
            setLoading(false); // Loading is false after data is fetched

        })
        .catch((error) => {
            console.error('Error fetching data:', error);
            setLoading(false); // Stop loading state in case of error
        });

        HyperTrackHome.api
        .threewheelerRiderJourney(formattedStartDate, formattedEndDate,  searchValInput)
        .then((e) => {
            const voltageData = e.data.loader_journey_data;

            // Update state with fetched data
            setThreewheelerJourneyData(voltageData);
            setLoadingMap(false); // Loading is false after data is fetched
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
            setLoadingMap(false); // Stop loading state in case of error
        });
        HyperTrackHome.api
        .threewheelerRiderLiveLocation(formattedStartDate, formattedEndDate, searchValInput)
        .then((e) => {
            const voltageData = e.data.last_location_data;

            // Update state with fetched data
            setThreewheelerLiveLocation(voltageData);
            setLoadingLive(false); // Loading is false after data is fetched
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
            setLoadingLive(false); // Stop loading state in case of error
        });
     
  };
  useEffect(() => {
    handleSearchClick();
  },[endDate, page])
  const handleClearSearch = () => {
    setSearchVal("");
    setSearchValInput("");
  };
  
  function formatDate(inputDate: Date) {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  const pageCount = Math.ceil(totalCount / rowsPerPage);
  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(1, Math.min(newPage + 1, pageCount));
    setPage(newPageNumber - 1);

  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index + 1;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber - 1)}
          className={page === pageNumber - 1 ? "active" : "unactive"}
          style={{ marginLeft: "6px" }}
        >
          {pageNumber}
        </span>
      );
    });
  };
 
  const googleMapApiKey = "AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg";
 
  return (
    <div className="flex flex-wrap animate__animated animate__fadeInLeft bg-[#f2f2f2] px-12">
      <div className="flex justify-between w-full mt-4">
        <div className="flex">
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
          Three Wheeler Tracker
          </Typography>
        </div>
        <div className="flex  pr-2">
          
        <div className="flex">
              <TextField
                placeholder="Search Chassis Number"
                id="outlined-basic"
                variant="outlined"
                onChange={(search: any) =>
                  setSearchValInput(search.target.value)
                }
                value={searchValInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={handleClearSearch}>
                      <ClearIcon style={{ cursor: "pointer" }} />
                    </InputAdornment>
                  ),
                  style: { height: "40px" },
                }}
                // onKeyDown={handleKeyPress}
              />
              <Button
                sx={{
                  bgcolor: "#FF725E33",
                  color: "black",
                  ml: 2,
                  mr: 2,
                  textTransform: "none",
                  "&:hover": { bgcolor: "#FF725E16" },
                }}
                onClick={handleSearchClick}
              >
                Search
              </Button>
            </div>
          <div className="flex items-center ">
            <p className="mr-2 font-semibold">Date Range: </p>
            <RangeDatePicker
              onChange={handleDateChange}
              maxDaysAdd={0}
              minDaysSub={1000}
              defaultStartDate={0}
              defaultEndDate={0}
            />
          </div>
        </div>
      </div>

     
      <div className="flex justify-between w-full mt-5">
        <div className="flex-grow w-1/6	 h-80 rounded-lg bg-[#fff] m-4 shadow">
          <div className="flex justify-between items-center">
            <h2 className="text-center ml-4 mx-1 mt-2 font-bold text-base text-[#2B313F]">
            Three wheeler Rider Whole Journey
            </h2>
           
          </div>
          <div className="flex flex-col items-center justify-center p-3 animate_animated animate__slideInUp">
          {loadingMap ? (
  <LinearProgress
    className="pl-6 pr-6"
    sx={{
      backgroundColor: "white",
      "& .MuiLinearProgress-bar": {
        backgroundColor: "#00CD5B",
      },
      width: "100%",
    }}
  />
) : (
  !threewheelerJourneyData || threewheelerJourneyData.length === 0 ? (
    <Box sx={{ width: "100%" }}>
      <Typography variant="subtitle1">Please Search Chassis Number</Typography>
    </Box>
  ) : null
)}
<div style={{ width: "100%", height: "5.5rem" }}>
          {threewheelerJourneyData && threewheelerJourneyData.length > 0 ? (

          <WrappedMap
           paths={threewheelerJourneyData}
          
          />
        ):null
      }
      </div>
          </div>
        </div>
        <div className="flex-grow w-1/6	 h-80 rounded-lg bg-[#fff] m-4 shadow">
          <div className="flex justify-between items-center">
            <h2 className="text-center ml-4 mx-1 mt-2 mb-3 font-bold text-base text-black">
               Three wheeler Rider Live Location
            </h2>
            <div className="flex">
            <IconButton
  size="small"
  aria-label="Refresh"
  onClick={() => {
    setLoadingLive(true); // Show loading state
    const formattedStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
    const formattedEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";

    HyperTrackHome.api
      .threewheelerRiderLiveLocation(formattedStartDate, formattedEndDate, searchValInput)
      .then((e) => {
        const voltageData = e.data.last_location_data;
        setThreewheelerLiveLocation(voltageData);
        setLoadingLive(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoadingLive(false); // Stop loading state in case of error
      });
  }}
>
  <RefreshIcon />
</IconButton>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center px-4 pb-3">
          {loadingLive ? (
  <LinearProgress
    className="pl-6 pr-6"
    sx={{
      backgroundColor: "white",
      "& .MuiLinearProgress-bar": {
        backgroundColor: "#00CD5B",
      },
      width: "100%",
    }}
  />
) : (
  !threewheelerLiveLocation || threewheelerLiveLocation.length === 0 ? (
    <Box sx={{ width: "100%" }}>
      <Typography variant="subtitle1">Please Search Chassis Number</Typography>
    </Box>
  ) : null
)}
<div style={{ width: "100%", height: "5.5rem" }}>
          {threewheelerLiveLocation && threewheelerLiveLocation.length > 0 ? (

          <WrappedMapLive
           paths={threewheelerLiveLocation}
          
          />
        ):null
      }
      </div>
            {/* <img src={"/assets/icons/map.svg"} alt="" className="w-9/12" /> */}
          </div>
        </div>
        
      </div>

      <>
        <div className="w-full">


        {loading ? (
  <LinearProgress
    className="pl-6 pr-6"
    sx={{
      backgroundColor: "white",
      "& .MuiLinearProgress-bar": {
        backgroundColor: "#00CD5B",
      },
      width: "100%",
    }}
  />
) : (
  !threewheelerListData || threewheelerListData.length === 0 ? (
    <Box sx={{ width: "100%", height:'200px' }}>
      <Typography variant="subtitle1">Please Search Chassis Number</Typography>
    </Box>
  ) : null
)}
          {threewheelerListData && threewheelerListData.length > 0 ? (
            <div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ display: "flex" }}>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 0.5 }}>
                      S.No
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 2 }}>
                    Created Date
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                    Voltage
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                    State Of Charge
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                    State Of Health
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                    Temperature
                    </TableCell>
                   
                  </TableRow>
                </TableHead>
                <TableBody>
                  {threewheelerListData?.map((row: any) => (
                    <TableRow sx={{ display: "flex" }} key={row.index}>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 0.5 }}>
                        {row.index}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 2 }}>
                      {formatDate(row.createdTime)}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        {row.voltage}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        {row.soc}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        {row.soh}
                      </TableCell>
                      
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        {row.temperature}
                      </TableCell>
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          
          <div className="pl-2 pt-4 pb-2 pr-16">
            <div
              className="pagination"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <button
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 0}
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "20px",
                    marginRight: "6px",
                  }}
                >
                  {"<"}
                </button>
                {renderPageNumbers()}
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page >= pageCount - 1}
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "20px",
                    marginLeft: "6px",
                  }}
                >
                  {">"}
                </button>
              </div>
            </div>
          </div>
          </div>
          )
          : null
        }
        </div>
        <Snackbar open={showSnackbar} autoHideDuration={6000}>
          <Alert sx={{ width: "100%" }} severity="error">
            No data found
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};

export default HyperTrackHomepage;

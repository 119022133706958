import React from "react";
import WrappedMap from "./gmap";
import moment from "moment";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { useState, useEffect } from "react";

export interface Path {
  Start_time: string;
  End_time: string;
  time_diff: string;
  latitude: number;
  longitude: number;
  lockStatus: number;
  ride_status: string;
  Distance: number;
  cycleId: number;
  date: string;
}

function App(props: any) {
  const [mapdata, setMapData] = useState<Path[]>([]);
  const [workLat, setWorkLat] = useState<any | null>(null);
  const [homeLat, setHomeLat] = useState<any | null>(null);
  const [workLng, setWorkLng] = useState<any | null>(null);
  const [homeLng, setHomeLng] = useState<any | null>(null);

  const [loaderpassword, setLoaderpassword] = useState(false);
  const [offStopRides, setOffStopRides] = useState([]);
  const [journeydetails, setJourneydetails] = useState([]);
  const [stopjourneydetails, setStopJourneydetails] = useState([]);

  const selectdate = props.mapdate;
  const calenderselectdate = props.selectedDate;
  const starttimeFormat = (dt: string) => {
    // debugger; // eslint-disable-line no-debugger
    //alert('lllrr', dt)

    let fdate: string | string[] = "";
    if (dt) {
      fdate = dt.split(" ");
      if (fdate.length) {
        fdate = fdate[fdate.length - 1];
      }
    }
    return fdate;
  };
  const endtimeFormat = (dt: any) => {
    // debugger; // eslint-disable-line no-debugger
    //alert('lllrr', dt)

    let fdate = "";
    if (dt) {
      fdate = dt.split(" ");
      if (fdate.length) {
        fdate = fdate[fdate.length - 1];
      }
    }
    return fdate;
  };

  useEffect(() => {
    setLoaderpassword(true);

    let mapdataurl = `https://data.bcykal.com/tracker/map_data/${props.cycleid}/`;

    if (selectdate) {
      if (calenderselectdate) {
        let selectdatebarcalender =
          moment(calenderselectdate).format("DD-MM-YYYY");
        mapdataurl = `https://data.bcykal.com/tracker/map_data/${props.cycleid}/${selectdatebarcalender}`;
      } else {
        let selectdatebar = moment(selectdate).format("DD-MM-YYYY");
        mapdataurl = `https://data.bcykal.com/tracker/map_data/${props.cycleid}/${selectdatebar}`;
      }
      fetch(mapdataurl, {})
        .then((data) => data.json())
        .then((data) => {
          let tempData: any = [];
          if (data.data && data.data.length !== 0) {
            for (let i = 0; i < data.data.length; i++) {
              if (i !== 0) {
                if (
                  tempData[tempData.length - 1]["ride_status"] ==
                  data.data[i]["ride_status"]
                ) {
                  // debugger; // eslint-disable-line no-debugger
                  tempData[tempData.length - 1]["End_Time_IST"] =
                    data.data[i]["Time_IST"];
                } else {
                  data.data[i]["End_Time_IST"] = data.data[i]["Time_IST"];
                  //debugger; // eslint-disable-line no-debugger
                  tempData.push(data.data[i]);
                }
              } else {
                data.data[i]["End_Time_IST"] = data.data[i]["Time_IST"];
                //debugger; // eslint-disable-line no-debugger
                tempData.push(data.data[i]);
              }
            }
          } else {
            // oldDates.push(formatDate(selectdate));
          }
          const offStopRidesData = tempData.filter(
            (ride: any) => ride.ride_status === "off_stop"
          );

          setStopJourneydetails(offStopRidesData);
          setLoaderpassword(false);
        });
    } else {
      if (calenderselectdate) {
        let dateapiselect = moment(calenderselectdate).format("DD-MM-YYYY");
        mapdataurl = `https://data.bcykal.com/tracker/map_data/${props.cycleid}/${dateapiselect}`;
      } else {
        let dateapi = moment(props.mapdate).format("DD-MM-YYYY");
        mapdataurl = `https://data.bcykal.com/tracker/map_data/${props.cycleid}/${dateapi}`;
      }
      fetch(mapdataurl, {})
        .then((data) => data.json())
        .then((data) => {
          let tempData: any = [];
          for (let i = 0; i < data.data.length; i++) {
            if (i !== 0) {
              if (
                tempData[tempData.length - 1]["ride_status"] ==
                data.data[i]["ride_status"]
              ) {
                // debugger; // eslint-disable-line no-debugger
                tempData[tempData.length - 1]["End_Time_IST"] =
                  data.data[i]["Time_IST"];
              } else {
                data.data[i]["End_Time_IST"] = data.data[i]["Time_IST"];
                //debugger; // eslint-disable-line no-debugger
                tempData.push(data.data[i]);
              }
            } else {
              data.data[i]["End_Time_IST"] = data.data[i]["Time_IST"];
              //debugger; // eslint-disable-line no-debugger
              tempData.push(data.data[i]);
            }
          }
          const offStopRidesData = tempData.filter(
            (ride: any) => ride.ride_status === "off_stop"
          );
          setStopJourneydetails(offStopRidesData);

          setLoaderpassword(false);
        });
    }
  }, [calenderselectdate]);
  let riderdata = stopjourneydetails;


  let mapdataurl = `https://data.bcykal.com/tracker/map_data_prod/${props.cycleid}/`;

  const fetchData = async () => {
    try {
      const response = await fetch(mapdataurl, {});
      const data = await response.json();
      if (!data || !data.data || !Array.isArray(data.data)) {
        console.error("Invalid API response format:", data);
        return;
      }
      let tempData: any = [];
      if (data.data.length !== 0) {
        for (let i = 0; i < data.data.length; i++) {
          if (i !== 0) {
            if (
              tempData[tempData.length - 1]["ride_status"] ==
              data?.data[i]["ride_status"]
            ) {
              // debugger; // eslint-disable-line no-debugger
              tempData[tempData.length - 1]["End_Time_IST"] =
                data?.data[i]["Time_IST"];
            } else {
              data.data[i]["End_Time_IST"] = data?.data[i]["Time_IST"];
              //debugger; // eslint-disable-line no-debugger
              tempData.push(data?.data[i]);
            }
          } else {
            data.data[i]["End_Time_IST"] = data.data[i]["Time_IST"];
            //debugger; // eslint-disable-line no-debugger
            tempData.push(data?.data[i]);
          }
        }
      }
      // Filter data for "off_stop" ride_status
      const offStopRidesData = tempData.filter(
        (ride: any) => ride.ride_status === "off_stop"
      );
      setJourneydetails(tempData);

      setOffStopRides(offStopRidesData);

      for (let i = 0; i < tempData.length; i++) {
        let time1 = moment(
          tempData[i + 1] && endtimeFormat(tempData[i + 1]["Time_IST"]),
          "hh:mm:ss"
        );
        let time2 = moment(starttimeFormat(tempData[i].Time_IST), "hh:mm:ss");

        const startTime = time2; // replace with your start time
        const endTime = time1; // replace with your end time

        const durationMs =
          new Date(endTime.toString()).getTime() -
          new Date(startTime.toString()).getTime();
        const durationMinutes = Math.floor(durationMs / 1000); // convert milliseconds to minutes, rounding down

        const minduration = Math.floor(durationMinutes / 60);
        const secduration = durationMinutes % 60;
        tempData[i].duration = `${minduration} Min ${secduration} Sec`;
      }
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  };
  useEffect(() => {
    setLoaderpassword(true);

    if (selectdate) {
      if (calenderselectdate) {
        let selectdatebarcalender =
          moment(calenderselectdate).format("DD-MM-YYYY");
        mapdataurl = `https://data.bcykal.com/tracker/map_data_prod/${props.cycleid}/${selectdatebarcalender}`;
      } else {
        let selectdatebar = moment(selectdate).format("DD-MM-YYYY");
        mapdataurl = `https://data.bcykal.com/tracker/map_data_prod/${props.cycleid}/${selectdatebar}`;
      }
      fetch(mapdataurl, {})
        .then((data) => data.json())
        .then((data) => {
          setMapData(data?.data);
          setHomeLng(data?.home_lng);
          setWorkLng(data?.work_lng);
          setHomeLat(data?.home_lat);
          setWorkLat(data?.work_lat);
          setLoaderpassword(false);
        });
    } else {
      if (calenderselectdate) {
        let dateapiselect = moment(calenderselectdate).format("DD-MM-YYYY");
        mapdataurl = `https://data.bcykal.com/tracker/map_data_prod/${props.cycleid}/${dateapiselect}`;
      } else {
        let dateapi = moment(selectdate).format("DD-MM-YYYY");
        mapdataurl = `https://data.bcykal.com/tracker/map_data_prod/${props.cycleid}/${dateapi}`;
      }
      fetch(mapdataurl, {})
        .then((data) => data.json())
        .then((data) => {
          setMapData(data?.data);
          setHomeLng(data?.home_lng);
          setWorkLng(data?.work_lng);
          setHomeLat(data?.home_lat);
          setWorkLat(data?.work_lat);
          setLoaderpassword(false);
        });
    }

    fetchData();
  }, [calenderselectdate]);

  //const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${config.mapsKey}`;
  return (
    <div style={{ height: 600, width: `100%` }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            sx={{
              alignItems: "center",
              my: 2,
              position: "absolute",
              right: 15,
              top: 10,
              zIndex: 9999,
              backgroundColor: "#fff",
              padding: 1,
              borderRadius: 2,
              border: 1,
              borderColor: "#CCCCCC",
            }}
          >
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#3c96ff",
                  borderRadius: 50,
                }}
              >
                &nbsp;
              </Typography>
              <Typography sx={{ ml: 1 }}>Home Location</Typography>
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#ef4c45",
                  borderRadius: 50,
                }}
              >
                &nbsp;
              </Typography>
              <Typography sx={{ ml: 1 }}>Work Location</Typography>
            </Grid>
            
            
          </Grid>
        </Grid>
        {loaderpassword && (
          <Grid item sm={12} lg={12}>
            <LinearProgress
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
              }}
            />
          </Grid>
        )}
      </Grid>
      {mapdata && mapdata.length > 0 ? (
        <div style={{ height: "100%", width: "100%" }}>
          <WrappedMap
            currentlySelectedMarkerIndex={props.selectMarker || -1}
            paths={mapdata.sort((o1, o2) => o1.Distance - o2.Distance)}
            stops={stopjourneydetails}
            workLat={workLat}
            workLng={workLng}
            homeLat={homeLat}
            homeLng={homeLng}
          />
        </div>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Typography variant="subtitle1">No data Found</Typography>
        </Box>
      )}
    </div>
  );
}

export default App;

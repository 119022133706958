import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer
} from "@react-google-maps/api";
import { HyperTrackHome } from "../../../API/HyperTrack/HomePage";
import LinearProgress from "@mui/material/LinearProgress";
import styled from "@emotion/styled";
import { Link } from "@mui/material";
import WorkHomeLocationModal from "../HyperTrack/WorkHomeLocationModal";

const containerStyle = {
  width: "100%",
  height: "100%",
};

interface MapProps {
  startDate: Date | null;
  endDate: Date | null;
  city: string | null;
  merchant: string | null;
}

const GoogleMapApi: React.FC<MapProps> = ({
  startDate,
  endDate,
  city,
  merchant,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedMarker, setSelectedMarker] = useState<number | null>(null);
  const [mapCenter, setMapCenter] = useState({ lat: 28.7136, lng: 77.1294 });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    HyperTrackHome.api
      .googleMapsOnDutyRidersHyperTrack(startDate, endDate, merchant, city)
      .then((e) => {
        setData(e);
        setLoading(false);
        if (e.length > 0 && city) {
          const avgLat = e.reduce((sum, item) => sum + item.lat, 0) / e.length;
          const avgLng = e.reduce((sum, item) => sum + item.lng, 0) / e.length;
          setMapCenter({ lat: avgLat, lng: avgLng });
        }
      });
  }, [startDate, endDate, merchant, city]);

  const getCustomMarkerIcon = () => {
    const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="62" height="42" viewBox="0 0 62 42">
    <g id="scooter-delivery" transform="translate(-1 -11)">
      <path id="Path_14359" data-name="Path 14359" d="M6.98,33.98a1.024,1.024,0,0,1-.71-.29L3.29,30.71A.99.99,0,0,1,3,30V26a1,1,0,0,1,2,0v3.59l2.69,2.69a1,1,0,0,1,0,1.41A.982.982,0,0,1,6.98,33.98Z" fill="#575f6b"/>
      <path id="Path_14360" data-name="Path 14360" d="M43,17h3v4H43a2.006,2.006,0,0,1-2-2h0A2.006,2.006,0,0,1,43,17Z" fill="#00cd5b"/>
      <path id="Path_14361" data-name="Path 14361" d="M60.32,43A6.844,6.844,0,0,1,61,46a7,7,0,0,1-14,0,6.844,6.844,0,0,1,.68-3Z" fill="#504254"/>
      <circle id="Ellipse_266" data-name="Ellipse 266" cx="3" cy="3" r="3" transform="translate(51 43)" fill="#fcddad"/>
      <path id="Path_14362" data-name="Path 14362" d="M6.29,48a7,7,0,0,0,13.42,0Z" fill="#504254"/>
      <path id="Path_14363" data-name="Path 14363" d="M63,43H46.8l4.21-4.39A2.013,2.013,0,0,1,52.45,38h1.74a10.15,10.15,0,0,1,2.81.4,10.015,10.015,0,0,1,5,3.35ZM14,26H28a2.006,2.006,0,0,1,2,2v3H12V28A2.006,2.006,0,0,1,14,26Z" fill="#00cd5b"/>
      <path id="Path_14364" data-name="Path 14364" d="M30,29v2H12V28a2.006,2.006,0,0,1,2-2h1v1a2.006,2.006,0,0,0,2,2Z" fill="#00893d"/>
      <path id="Path_14365" data-name="Path 14365" d="M57,38v.4a10.15,10.15,0,0,0-2.81-.4H52.45a2.013,2.013,0,0,0-1.44.61L46.8,43l-3.91,4.08a3.01,3.01,0,0,1-2.17.92H1V43.51a12.043,12.043,0,0,1,.37-2.98,12.257,12.257,0,0,1,8.02-8.66L12,31H30a10.233,10.233,0,0,0-3,7.24V44H40l5.66-5.66A7.985,7.985,0,0,0,48,32.69V22h4.11l.64,7.86a10.173,10.173,0,0,0,2.22,5.6L57,38Z" fill="#fc4847"/>
      <path id="Path_14366" data-name="Path 14366" d="M57,38v.4a10.15,10.15,0,0,0-2.81-.4H52.45a2.013,2.013,0,0,0-1.44.61L46.8,43l-3.91,4.08a3.01,3.01,0,0,1-2.17.92H1V43.51a12.043,12.043,0,0,1,.37-2.98,12.257,12.257,0,0,1,8.02-8.66L12,31H30a10.233,10.233,0,0,0-3,7.24V44H40l5.66-5.66A7.985,7.985,0,0,0,48,32.69V22h4.11l.64,7.86a10.173,10.173,0,0,0,2.22,5.6L57,38Z" fill="#00cd5b"/>
      <path id="Path_14367" data-name="Path 14367" d="M57,38v.4a10.15,10.15,0,0,0-2.81-.4H52.45a2.013,2.013,0,0,0-1.44.61L46.8,43l-3.91,4.08a3.01,3.01,0,0,1-2.17.92H1V43.51a12.043,12.043,0,0,1,.37-2.98,12.206,12.206,0,0,1,6.67-8.11c-.27.23-.52.49-.77.75a12.2,12.2,0,0,0-2.9,5.36A12.043,12.043,0,0,0,4,41.51V44a2.006,2.006,0,0,0,2,2H40.72a3.01,3.01,0,0,0,2.17-.92L46.8,41l4.21-4.39A2.013,2.013,0,0,1,52.45,36h1.74a9.075,9.075,0,0,1,1.28.09L57,38Z" fill="#00893d"/>
      <path id="Path_14368" data-name="Path 14368" d="M13,38h0a8,8,0,0,1,8,8v2H5V46A8,8,0,0,1,13,38Z" fill="#00893d"/>
      <path id="Path_14369" data-name="Path 14369" d="M52,16v6H48a3,3,0,0,1,0-6h4Z" fill="#00cd5b"/>
      <rect id="Rectangle_1708" data-name="Rectangle 1708" width="4" height="6" transform="translate(52 16)" fill="#fcddad"/>
      <rect id="Rectangle_1709" data-name="Rectangle 1709" width="20" height="15" transform="translate(1 11)" fill="#00cd5b"/>
      <path id="Path_14370" data-name="Path 14370" d="M21,24v2H1V11H4v7a6,6,0,0,0,6,6Z" fill="#00893d"/>
      <rect id="Rectangle_1710" data-name="Rectangle 1710" width="6" height="5" transform="translate(8 11)" fill="#fc4847"/>
      <path id="Path_14371" data-name="Path 14371" d="M14,14v2H8V11h2v1a2.006,2.006,0,0,0,2,2Z" fill="#f43737"/>
      <path id="Path_14372" data-name="Path 14372" d="M45.77,19v2H43a2.006,2.006,0,0,1-2-2,2.03,2.03,0,0,1,1.02-1.74A2.005,2.005,0,0,0,44,19Z" fill="#00893d"/>
      <path id="Path_14373" data-name="Path 14373" d="M57.66,51.96A6.889,6.889,0,0,1,54,53a7,7,0,0,1-7-7,6.844,6.844,0,0,1,.68-3h2.61A6.908,6.908,0,0,0,50,45a7,7,0,0,0,7,7A4.872,4.872,0,0,0,57.66,51.96Z" fill="#3e3342"/>
      <path id="Path_14374" data-name="Path 14374" d="M56.98,46.24A2.995,2.995,0,0,1,51,46a2.929,2.929,0,0,1,1.02-2.24A.974.974,0,0,0,52,44a2.989,2.989,0,0,0,4.98,2.24Z" fill="#fcc489"/>
      <path id="Path_14375" data-name="Path 14375" d="M16.65,51.97A7,7,0,0,1,6.29,48H9.67A7,7,0,0,0,16,52C16.22,52,16.44,51.99,16.65,51.97Z" fill="#3e3342"/>
      <path id="Path_14376" data-name="Path 14376" d="M52,20v2H48a3,3,0,0,1-2.12-5.12,3.058,3.058,0,0,1,1.25-.75A3.1,3.1,0,0,0,47,17a3,3,0,0,0,3,3h2Z" fill="#00893d"/>
      <path id="Path_14377" data-name="Path 14377" d="M56,20v2H52V16h2v2A2.006,2.006,0,0,0,56,20Z" fill="#fcc489"/>
      <path id="Path_14378" data-name="Path 14378" d="M63,43H46.8l4.21-4.39A2.013,2.013,0,0,1,52.45,38h.31a2,2,0,0,0,1.73,3h6.84a9.666,9.666,0,0,1,.67.75L63,43ZM21,46v2H5V46a8,8,0,0,1,5.17-7.48A8,8,0,0,0,8,44a2.006,2.006,0,0,0,2,2Z" fill="#00cd5b"/>
    </g>
  </svg>
    `;
    const svgIcon = `data:image/svg+xml;base64,${btoa(svgContent)}`;
    return {
      url: "https://miro.medium.com/v2/resize:fit:786/format:webp/1*nZ9VwHTLxAfNCuCjYAkajg.png",
      scaledSize: new window.google.maps.Size(5.5, 5.5),
    };
  };

  const [workHomeLocationData, setWorkHomeLocationData] = useState<any | null>(
    null
  );

  const fetchData = async (cycleId: number) => {
    HyperTrackHome.api.workHomeLocation(cycleId, endDate).then((e) => {
      setWorkHomeLocationData(e);
    });
  };

  const handleMarkerClick = (index: number) => {
    setSelectedMarker(index);
  };
  const handleQrCodeClick = (index: number) => {
    const cycleId = data[index]?.cycleId;
    if (cycleId) {
      fetchData(cycleId);
      handleOpen();
    }
  };
  const handleClose = () => {
    setOpen(false);
    // Additional cleanup or logic on modal close if needed
  };
  return (
    <>
      {data ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={12}
        >
          <MarkerClusterer averageCenter enableRetinaIcons gridSize={60} >
  {(clusterer) => (
    <>
          {data.map((item, index) => (
            <Marker
              key={index}
              position={{
                lat: parseFloat(item.lat),
                lng: parseFloat(item.lng),
              }}
              icon={getCustomMarkerIcon()}             
              onMouseOver={() => handleMarkerClick(index)}
              clusterer={clusterer}

            />
          ))}
           </>
  )}
</MarkerClusterer>

          {selectedMarker !== null && (
            <>
              <InfoWindow
                position={{
                  lat: parseFloat(data[selectedMarker].lat),
                  lng: parseFloat(data[selectedMarker].lng),
                }}
                onCloseClick={() => setSelectedMarker(null)}
              >
                <div>
                  <p className="font-bold">
                    <Link
                      style={{ cursor: "pointer" }}
                      onClick={() => handleQrCodeClick(selectedMarker)}
                    >
                      <b>{data[selectedMarker].qrCode}</b>
                    </Link>
                  </p>
                </div>
              </InfoWindow>
              {workHomeLocationData && (
                <WorkHomeLocationModal
                  modalOpen={open}
                  work_latit={workHomeLocationData.work_lat}
                  work_lngit={workHomeLocationData.work_lng}
                  home_latit={workHomeLocationData.home_lat}
                  home_lngit={workHomeLocationData.home_lng}
                  userId={workHomeLocationData.userId}
                  date={endDate}
                  cycleId={data[selectedMarker].cycleId}
                  onClose={handleClose} // Pass onClose function as a prop
                />
              )}
            </>
          )}
        </GoogleMap>
      ) : (
        <div className="w-full">
          <LinearProgress
            sx={{
              backgroundColor: "white",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#00CD5B",
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default GoogleMapApi;

import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
  //Polygon
} from "@react-google-maps/api";
import moment from "moment";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import "./style.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Path } from "../3wTracker";
import { Link } from "@mui/material";
//import axios from 'axios';

function Map({
  paths,
}: {
  paths: Path[];
 
}) {
  

  
  const [map, setMap] = useState<google.maps.Map>();
  const [startAddress, setStartAddress] = useState<string | null>(null);
  const [selectedMarkerstart, setSelectedMarkerstart] = useState<number | null>(null);

  const googleMapApiKey = "AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg";
  
  let mapPaths: Path[] = paths;
  
  const startLat = mapPaths[0].latitude;
  const startLng = mapPaths[0].longitude;
  const starttime = mapPaths[0].createdTime;

  const starttimeIST = moment.utc(starttime).tz("Asia/Kolkata");
  let StartDate = starttimeIST.format("DD-MM-YYYY");
  let StartTime = starttimeIST.format("HH:mm:ss");



  const fetchData = async () => {
    try {
      const fetchedallWorkAddress = await getLocality(
        googleMapApiKey,
        startLat,
        startLng
      );
      
      setStartAddress(fetchedallWorkAddress);

     
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  async function getLocality(
    apiKey: string,
    latitude: number | undefined,
    longitude: number | undefined
  ): Promise<string | null> {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch address. Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const result = data.results[0];
        // Extract the name of the first locality and sublocality if available
        const localityComponentone = result.address_components.find(
          (component: any) => component.types.includes("sublocality_level_2")
        );
           const localityComponent = result.address_components.find(
          (component: any) => component.types.includes("sublocality_level_1")
        );
        const cityComponent = result.address_components.find((component: any) =>
          component.types.includes("locality")
        );
        const postalCodeComponent = result.address_components.find(
          (component: any) => component.types.includes("postal_code")
        );

        // Return the combined result of city, sublocality, and postal code if found
        if (localityComponent && cityComponent && postalCodeComponent && localityComponentone) {
          const localityone = localityComponentone.long_name;
          const locality = localityComponent.long_name;
          const city = cityComponent.long_name;
          const postalCode = postalCodeComponent.long_name;
          return `${localityone},${locality}, ${city}, ${postalCode}`;
        } else {
          // If any of the components is missing, return the formatted address
          return result.formatted_address;
        }
      } else {
        throw new Error(`No results found for the provided coordinates.`);
      }
    } catch (error: any) {
      console.error(`Error fetching locality: ${error.message}`);
      return null;
    }
  }
 
 
  const handleMarkerStart = (e: any) => {
    setSelectedMarkerstart(e);
  };
 
 
  let iconMarkerStart = "https://zypp.app/clientdashboard-mailer/livelocation.gif";


  return (
    <div>
    
      <Card variant="outlined">
        <div className="gMapCont">
          <GoogleMap
            onLoad={(mapDetails: google.maps.Map) => {
              setMap(mapDetails); // Map object stored
            }}
            zoom={8}
            center={{ lat: startLat, lng: startLng }}

            options={{
              fullscreenControl: true,
              mapTypeControl: true,
              streetViewControl: true,
            }}
            // journeydetails={journeydetails}
            mapContainerStyle={{
              width: "100%",
              height: "16.5rem",
            }}
          >
           
           <Marker
              icon={iconMarkerStart}
              position={{ lat: startLat, lng: startLng }}
              onClick={() => handleMarkerStart(mapPaths)}

            />

            {selectedMarkerstart !== null && (
              <InfoWindow
                options={{ pixelOffset: new window.google.maps.Size(0, -100) }}
                onCloseClick={() => setSelectedMarkerstart(null)}
                position={{
                  lat: startLat,
                  lng: startLng,
                }}
              >
                <div>
                  <p>
                    <b>{StartDate} {StartTime}</b>
                  </p>
                  <p>
                    <b>Live Location Address: </b>
                    {startAddress || "Unknown Address"}
                  </p>
                  <p>
                    <b>Latitude:</b> {startLat} <b>Longitude:</b> {startLng}
                  </p>
                  <p>
                    <b>Google Maps Link:</b>{" "}
                    <a
                      target="_blank"
                      href={`https://maps.google.com/?q=${startLat},${startLng}`}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      https://maps.google.com/?q={startLat},{startLng}
                    </a>
                  </p>
                </div>
              </InfoWindow>
            )}
           
           
           
            

          
           
          </GoogleMap>
        </div>
      </Card>
    </div>
  );
}

// const WrappedMap = withScriptjs(withGoogleMap(Map));

export default Map;

import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/system";
import { TeamAndSpocsProps } from "../../../@Types/ComponentsInterface/TeamAndSpocsProps";
import ExpandIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  fontSize: "18px",
  fontWeight: "bold",
  color: "#FFFFFF",
}));

function getBackgroundColorForLevel(level: number) {
  switch (level) {
    case 1:
      return "#00CD5B"; // Green for level 1
    case 2:
      return "orange"; // Orange for level 2
    case 3:
      return "red"; // Red for level 3
    default:
      return "#000000"; // Default color for unknown levels
  }
}

function avatarName(fullName: string) {
  const names = fullName.split(" ");

  if (names.length === 1) {
    return names[0].slice(0, 2);
  } else {
    return names[0][0] + names[names.length - 1][0];
  }
}

const TeamAndSpocsCard: React.FC<TeamAndSpocsProps> = ({
  name,
  designation,
  email,
  mobile,
  level,
  hubName,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const cardHeaderStyle = {
    backgroundColor: getBackgroundColorForLevel(level), // Set background color based on level
  };

  if (!hubName) {
    return (
      <Card
        sx={{
          width: 345,
          maxHeight: 300,
          boxShadow: "none",
          borderColor: getBackgroundColorForLevel(level),
        }}
        className="border-2 mr-1"
      >
        <CardHeader
          avatar={
            <StyledAvatar
              aria-label="recipe"
              sx={{ backgroundColor: getBackgroundColorForLevel(level) }}
            >
              {avatarName(name)}
            </StyledAvatar>
          }
          title={
            <div className="flex">
              <span className="font-extrabold font-rubik text-base text-[#263238]">
                {name}
              </span>
            </div>
          }
          subheader={
            <div className="flex">
              <span className="text-sm font-rubik font-semibold text-[#455A64]">
                {designation}
              </span>
              <span className="text-end"></span>
            </div>
          }
        />
        <div className="text-end -mt-6">
          <Button
            aria-expanded={expanded}
            onClick={handleExpandClick}
            endIcon={
              <ExpandIcon
                sx={[
                  {
                    transform: "rotate(0deg)",
                    transition: (theme) =>
                      theme.transitions.create("all", {
                        duration: theme.transitions.duration.shortest,
                      }),
                  },
                  expanded && {
                    transform: "rotate(180deg)",
                  },
                ]}
              />
            }
          ></Button>
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardActions className="ml-3 -mb-3">
            <img
              src="/assets/icons/mail.svg"
              alt="lock"
              className="mr-2 ml-1"
            />{" "}
            <a
              href={`mailto:${email}`}
              className="text-[#3183FF] font-rubik font-semibold text-sm"
            >
              {email}
            </a>
            <br />
          </CardActions>
          <div className="flex mb-2 ml-5 mt-2 ">
            {level < 4 ? (
              <div className="flex items-center">
                <img
                  src="/assets/icons/smartphone.svg"
                  alt="lock"
                  className="mr-2"
                />
                <div className=" text-sm font-rubik font-semibold text-[#455A64]">
                  {""}
                  {mobile}
                </div>

                {/* <img
                  src="/assets/icons/lock.svg"
                  alt="lock"
                  className="mr-8 ml-2"
                />{" "} */}
                <div className="border-2 px-1 py-1 text-sm rounded-md text-[#FF725E] font-rubik ml-36 font-extrabold items-end">
                  {" "}
                  Level {level}
                </div>
              </div>
            ) : (
              <div className="border-2 px-1 py-1 rounded-md text-[#FF725E] font-rubik ml-60 font-bold items-end">
                {" "}
                Level {level}
              </div>
            )}
          </div>
        </Collapse>
      </Card>
    );
  }
  return (
    <Card
      sx={{
        maxWidth: 345,
        maxHeight: 300,
        boxShadow: "none",
        borderColor: getBackgroundColorForLevel(level),
      }}
      className="border-2 border-green-500"
    >
      <CardHeader
        avatar={
          <StyledAvatar
            aria-label="recipe"
            sx={{ backgroundColor: getBackgroundColorForLevel(level) }}
          >
            {avatarName(name)}
          </StyledAvatar>
        }
        title={
          <div className="flex">
            <span className="font-bold text-base">{name}</span>
          </div>
        }
        subheader={
          <div className="flex">
            <span className="text-sm">
              {designation} ({hubName})
            </span>
          </div>
        }
      />
      <div className="text-end -mt-6">
        <Button
          aria-expanded={expanded}
          onClick={handleExpandClick}
          endIcon={
            <ExpandIcon
              sx={[
                {
                  transform: "rotate(0deg)",
                  transition: (theme) =>
                    theme.transitions.create("all", {
                      duration: theme.transitions.duration.shortest,
                    }),
                },
                expanded && {
                  transform: "rotate(180deg)",
                },
              ]}
            />
          }
        ></Button>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit></Collapse>
    </Card>
  );
};

export default TeamAndSpocsCard;

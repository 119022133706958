import axios, { AxiosInstance, AxiosResponse } from "axios";
import { MerchantShop, ProfileInfo } from "../@Types/DRO/ApiResponses";
export class ProfilePageApi {
  public static api = new ProfilePageApi();
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: "https://stag-micro.bcykal.com/mobycy/clientDash/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  getToken() {
    return localStorage.getItem("access_token");
  }

  profileInfoApi(): Promise<ProfileInfo> {
    return this.instance
      .get<ProfileInfo>("get/profile", {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }

  merchantShopApi(): Promise<MerchantShop> {
    return this.instance
      .get<MerchantShop>("getMerchantShop/v2", {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }

  changePasswordApi(password: string | null): Promise<AxiosResponse> {
    const data = { password };
    const config = {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    };
    return this.instance.post("change/user/password", data, config);
  }

  changeAccountDeactivateRequestApi(): Promise<AxiosResponse> {
    const config = {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    };
    return this.instance.post("accountDeactivate", null, config);
  }

  changeHubRequestApi(shopId: number | null): Promise<AxiosResponse> {
    const config = {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
      params: {
        shopId: shopId,
      },
    };
    return this.instance.post("change/hub", null, config);
  }
}

import * as React from "react";
import GoogleMapApi from "../../../components/Layouts/GoogleMaps/Maps";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";



export default function DataTable() {
  return (
    <div
      style={{ height: "100%", width: "100%" }}
      className="border-10 bg-gradient-to-r from-slate-100 to-slate-50"
    >
    <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display:'flex',
          alignItems:'center'
        }}
        className="bg-gradient-to-r from-slate-100 to-slate-50"
      >
        {
          <Typography
            sx={{ flex: "1 1 100%", textAlign: "left" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <ArrowBackIcon className="mr-4 mb-1 ml-7" />
            <b>Live EV Riders</b>
          </Typography>
        }
        {
          <Typography
            sx={{ flex: "1 1 100%", textAlign: "right", marginRight: "48px" }}
            component="div"
          >
            <HomeIcon className="mb-1" />
            {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span> {" / "}
            Live Tracking
          </Typography>
        }
      </Toolbar>
    <div className="flex flex-col items-center justify-center p-8">
       <div style={{ width: "100%", height: "26.5rem"}}>
              <GoogleMapApi />
              </div>
              {/* <img src={"/assets/icons/map.svg"} alt="" className="w-9/12" /> */}
            </div>
            </div>
  );
}

import React from "react";
import NewsAndFeedCard from "./NewsFeedCard";
import { NewsCardProps } from "../../../@Types/ComponentsInterface/NewsCardProps";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import "./NewsCarousel.css";

interface CarouselProps {
  newsData: NewsCardProps[];
}

const Carousel: React.FC<CarouselProps> = ({ newsData }) => {
  return (
    <Swiper
      slidesPerView={5}
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Navigation]}
      className="mySwiper"
    >
      <div className="p-4">
        {newsData.map((newsItem: NewsCardProps, index: number) => (
          <SwiperSlide key={index}>
            <NewsAndFeedCard
              fileId={newsItem.fileId}
              heading={newsItem.heading}
              body={newsItem.body}
              url={newsItem.url}
            />
          </SwiperSlide>
        ))}
      </div>
    </Swiper>
  );
};

export default Carousel;

import React from 'react';
import './style.css'; 

const WaveLoader = () => {
  const numWaves = 20; 

  return (
    <div className="center">
      {Array.from({ length: numWaves }, (_, index) => (
        <div key={index} className="wave"></div>
      ))}
    </div>
  );
};

export default WaveLoader;

import React from "react";
import WaveLoader from "../../WaveLoader/wave";
import Loader from "@mui/material/CircularProgress";
import { DashboardFrontCardProps } from "../../../@Types/ComponentsInterface/DashboardFrontCardProps";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

const DashboardFrontCard: React.FC<DashboardFrontCardProps> = ({
  fromColorClass,
  toColorClass,
  textFirstLine,
  incrementPercentage,
  imageURL,
  monthLineData,
  children,
}) => {
  const cardStyle = {
    background: `linear-gradient(to right, ${fromColorClass}, ${toColorClass})`,
  };
  const merchantName = localStorage.getItem("merchantName")?.toLowerCase();
  if (!monthLineData || monthLineData.length === 0) {
    return (
      <div
        className={`flex-grow w-24 h-52 rounded-2xl bg-gradient-to-r m-4  ${fromColorClass} ${toColorClass}`}
        style={cardStyle}
      >
        <div className="flex justify-between m-4 mb-0 pb-0 text-left">
          <div>
            <p className="text-white font-semibold text-sm">{textFirstLine} </p>
            <p className="text-white font-semibold text-sm">
              Zypp with {merchantName}
            </p>
            <table className="w-4/5 min-w-max mt-1">
              <tr className="px-2">
                <td className="border-r-2 border-white pr-4">
                  <Loader sx={{ color: "white" }} />
                </td>
                <td className="px-4">
                  <Loader sx={{ color: "white" }} />
                </td>
              </tr>
              <tr>
                <td className="pr-4">
                  <p className="text-white text-xs font-semibold text-center">
                    LAST MONTH
                  </p>
                </td>
                <td className="px-4">
                  <p className="text-white text-xs font-semibold text-center">
                    OVERALL
                  </p>
                </td>
              </tr>
            </table>{" "}
            <p className="text-white font mt-4 text-xs">
              <Loader className="p-1" sx={{ color: "white" }} />
              Last Month
            </p>
          </div>
          <div>
            <img src={imageURL} alt="" className="w-32 h-32" />
          </div>
        </div>
        <div className="mb-2 h-20 mt-0 pl-3 pr-3 pb-6">
          <WaveLoader />
        </div>
      </div>
    );
  }
  const formattedData = monthLineData
    ?.map((item) => ({
      date: new Date(item.date),
      data: item.dataValue,
    }))
    .reverse();
  interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: string;
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      const { data, date } = payload[0].payload;
      const formattedDate = new Date(date).toLocaleDateString();

      return (
        <div className="custom-tooltip border-slate-200	rounded-sm p-1">
          <p className="text-white">{formattedDate}</p>
          <p className="text-white"> {data}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`flex-grow w-24 h-52 rounded-2xl bg-gradient-to-r m-4 ${fromColorClass} ${toColorClass}`}
      style={cardStyle}
    >
      <div className="flex justify-between m-4 mb-0 pb-0 text-left">
        <div>
          <p className="text-white font-semibold text-sm">{textFirstLine} </p>
          <p className="text-white font-semibold text-sm">
            Zypp with {merchantName}
          </p>
          <table className="w-4/5 min-w-max mt-1">
            <tr className="px-2">
              <td className="border-r-2 border-white pr-4">
                <p className="text-white text-xl font-bold">
                  {React.Children.toArray(children)[0]}
                </p>
              </td>
              <td className="px-4">
                <p className="text-white text-xl font-bold">
                  {React.Children.toArray(children)[1]}
                </p>
              </td>
            </tr>
            <tr>
              <td className="pr-4">
                <p className="text-white text-xs font-semibold text-center">
                  LAST MONTH
                </p>
              </td>
              <td className="px-4">
                <p className="text-white text-xs font-semibold text-center">
                  OVERALL
                </p>
              </td>
            </tr>
          </table>

          <p className="text-white font mt-4 text-xs">
            {incrementPercentage <= 0 ? (
              <span className="bg-black/[0.6] py-1 px-2 mr-2 rounded-sm">
                {incrementPercentage}%
              </span>
            ) : (
              <span className="bg-black/[0.6] py-1 px-2 mr-2 rounded-sm">
                +{incrementPercentage}%
              </span>
            )}
            LAST MONTH
          </p>
        </div>
        <div>
          <img src={imageURL} alt="" className="w-32 h-32" />
        </div>
      </div>
      <div className="h-20 mt-0 pl-3 pt-2 pr-3 pb-6">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={formattedData}>
            <CartesianGrid
              stroke="transparent"
              vertical={false}
              horizontal={false}
            />
            <XAxis dataKey="date" hide />
            <YAxis hide />
            <Tooltip content={<CustomTooltip />} cursor={false} />
            <Line
              type="monotone"
              dataKey="data"
              stroke="rgba(0, 0, 0, 0.7)"
              strokeWidth={2}
              dot={false}
              className="opacity-60"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
export default DashboardFrontCard;

import React from "react";
import WaveLoader from "../../WaveLoader/wave";
import Loader from "@mui/material/CircularProgress";
import { DashboardFrontCardProps } from "../../../@Types/ComponentsInterface/DashboardFrontMobileTrackerCardProps";
import { Divider } from "@mui/material";

const DashboardFrontCard: React.FC<DashboardFrontCardProps> = ({
  textFirstLine,
  imageURL,
  lineImageURL,
  children,
  clientData,
  textSecondLine,
  textThirdLine,
  iotConnectedNumber,
  iotDisconnectedNumber,
  greylineImageURL,
  livetext,
  notlivetext,
  iotconnectedlivecounts,
  iotconnectednonlivecounts,
  iotdisconnectedlivecounts,
  iotdisconnectednonlivecounts,
}) => {
  return (
    <div
      className={`flex-grow w-5/12  rounded-2xl bg-gradient-to-r m-4 bg-white text-black p-4`}
    >
      <div className="flex text-left">
        <div className="w-4/12 flex justify-between">
          <div className="flex justify-between">
            <div className="pr-2">
              <img src={imageURL} alt="" className="w-16 h-16" />
              <div>
                <p className="font-medium text-[#727597] text-sm mt-8">
                  {textFirstLine}{" "}
                </p>

                <p className="font-semibold text-[#1E1E1E] text-4xl mt-4">
                  {clientData}
                </p>
              </div>
            </div>
          </div>
          <div>
            <img src={lineImageURL} alt="" />
          </div>
        </div>
        <div className="text-left  w-8/12 pl-6">
          <div className="p-2 br-border flex justify-between">
            <div className="w-6/12">
              <p className="font-medium text-[#727597] text-sm">
                {textSecondLine}{" "}
              </p>

              <p className="font-semibold text-[#1E1E1E] text-4xl mt-3">
                {iotConnectedNumber}
              </p>
            </div>
            <div>
              <img src={greylineImageURL} alt=""  />
            </div>
            <div>
              <div className="mb-1">
                <p className="font-medium text-[#727597] text-xs">
                  {livetext}{" "}
                </p>

                <p className="font-semibold text-[#1E1E1E] text-sm">
                  {iotconnectedlivecounts}
                </p>
              </div>
              <Divider />
              <div className="mt-1">
                <p className="font-medium text-[#727597] text-xs">
                  {notlivetext}{" "}
                </p>

                <p className="font-semibold text-[#1E1E1E] text-sm">
                  {iotconnectednonlivecounts}
                </p>
              </div>
            </div>
          </div>
          <Divider />
          <div className="p-2 br-border flex justify-between">
            <div className="w-6/12">
              <p className="font-medium text-[#727597] text-sm">
                {textThirdLine}{" "}
              </p>

              <p className="font-semibold text-[#1E1E1E] text-4xl mt-3">
                {iotDisconnectedNumber}
              </p>
            </div>
            <div>
              <img src={greylineImageURL} alt="" />
            </div>
            <div>
              <div className="mb-1">
                <p className="font-medium text-[#727597] text-xs">
                  {livetext}{" "}
                </p>

                <p className="font-semibold text-[#1E1E1E] text-sm">
                  {iotdisconnectedlivecounts}
                </p>
              </div>
              <Divider />
              <div className="mt-1">
                <p className="font-medium text-[#727597] text-xs">
                  {notlivetext}{" "}
                </p>

                <p className="font-semibold text-[#1E1E1E] text-sm">
                  {iotdisconnectednonlivecounts}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardFrontCard;

import React, { useState, useEffect } from "react";
import GPlace from "./GPlaceForm";
import { Place } from "../../../../@Types/ComponentsInterface/CreateHubProps";
// API key of the google map
const GOOGLE_MAP_API_KEY = process.env.GOOGLE_MAPS_API_KEY;
type LoadGoogleMapCallback = () => void;

// load google map script
const loadGoogleMapScript = (callback: LoadGoogleMapCallback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};
interface MapAutoCompleteProps {
  onPlaceSelect: (place: Place | null) => void;
  value?: Place;
}
const MapAutoComplete: React.FC<MapAutoCompleteProps> = ({
  onPlaceSelect,
  value,
}) => {
  const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  return (
    <div className="auto-complete">
      {!loadMap ? (
        <div>Loading...</div>
      ) : (
        <GPlace value={value} onPlaceSelect={onPlaceSelect} />
      )}
    </div>
  );
};

export default MapAutoComplete;

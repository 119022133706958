import React from "react";
import WaveLoader from "../../WaveLoader/wave";
import Loader from "@mui/material/CircularProgress";
import { DashboardFrontCardProps } from "../../../@Types/ComponentsInterface/DashboardFrontIotTrackerCardProps";

const DashboardFrontCard: React.FC<DashboardFrontCardProps> = ({
  textFirstLine,
  imageURL,
  lineImageURL,
  children,
  clientData,
}) => {
  return (
    <div className={`flex-grow w-24  rounded-2xl bg-gradient-to-r m-4 bg-white text-black p-4`}>
      <div className="flex text-left">
        <div className="pr-2">
          <img src={imageURL} alt="" className="w-16 h-16" />
        </div>
        <div>
          <img src={lineImageURL} alt="" className="h-16" />
        </div>
        <div className="pl-2">
          <p className="font-medium text-[#727597] text-sm">{textFirstLine} </p>

          <p className="font-semibold text-[#1E1E1E] text-4xl mt-2">
            {clientData}
          </p>
        </div>
      </div>
    </div>
  );
};
export default DashboardFrontCard;

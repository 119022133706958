import React, { useEffect, useState, useRef } from "react";
import { DistanceReport } from "../../../API/HyperTrack/DistanceReport";
import RangeDatePicker from "../../UI/AppDatePicker/AppDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Alert,
  Button,
  FormControl,
  LinearProgress,
  Link,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DashboardFrontCardHyperTrack from "../DashboardFrontCardHyperTrack/DashboardFrontCard";
import { CSVLink } from "react-csv";

const DistanceReportYearWiseList = () => {
  const csvHeaders = [
    { label: "S.No", key: "index" },
    { label: "Fleet Name", key: "fleet_name" },
    { label: "Month", key: "Month" },
    { label: "Fleet Count", key: "fleet_count" },
    { label: "Carbon Saved (KG)", key: "carbon_saved" },
    { label: "IOT Distance (KM)", key: "iot_distance" },
  ];
  const tableHeaderCellStyle = {
    fontWeight: 600,
    fontSize: "14px",
    color: "#455A64",
    font: "Rubik",
  };
  const tableBodyCellStyle = {
    fontWeight: 400,
    fontSize: "14px",
    color: "#A4A2A2",
    font: "Rubik",
  };

  const [distanceReportYearWiseData, setDistanceReportYearWiseData] =
    useState<any>();
  const [year, setYear] = useState<any>(2023);
  const [month, setMonth] = useState<string>();
  const [totalCount, setTotalCount] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [summary, setSummary] = useState<any>();
  const pageRange = 5;

  useEffect(() => {
    setPage(0);
    DistanceReport.api.distanceReportYearWiseList(year, month).then((e) => {
      setDistanceReportYearWiseData(e.data);
      setSummary(e.summary);
      if (e.summary && typeof e.summary.total_record !== "undefined") {
        setTotalCount(e.summary.total_record);
      }
    });
  }, [year, month]);
  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(1, Math.min(newPage + 1, pageCount));
    setPage(newPageNumber - 1);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index + 1;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber - 1)}
          className={page === pageNumber - 1 ? "active" : "unactive"}
          style={{ marginLeft: "6px" }}
        >
          {pageNumber}
        </span>
      );
    });
  };
  function roundToDecimalPlaces(number: number) {
    const multiplier = 10 ** 2;
    return Math.round(number * multiplier) / multiplier;
  }
  return (
    <div className="flex flex-wrap animate__animated animate__fadeInLeft">
      <div className=" w-full flex justify-between py-4 pr-6 pl-16 items-center mt-5">
        <div className="font-semibold font-rubik w-1/4">
          <Typography variant="h6" sx={{ textAlign: "left", fontWeight: 400 }}>
            Distance Report YearWise
          </Typography>
        </div>
        <div className="w-2/4">
          <div className="flex items-center justify-end mr-12">
            <div className="flex items-center mr-8">
              <div>
                <Typography sx={{ mr: 1 }}>Year:</Typography>
              </div>
              <FormControl fullWidth size="small">
                <div>
                  <Select
                    id="demo-simple-select"
                    value={year || ""}
                    defaultValue={2023}
                    onChange={(year) => {
                      setYear(year.target.value);
                    }}
                    sx={{width:100}}
                  >
                    <MenuItem value="">
                    <em>Clear</em>
                  </MenuItem>
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </div>
            <div className="flex items-center">
              <div>
                <Typography sx={{ mr: 1 }}>Month:</Typography>
              </div>
              <FormControl fullWidth size="small">
                <Select
                  id="demo-simple-select"
                  value={month}
                  defaultValue={""}
                  placeholder=""
                  onChange={(month) => {
                    setMonth(month.target.value);
                  }}
                  sx={{width:200}}
                >
                  <MenuItem value="">
                    <em>Clear</em>
                  </MenuItem>
                  <MenuItem value="January">January</MenuItem>
                  <MenuItem value="February">February</MenuItem>
                  <MenuItem value="March">March</MenuItem>
                  <MenuItem value="April">April</MenuItem>
                  <MenuItem value="May">May</MenuItem>
                  <MenuItem value="June">June</MenuItem>
                  <MenuItem value="July">July</MenuItem>
                  <MenuItem value="August">August</MenuItem>
                  <MenuItem value="September">September</MenuItem>
                  <MenuItem value="October">October</MenuItem>
                  <MenuItem value="November">November</MenuItem>
                  <MenuItem value="December">December</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <CSVLink
                data={distanceReportYearWiseData || []}
                headers={csvHeaders}
                filename={`distance_report_year_wise_${year}_${month}.csv`}
              >
                <Button
                  style={{
                    backgroundColor: "#00CD5B",
                    padding: "8px 8px",
                    color: "#fff",
                    borderRadius: "5px",
                    width: 200,
                    textTransform: "none",
                    marginLeft: "15px",
                  }}
                >
                  Download CSV
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
      </div>
      {summary ? (
        <div className="flex w-full text-left my-8 px-16">
          <DashboardFrontCardHyperTrack
            fromColorClass="from-[#0CAF55]"
            toColorClass="to-[#92E3A9]"
            textFirstLine="Total Fleets"
            totalcount={roundToDecimalPlaces(summary?.total_fleet)}
            avgcount={0}
            avgtext=""
            shorttext=""
            imageURL="/assets/hyper-track-icon/delivery-boy.svg"
          />

          <DashboardFrontCardHyperTrack
            fromColorClass="from-[#0CAF55]"
            toColorClass="to-[#92E3A9]"
            textFirstLine="Total Distance"
            totalcount={roundToDecimalPlaces(summary?.total_distance)}
            avgcount={0}
            shorttext="km"
            avgtext="Avg Distance"
            imageURL="/assets/hyper-track-icon/delivery-bike.svg"
          />
          <DashboardFrontCardHyperTrack
            fromColorClass="from-[#0CAF55]"
            toColorClass="to-[#92E3A9]"
            textFirstLine="CO2 Saved"
            shorttext="Kg"
            avgtext="Avg Carbon Saved"
            totalcount={roundToDecimalPlaces(summary?.total_carbon_saved)}
            avgcount={0}
            imageURL="/assets/hyper-track-icon/world.svg"
          />
        </div>
      ) : (
        <p>Summary data loading.</p>
      )}
      <>
        {distanceReportYearWiseData ? (
          <TableContainer className="px-16">
            <Table className="px-8">
              <TableHead>
                <TableRow sx={{ display: "flex" }}>
                  <TableCell style={tableHeaderCellStyle} sx={{ flex: 0.5 }}>
                    S.No
                  </TableCell>
                  <TableCell style={tableHeaderCellStyle} sx={{ flex: 1.5 }}>
                    Fleet Name
                  </TableCell>
                  <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                    Month
                  </TableCell>
                  <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                    Fleet Count
                  </TableCell>
                  <TableCell style={tableHeaderCellStyle} sx={{ flex: 1.5 }}>
                    Carbon Saved (KG)
                  </TableCell>
                  <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                    IOT Distance (KM)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {distanceReportYearWiseData
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((row: any) => (
                    <TableRow sx={{ display: "flex" }} key={row.index}>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 0.5 }}>
                        {row.index}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1.5 }}>
                        {row.fleet_name}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        {row.Month}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        {row.fleet_count}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1.5 }}>
                        {row.carbon_saved}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        {row.iot_distance}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="w-full">
            <LinearProgress
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
              }}
            />
          </div>
        )}
        <div className="pl-6 pt-4 pb-2 pr-16">
          <div
            className="pagination"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 0}
                style={{
                  fontFamily: "Rubik",
                  fontSize: "20px",
                  marginRight: "6px",
                }}
              >
                {"<"}
              </button>
              {renderPageNumbers()}
              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page >= pageCount - 1}
                style={{
                  fontFamily: "Rubik",
                  fontSize: "20px",
                  marginLeft: "6px",
                }}
              >
                {">"}
              </button>
            </div>
          </div>
        </div>

        {/* <Snackbar
              open={showSnackbar}
              autoHideDuration={6000}
            >
              <Alert
                sx={{ width: "100%" }}
                severity="error"
              >
                No data found
              </Alert>
            </Snackbar> */}
      </>
    </div>
  );
};

export default DistanceReportYearWiseList;

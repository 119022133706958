import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import LinearProgress from "@mui/material/LinearProgress";

interface SeriesData {
  data: { x: string; y: number }[];
}
interface MapProps {
  selectedCityValue: string | null;
  selectedClientValue: string | null;
  selectedVehicleTypeValue: string | null;
  selectedDisconnectionValue: string | null;
  selectedOptions: string[] | null;
}

const ApexChart: React.FC<MapProps> = ({
  selectedCityValue,
  selectedClientValue,
  selectedVehicleTypeValue,
  selectedDisconnectionValue,
  selectedOptions
}) => {
  const [series, setSeries] = useState<SeriesData[]>([]);
  const [clientmappingloader, setClientmappingloader] = useState(true);

  const [options] = useState({
    legend: {
      show: false
    },
    chart: {
      height: 350,
      type: 'treemap' // Correct type for treemap
    },
    colors: [
      '#3B93A5', '#F7B844', '#ADD8C7', '#EC3C65', '#CDD7B6',
      '#C1F666', '#D43F97', '#1E5D8C', '#421243', '#7F94B0',
      '#EF6537', '#C0ADDB'
    ],
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false
      }
    }
  });

  useEffect(() => {
    setClientmappingloader(true);
    const fetchData = async () => {
      try {
        const daysParams = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => `days_status=${option}`).join('&') : '';

        const apiUrl = 'https://data.bcykal.com/tracker/mobile/client_wise/counts';

        const response = await axios.get(apiUrl + '?' + daysParams, {
          params: {
            city: selectedCityValue,
            merchant: selectedClientValue,
            vehicle_type: selectedVehicleTypeValue,
            connection_status: selectedDisconnectionValue,
          },
          headers: {
            // Add headers if necessary
          }
        });

        console.log('treemapdata', response.data);
        const seriesData = response.data.data.client_wise_bifurcations.map((item: { merchant_name: string; counts: number }) => ({ x: item.merchant_name, y: item.counts }));
        setSeries([{ data: seriesData }]);
        console.log('seriesData', series);
        setClientmappingloader(false)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [
    selectedCityValue,
    selectedClientValue,
    selectedVehicleTypeValue,
    selectedDisconnectionValue,
    selectedOptions
  ]);

  return (
    <div>
      {clientmappingloader? (
              <div className="w-full">
                <LinearProgress
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
                </div>
            ):(
              <div>
      <div id="chart">
        <Chart options={options} series={series} type="treemap" height={350} />
      </div>
      <div id="html-dist"></div>
      </div>
            )}
    </div>
  );
};

export default ApexChart;

import React, { useState, useEffect } from "react";
import * as moment from "moment";
import GoogleLive from "./googleMapLive";
import {
  Avatar,
  Grid,
  TextField,
  Typography,
  LinearProgress,
  Snackbar,
} from "@mui/material";
import { useLocation } from "react-router";
import ChatDrawer from "./ChatDrawer";

const key = process.env.REACT_APP_TRACKER_KEY;

export default function App() {
  const location = useLocation();
  const [trackDetails, setTrackDetails] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const calculateRelativeDate = (days) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - days);
    return currentDate;
  };

  const minDate = calculateRelativeDate(2);
  const maxDate = calculateRelativeDate(16);
  let selectDatebar = moment(location.state.date).format("DD-MM-YYYY");
  let selectdatebarcalender = moment(selectedDate).format("DD-MM-YYYY");
  let formattedDate = selectdatebarcalender;

  if (!moment(formattedDate, "DD-MM-YYYY", true).isValid()) {
    formattedDate = selectDatebar;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://data.bcykal.com/tracker/user_data/${location.state.userid}/${formattedDate}`,
          {}
        );
        const data = await response.json();

        if (data.success && data.user_data.length > 0) {
          setTrackDetails(data.user_data);
        } else {
          setSnackbarMessage("No data found");
          setSnackbarOpen(true);
          setTrackDetails([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setSnackbarMessage("Error fetching data");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectdatebarcalender, selectDatebar]);

  return (
    <Grid container sx={{ display: "flex", px: 6 }}>
      <Grid container>
        <Grid item md={12} sx={{ mr: 2 }}>
          <Grid
            item
            md={12}
            sx={{ display: "flex", mt: 6, alignItems: "center" }}
          >
            <Grid
              item
              sm={6}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Typography variant="h5" sx={{ mb: 2 }}>
                <b> 3 Wheeler Rider Details</b>
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <TextField
                name="fdate"
                id="fdate"
                label="Select Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: { height: "40px" },
                }}
                required
                sx={{ mr: 2, width: 300, height: 30, mb: 3 }}
                onChange={(date) => {
                  setSelectedDate(date.target.value);
                }}
              />
              <Typography variant="h5" sx={{ mb: 2, mr: 2 }}>
                {selectedDate && selectedDate ? (
                  <b> Date: {selectdatebarcalender} </b>
                ) : (
                  <b> Date: {selectDatebar} </b>
                )}
              </Typography>
            </Grid>
          </Grid>
          {loading && (
            <LinearProgress
              className="pl-6 pr-6"
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
                width: "100%",
              }}
            />
          )}

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
          />
          {trackDetails?.map((item, index) => (
            <Grid
              key={index}
              container
              sx={{ mb: 5, border: 1, borderColor: "#CCCCCC", py: 2 }}
            >
              <Grid item sm={12} lg={12} sx={{ display: "flex", ml: 2 }}>
                <Grid
                  item
                  sm={2}
                  lg={2}
                  sx={{ borderRight: 1, borderColor: "#CCCCCC" }}
                >
                  <Grid container justifyContent="center" alignItems="center">
                    <Avatar
                      sx={{
                        width: 50,
                        height: 50,
                        textTransform: "capitalize",
                      }}
                    >
                      {item.Rider_Name[0]}{" "}
                    </Avatar>
                  </Grid>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    {item.Rider_Name}
                  </Typography>
                </Grid>
                <Grid item sm={12} lg={12} sx={{ ml: 2 }}>
                  <Grid
                    item
                    sm={12}
                    lg={12}
                    sx={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Grid item sm={2}>
                      <Typography variant="subtitle1">Start Time</Typography>
                      <Typography variant="p">
                        {moment(item.Start_Time).format("hh:mm:ss")}
                      </Typography>
                    </Grid>

                    <Grid item sm={2}>
                      <Typography variant="subtitle1">Carbon Saved</Typography>
                      <Typography variant="p">{item.carbon_saved}</Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography variant="subtitle1">Login hours</Typography>
                      <Typography variant="p">{item.Login_Hours}</Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography variant="subtitle1">Total Orders</Typography>
                      <Typography variant="p">{item.Total_Orders}</Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography variant="subtitle1">IOT Distance</Typography>
                      <Typography variant="p">
                        {item.iot_distance}
                        {item.iot_distance && " km"}
                      </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography variant="subtitle1">Average Speed</Typography>
                      <Typography variant="p">{item.Average_Speed}</Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography variant="subtitle1">
                        Disconnection Duration
                      </Typography>
                      <Typography variant="p">
                        {item.iot_disconnection_duration}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      mt: 2,
                    }}
                  >
                    <Grid item sm={2}>
                      <Typography variant="subtitle1">
                        Time per Order
                      </Typography>
                      <Typography variant="p">{item.Time_per_order}</Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography variant="subtitle1">Fleet Name</Typography>
                      <Typography variant="p">{item.fleet_name}</Typography>
                    </Grid>

                    <Grid item sm={2}>
                      <Typography variant="subtitle1">
                        Drive Duration
                      </Typography>
                      <Typography variant="p">{item.Drive_Duration}</Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography variant="subtitle1">Stop Duration</Typography>
                      <Typography variant="p">{item.Stop_Duration}</Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography variant="subtitle1">IOT Type</Typography>
                      <Typography variant="p">{item.IOT_Type}</Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography variant="subtitle1">Client</Typography>
                      <Typography variant="p">{item.Client}</Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography variant="subtitle1">QrCode</Typography>
                      <Typography variant="p">{item.QrCode}</Typography>
                    </Grid>
                  </Grid>
                  {/* <Grid item sm={2}>
                    <Typography variant="subtitle1">Test Distance</Typography>
                    <Typography variant="p">
                      {item.client_distance}
                      {item.client_distance && ' km'}
                    </Typography>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid
            item
            sx={{ position: "relative", display: "flex", width: "100%" }}
          >
            
            <Grid item >
              <GoogleLive
                selectMarker={selectedMarker}
                cycleid={location.state.cycleid}
                mapuserid={location.state.userid}
                mapdate={location.state.date}
                selectedDate={selectedDate}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

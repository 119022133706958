import React, { useEffect } from "react";
import {
  GoogleMap,
  HeatmapLayer,
  useJsApiLoader,
} from "@react-google-maps/api";
import { ClientApi } from "../../../API/ClientApi";
import LinearProgress from "@mui/material/LinearProgress";
import { useLocation } from "react-router-dom";

function AppHeatMapComponent() {
  const location = useLocation();
  const isNewFleetDemandRoute = location.pathname.includes(
    "/NewFleetDemandForm"
  );

  const containerStyle = {
    width: "100%",
    height: isNewFleetDemandRoute ? "44rem" : "16.5rem",
  };

  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [heatmapData, setHeatmapData] = React.useState<google.maps.LatLng[]>(
    []
  );

  useEffect(() => {
    ClientApi.api.googleMapsOnDutyRiders().then((data) => {
      setHeatmapData(
        data
          .filter((e) => e.latitude && e.longitude)
          .map((e) => ({
            lat: parseFloat(e.latitude.toString()),
            lng: parseFloat(e.longitude.toString()),
          }))
          .map((e) => new window.google.maps.LatLng(e.lat, e.lng))
      );
    });
  }, []);

  useEffect(() => {
    if (map && heatmapData.length > 0) {
      const centerLatLng = heatmapData[0];
      const center = {
        lat: centerLatLng.lat(),
        lng: centerLatLng.lng(),
      };
      map.setCenter(center);
    }
  }, [map, heatmapData]);

  const onLoad = (map: google.maps.Map) => {
    setMap(map);
  };

  const onUnmount = () => {
    setMap(null);
  };

  return (
    <>
      {heatmapData && heatmapData.length > 0 ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={8}
          options={{
            fullscreenControl: true,
            zoomControl: true,
          }}
          center={
            heatmapData.length > 0
              ? { lat: heatmapData[0].lat(), lng: heatmapData[0].lng() }
              : undefined
          }
          onLoad={onLoad}
          onUnmount={onUnmount}
          onZoomChanged={() => {
            // console.log(map?.getZoom());
          }}
        >
          <HeatmapLayer
            data={heatmapData}
            options={{
              radius: 10,
              opacity: 0.6,
              map,
              //   gradient: ["#00ff00", "#ffff00", "#ff0000"],
            }}
          />
        </GoogleMap>
      ) : (
        <div className="w-full">
          <LinearProgress
            sx={{
              backgroundColor: "white",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#00CD5B",
              },
            }}
          />
        </div>
      )}
    </>
  );
}

export const AppHeatMap = React.memo(AppHeatMapComponent);

import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
  //Polygon
} from "@react-google-maps/api";
import moment from "moment";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import "./style.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Path } from "./googleMapLive";
import { Link } from "@mui/material";
//import axios from 'axios';

function Map({
  paths,
  stops,
  homeLat,
  homeLng,
  workLat,
  workLng,
  currentlySelectedMarkerIndex,
}: {
  paths: Path[];
  stops: Path[];
  workLat: number;
  workLng: number;
  homeLat: number;
  homeLng: number;
  currentlySelectedMarkerIndex?: Path;
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [positionIndex, setPositionIndex] = useState(0);
  const [selectedMarker, setSelectedMarker] = useState<number>(-1);
  const [selectedMarkerinfo, setSelectedMarkerinfo] = useState<number | null>(null);
  const [selectedMarkerinfowork, setSelectedMarkerinfowork] = useState<number | null>(null);


  const [selectedMarkermapStops, setSelectedMarkermapStops] = useState<number>(-1);

  const [selectedMarkermapPaths, setSelectedMarkermapPaths] = useState<number>(-1);

  const [loadingWorkAddress, setLoadingWorkAddress] = useState(true);
  const [loadingHomeAddress, setLoadingHomeAddress] = useState(true);
  const [homeAddress, setHomeAddress] = useState<string | null>(null);
  const [workAddress, setWorkAddress] = useState<string | null>(null);
  const googleMapApiKey = "AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg";
  let mapPaths: Path[] = paths;

  const mapStops: Path[] = stops;


  let interval: NodeJS.Timer | undefined;
  const icon1 = {
    url: "https://zypp.app/mailer/Map-icon.png",
    scaledSize: new window.google.maps.Size(35, 35),
    anchor: new window.google.maps.Point(20, 20),
    scale: 0,
  };

  const mid = Math.floor(mapPaths.length / 2);
  const centerPathLat = mapPaths[mid]?.latitude;
  const centerpathLng = mapPaths[mid].longitude;
  const startLat = mapPaths[0].latitude;
  const startLng = mapPaths[0].longitude;
  const end = mapPaths.length - 1;
  const endPathLat = mapPaths[end].latitude;
  const endpathLng = mapPaths[end].longitude;

  const convertPathToLatLngLiteral = (
    path: Path[]
  ): google.maps.LatLngLiteral[] => {
    return path.map((point) => ({
      lat: point.latitude,
      lng: point.longitude,
      distance: point.Distance,
    }));
  };
  const [mapPath, setMapPath] = useState<google.maps.LatLngLiteral[]>([]);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (
      currentlySelectedMarkerIndex?.latitude !== mapStops[selectedMarkermapStops]?.latitude ||
      currentlySelectedMarkerIndex?.longitude !== mapStops[selectedMarkermapStops]?.longitude
    ) {
      // Find the index in the mapStops array where the coordinates match currentlySelectedMarkerIndex
      const newIndex = mapStops.findIndex(
        (e) =>
          currentlySelectedMarkerIndex?.latitude === e.latitude &&
          currentlySelectedMarkerIndex?.longitude === e.longitude
      );
  
      // Update the selectedMarker state with the new index
      setSelectedMarkermapStops(newIndex);

    }
  }, [currentlySelectedMarkerIndex, mapStops, selectedMarkermapStops]);
  useEffect(() => {
    if (
      currentlySelectedMarkerIndex?.latitude !== mapPaths[selectedMarkermapPaths]?.latitude ||
      currentlySelectedMarkerIndex?.longitude !== mapPaths[selectedMarkermapPaths]?.longitude
    ) {
      // Find the index in the mapStops array where the coordinates match currentlySelectedMarkerIndex
      const newIndex = mapPaths.findIndex(
        (e) =>
          currentlySelectedMarkerIndex?.latitude === e.latitude &&
          currentlySelectedMarkerIndex?.longitude === e.longitude
      );
  
      // Update the selectedMarker state with the new index
       setSelectedMarkermapPaths(newIndex);

      }
  }, [currentlySelectedMarkerIndex, mapStops, selectedMarkermapPaths]);
  
  useEffect(() => {
    const pathAsLatLngLiteral = convertPathToLatLngLiteral(paths);
    setMapPath(pathAsLatLngLiteral);
  }, [paths]);
  async function getLocality(
    apiKey: string,
    latitude: number | undefined,
    longitude: number | undefined
  ): Promise<string | null> {

    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch address. Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const result = data.results[0];
        // Extract the name of the first locality and sublocality if available
        const localityComponent = result.address_components.find(
          (component: any) => component.types.includes("sublocality_level_1")
        );
        const cityComponent = result.address_components.find((component: any) =>
          component.types.includes("locality")
        );
        const postalCodeComponent = result.address_components.find(
          (component: any) => component.types.includes("postal_code")
        );

        // Return the combined result of city, sublocality, and postal code if found
        if (localityComponent && cityComponent && postalCodeComponent) {
          const locality = localityComponent.long_name;
          const city = cityComponent.long_name;
          const postalCode = postalCodeComponent.long_name;
          return `${locality}, ${city}, ${postalCode}`;
        } else {
          // If any of the components is missing, return the formatted address
          return result.formatted_address;
        }
      } else {
        throw new Error(`No results found for the provided coordinates.`);
      }
    } catch (error: any) {
      console.error(`Error fetching locality: ${error.message}`);
      return null;
    }
  }
  const fetchData = async () => {
    try {
      const fetchedWorkAddress = await getLocality(
        googleMapApiKey,
        workLat,
        workLng
      );
      const fetchedHomeAddress = await getLocality(
        googleMapApiKey,
        homeLat,
        homeLng
      );

      setWorkAddress(fetchedWorkAddress);
      setLoadingWorkAddress(false);

      setHomeAddress(fetchedHomeAddress);
      setLoadingHomeAddress(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingWorkAddress(false);
      setLoadingHomeAddress(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [workLat]);

  useEffect(() => {
    let intervalId: NodeJS.Timer | undefined;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setPositionIndex((prevIndex) => {
          if (prevIndex === mapPath.length - 1) {
            setIsPlaying(false); // Stop playing if reached the end
            clearInterval(intervalId);
            return prevIndex;
          }
          return prevIndex + 1;
        });
      }, 1000); // Adjust the interval time as needed
    }

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [isPlaying, mapPath.length]);

  let iconMarkerStart = "https://zypp.app/clientdashboard-mailer/startpath.png";
  let iconMarkerEnd = "https://zypp.app/clientdashboard-mailer/endpath.png";

  const polylineOptionsBlue = {
    strokeColor: "#0826e5",
    strokeWeight: 2,
    strokeOpacity: 0.6,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 99999,
    icons: [
      {
        icon: {
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        },
        repeat: "40px",
        offset: "100%",
      },
    ],
  };
  const polylineOptions = {
    strokeColor: "#ffa500",
    strokeOpacity: 1,
    strokeWeight: 3,
    icons: [
      {
        icon: {
          path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
          scale: 3,
          fillColor: "#ffa500",
          fillOpacity: 1,
          strokeColor: "#ffa500",
        },
        offset: "100%",
      },
    ],
  };

  // const tooglePlay = () => {
  //   setIsPlaying(!isPlaying);
  // };

  const starttimeFormat = (date: string) => {
    // let fdate = "";
    // if (date) {
    //   fdate = date.split(" ");
    //   if (fdate.length) {
    //     fdate = fdate[fdate.length - 1];
    //   }
    // }
    // return fdate;
    return date?.split(" ")[-1] || "";
  };
  // const handleMarkerFocus = () => {
  //   if (mapStops.length <= 2) {
  //     // If there are only two markers, toggle between them
  //     setSelectedMarker(selectedMarker === 0 ? 1 : 0);
  //   } else {
  //     // If there are more than two markers, cycle through them
  //     if (selectedMarker === null || selectedMarker === mapStops.length - 1) {
  //       setSelectedMarker(0); // Wrap to the first marker if at the end
  //     } else {
  //       setSelectedMarker(selectedMarker + 1);
  //     }
  //   }
  // };

  const handleNextMarker = () => {
    if (
      (selectedMarker === null || selectedMarker === mapStops.length - 1 )
      && 
      (selectedMarkermapStops === null || selectedMarkermapStops === mapStops.length - 1)
      ) {
      setSelectedMarker(0); // Wrap to the first marker if at the end
      setSelectedMarkermapStops(0); // Wrap to the first marker if at the end
    } else {
      setSelectedMarker(selectedMarker + 1);
      setSelectedMarkermapStops(selectedMarker + 1);
    }
  };
  const getCustomMarkerIcon = () => {
    const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="62" height="42" viewBox="0 0 62 42">
    <g id="scooter-delivery" transform="translate(-1 -11)">
      <path id="Path_14359" data-name="Path 14359" d="M6.98,33.98a1.024,1.024,0,0,1-.71-.29L3.29,30.71A.99.99,0,0,1,3,30V26a1,1,0,0,1,2,0v3.59l2.69,2.69a1,1,0,0,1,0,1.41A.982.982,0,0,1,6.98,33.98Z" fill="#575f6b"/>
      <path id="Path_14360" data-name="Path 14360" d="M43,17h3v4H43a2.006,2.006,0,0,1-2-2h0A2.006,2.006,0,0,1,43,17Z" fill="#00cd5b"/>
      <path id="Path_14361" data-name="Path 14361" d="M60.32,43A6.844,6.844,0,0,1,61,46a7,7,0,0,1-14,0,6.844,6.844,0,0,1,.68-3Z" fill="#504254"/>
      <circle id="Ellipse_266" data-name="Ellipse 266" cx="3" cy="3" r="3" transform="translate(51 43)" fill="#fcddad"/>
      <path id="Path_14362" data-name="Path 14362" d="M6.29,48a7,7,0,0,0,13.42,0Z" fill="#504254"/>
      <path id="Path_14363" data-name="Path 14363" d="M63,43H46.8l4.21-4.39A2.013,2.013,0,0,1,52.45,38h1.74a10.15,10.15,0,0,1,2.81.4,10.015,10.015,0,0,1,5,3.35ZM14,26H28a2.006,2.006,0,0,1,2,2v3H12V28A2.006,2.006,0,0,1,14,26Z" fill="#00cd5b"/>
      <path id="Path_14364" data-name="Path 14364" d="M30,29v2H12V28a2.006,2.006,0,0,1,2-2h1v1a2.006,2.006,0,0,0,2,2Z" fill="#00893d"/>
      <path id="Path_14365" data-name="Path 14365" d="M57,38v.4a10.15,10.15,0,0,0-2.81-.4H52.45a2.013,2.013,0,0,0-1.44.61L46.8,43l-3.91,4.08a3.01,3.01,0,0,1-2.17.92H1V43.51a12.043,12.043,0,0,1,.37-2.98,12.257,12.257,0,0,1,8.02-8.66L12,31H30a10.233,10.233,0,0,0-3,7.24V44H40l5.66-5.66A7.985,7.985,0,0,0,48,32.69V22h4.11l.64,7.86a10.173,10.173,0,0,0,2.22,5.6L57,38Z" fill="#fc4847"/>
      <path id="Path_14366" data-name="Path 14366" d="M57,38v.4a10.15,10.15,0,0,0-2.81-.4H52.45a2.013,2.013,0,0,0-1.44.61L46.8,43l-3.91,4.08a3.01,3.01,0,0,1-2.17.92H1V43.51a12.043,12.043,0,0,1,.37-2.98,12.257,12.257,0,0,1,8.02-8.66L12,31H30a10.233,10.233,0,0,0-3,7.24V44H40l5.66-5.66A7.985,7.985,0,0,0,48,32.69V22h4.11l.64,7.86a10.173,10.173,0,0,0,2.22,5.6L57,38Z" fill="#00cd5b"/>
      <path id="Path_14367" data-name="Path 14367" d="M57,38v.4a10.15,10.15,0,0,0-2.81-.4H52.45a2.013,2.013,0,0,0-1.44.61L46.8,43l-3.91,4.08a3.01,3.01,0,0,1-2.17.92H1V43.51a12.043,12.043,0,0,1,.37-2.98,12.206,12.206,0,0,1,6.67-8.11c-.27.23-.52.49-.77.75a12.2,12.2,0,0,0-2.9,5.36A12.043,12.043,0,0,0,4,41.51V44a2.006,2.006,0,0,0,2,2H40.72a3.01,3.01,0,0,0,2.17-.92L46.8,41l4.21-4.39A2.013,2.013,0,0,1,52.45,36h1.74a9.075,9.075,0,0,1,1.28.09L57,38Z" fill="#00893d"/>
      <path id="Path_14368" data-name="Path 14368" d="M13,38h0a8,8,0,0,1,8,8v2H5V46A8,8,0,0,1,13,38Z" fill="#00893d"/>
      <path id="Path_14369" data-name="Path 14369" d="M52,16v6H48a3,3,0,0,1,0-6h4Z" fill="#00cd5b"/>
      <rect id="Rectangle_1708" data-name="Rectangle 1708" width="4" height="6" transform="translate(52 16)" fill="#fcddad"/>
      <rect id="Rectangle_1709" data-name="Rectangle 1709" width="20" height="15" transform="translate(1 11)" fill="#00cd5b"/>
      <path id="Path_14370" data-name="Path 14370" d="M21,24v2H1V11H4v7a6,6,0,0,0,6,6Z" fill="#00893d"/>
      <rect id="Rectangle_1710" data-name="Rectangle 1710" width="6" height="5" transform="translate(8 11)" fill="#fc4847"/>
      <path id="Path_14371" data-name="Path 14371" d="M14,14v2H8V11h2v1a2.006,2.006,0,0,0,2,2Z" fill="#f43737"/>
      <path id="Path_14372" data-name="Path 14372" d="M45.77,19v2H43a2.006,2.006,0,0,1-2-2,2.03,2.03,0,0,1,1.02-1.74A2.005,2.005,0,0,0,44,19Z" fill="#00893d"/>
      <path id="Path_14373" data-name="Path 14373" d="M57.66,51.96A6.889,6.889,0,0,1,54,53a7,7,0,0,1-7-7,6.844,6.844,0,0,1,.68-3h2.61A6.908,6.908,0,0,0,50,45a7,7,0,0,0,7,7A4.872,4.872,0,0,0,57.66,51.96Z" fill="#3e3342"/>
      <path id="Path_14374" data-name="Path 14374" d="M56.98,46.24A2.995,2.995,0,0,1,51,46a2.929,2.929,0,0,1,1.02-2.24A.974.974,0,0,0,52,44a2.989,2.989,0,0,0,4.98,2.24Z" fill="#fcc489"/>
      <path id="Path_14375" data-name="Path 14375" d="M16.65,51.97A7,7,0,0,1,6.29,48H9.67A7,7,0,0,0,16,52C16.22,52,16.44,51.99,16.65,51.97Z" fill="#3e3342"/>
      <path id="Path_14376" data-name="Path 14376" d="M52,20v2H48a3,3,0,0,1-2.12-5.12,3.058,3.058,0,0,1,1.25-.75A3.1,3.1,0,0,0,47,17a3,3,0,0,0,3,3h2Z" fill="#00893d"/>
      <path id="Path_14377" data-name="Path 14377" d="M56,20v2H52V16h2v2A2.006,2.006,0,0,0,56,20Z" fill="#fcc489"/>
      <path id="Path_14378" data-name="Path 14378" d="M63,43H46.8l4.21-4.39A2.013,2.013,0,0,1,52.45,38h.31a2,2,0,0,0,1.73,3h6.84a9.666,9.666,0,0,1,.67.75L63,43ZM21,46v2H5V46a8,8,0,0,1,5.17-7.48A8,8,0,0,0,8,44a2.006,2.006,0,0,0,2,2Z" fill="#00cd5b"/>
    </g>
  </svg>
    `;
    const svgIcon = `data:image/svg+xml;base64,${btoa(svgContent)}`;
    return {
      url: "https://zypp.app/clientdashboard-mailer/dot-red.png",
      scaledSize: new window.google.maps.Size(8, 8),
    };
  };
  const handlePreviousMarker = () => {
    if (
      (selectedMarker === null || selectedMarker === 0) &&
      (selectedMarkermapStops === null || selectedMarkermapStops === 0)
    ) {
      setSelectedMarker(mapStops.length - 1);
      setSelectedMarkermapStops(mapStops.length - 1); // Wrap to the last marker if at the beginning
    } else {
      setSelectedMarker(selectedMarker - 1);
      setSelectedMarkermapStops(selectedMarker - 1);
    }
  };
  const handleMarkerClick = (index: number) => {
    setSelectedMarker(index);
  };
  const handleAllMarkerClick = (index: number) => {
    setSelectedMarker(index);
  };

  const handleMarkerHover = (e:any) => {
    setSelectedMarkerinfo(e);
};
const handleMarkerHoverWork = (e:any) => {
    setSelectedMarkerinfowork(e);
};

  function StopCircleIcon(stop: Path, index: number) {
    const markerColor = stop.time_diff <= "00:20:00" ? "orange" : "violet";

    let time1 = starttimeFormat(stop.End_time);
    let time2 = starttimeFormat(stop.Start_time);
    let stoptime1 = moment(time2, ["hh:mm:ss"]).format("HH:mm");
    let stoptime2 = moment(time1, ["hh:mm:ss"]).format("HH:mm");

    return (
      <>
        <Marker
          key={stop.cycleId}
          position={{
            lat: stop.latitude,
            lng: stop.longitude,
          }}
          // icon={}
          icon={{
            path: "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z",
            anchor: new window.google.maps.Point(6, 8),
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: "white",
            fillColor: markerColor,
            scale: 2,
            scaledSize: new google.maps.Size(50, 50), // scaled size
            labelOrigin: new window.google.maps.Point(12, 9),
          }}
          title={stop.cycleId.toString()}
          label={`${index + 1}`}
          onClick={() => handleMarkerClick(index)}
        >
          {(selectedMarker === index || selectedMarkermapStops === index) && (
  <InfoWindow>
    <div>
      {/* <p>
        <b>
          {stoptime1}-{stoptime2}
        </b>
      </p> */}
      <p>
        <b>Dur:</b> {stop.time_diff}
      </p>
      <div style={{ marginTop: "10px" }}>
        <button
          style={{
            zIndex: 9999999,
            backgroundColor: "#00CD5B",
            padding: 1,
          }}
          onClick={handlePreviousMarker}
        >
          <KeyboardArrowLeftIcon />
        </button>
        <button
          style={{
            zIndex: 9999999,
            marginLeft: 5,
            backgroundColor: "#00CD5B",
            padding: 1,
          }}
          onClick={handleNextMarker}
        >
          <KeyboardArrowRightIcon />
        </button>
      </div>
    </div>
  </InfoWindow>
)}

        </Marker>
      </>
    );
  }
  function AllCircleIcon(stop: Path, index: number) {
   

    return (
      <>
        <Marker
          key={stop.cycleId}
          position={{
            lat: stop.latitude,
            lng: stop.longitude,
          }}
          // icon={}
          icon={getCustomMarkerIcon()} 
          onClick={() => handleAllMarkerClick(index)}
        >
          {(selectedMarker === index || selectedMarkermapStops === index) && (
  <InfoWindow>
    <div>
      <p>
        <b>Lat:</b> {stop.latitude}
      </p>
      <p>
        <b>Lat:</b> {stop.longitude}
      </p>
     
    </div>
  </InfoWindow>
)}

        </Marker>
      </>
    );
  }
  
  const homeMarker = {
    title: `Home: ${homeAddress || "Unknown Address"}\nLatitude: ${homeLat}\nLongitude: ${homeLng}\nGoogle Maps Link: https://maps.google.com/?q=${homeLat},${homeLng}`,
    icon: "/assets/hyper-track-icon/home-location.png", // Replace with the path to your home marker icon
    position: { lat: homeLat, lng: homeLng },
};

  const workMarker = {
    title: `Work: ${workAddress || "Unknown Address"}`,
    icon: "/assets/hyper-track-icon/work-location.png", // Replace with the path to your work marker icon
    position: { lat: workLat, lng: workLng },
  };
  return (
    <div>
      {/* <div style={{textAlign:'right', display:'flex', justifyContent:'flex-end'}}>
      <Button
            onClick={tooglePlay}
            style={{ backgroundColor: "#00CD5B", marginBottom: 10, color: "#fff" }}
          >
            {isPlaying ? "Pause" : "Play"}
          </Button>
          </div> */}
      <Card variant="outlined">
        <div className="gMapCont">
          <GoogleMap
            onLoad={(mapDetails: google.maps.Map) => {
              setMap(mapDetails); // Map object stored
            }}
            zoom={11}
            center={{ lat: workLat, lng: workLng }}
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
            }}
            // journeydetails={journeydetails}
            mapContainerStyle={{
              width: "100%",
              height: "44rem",
            }}
            
          >
            {!loadingHomeAddress && !loadingWorkAddress && (
              <>
                {/* Add markers for home and work */}
                <Marker
                title={homeMarker.title}
                icon={homeMarker.icon}
                position={homeMarker.position}
                onMouseOver={() => handleMarkerHover(workMarker)} // No semicolon needed here
            />

                  {selectedMarkerinfo !== null && (
            <>
              <InfoWindow
                position={homeMarker.position}
                options={{ pixelOffset: new window.google.maps.Size(0, -50) }}
                onCloseClick={() => setSelectedMarkerinfo(null)}
              >
                <div>
                  <p><b>Home: </b>{homeAddress || "Unknown Address"}</p>
                  <p><b>Latitude:</b> {homeLat} <b>Longitude:</b> {homeLng}</p>
                  <p>
                  <b>Google Maps Link:</b>  <a target="_blank" href={`https://maps.google.com/?q=${homeLat},${homeLng}`}  style={{ cursor: "pointer", color:'blue' }}
                    >
                        https://maps.google.com/?q={homeLat},{homeLng}
                    </a>
                  </p>
                </div>
              </InfoWindow>
              
            </>
          )}
                 
                <Marker
                  title={workMarker.title}
                  icon={workMarker.icon}
                  position={workMarker.position}
                  onMouseOver={() => handleMarkerHoverWork(workMarker)} // No semicolon needed here

                >
                 {selectedMarkerinfowork !== null && (
            <>
              <InfoWindow
                position={workMarker.position}
                options={{ pixelOffset: new window.google.maps.Size(0, -50) }}
                onCloseClick={() => setSelectedMarkerinfowork(null)}
              >
                <div>
                  <p><b>Work: </b>{workAddress || "Unknown Address"}</p>
                  <p><b>Latitude:</b> {workLat} <b>Longitude:</b> {workLng}</p>
                  <p>
                  <b>Google Maps Link:</b>  <a target="_blank" href={`https://maps.google.com/?q=${workLat},${workLng}`}  style={{ cursor: "pointer", color:'blue' }}
                    >
                        https://maps.google.com/?q={workLat},{workLng}
                    </a>
                  </p>
                </div>
              </InfoWindow>
              
            </>
          )}
          </Marker>
              </>
            )}



            <Polyline path={mapPath} options={polylineOptionsBlue} />
            {mapStops && mapStops && (
              <>
                {mapStops.map((stop: any, index: number) =>
                  StopCircleIcon(stop, index)
                )}
              </>
            )}
             {mapPaths && mapPaths && (
              <>
                {mapPaths.map((stop: any, index: number) =>
                  AllCircleIcon(stop, index)
                )}
              </>
            )}

            {mapPath && (
              <>
                <Polyline
                  path={mapPath.slice(0, positionIndex + 1)}
                  options={polylineOptions}
                />

                <Marker icon={icon1} position={{ lat: 0, lng: 0 }} />
              </>
            )}
             
            <Marker
              // label={new window.google.maps.Label("")}
              icon={iconMarkerStart}
              position={{ lat: startLat, lng: startLng }}
            />
            <Marker
              // name={"SOMA"}
              icon={iconMarkerEnd}
              position={{ lat: endPathLat, lng: endpathLng }}
            />
          </GoogleMap>
        </div>
      </Card>
    </div>
  );
}

// const WrappedMap = withScriptjs(withGoogleMap(Map));

export default Map;

import React from "react";
import { Box, Grid } from "@mui/material";
import AuthAdminLogin from "./AdminLogin";

function AdminSignIn() {
  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Grid container sx={{ flex: 1, alignItems:'center' }}>
        <Grid item xs={false} sm={4} md={6}>
          <div className="flex items-center justify-between">
            <img
              src={"/assets/icons/invoice-logo.svg"}
              alt="Logo"
              className="ml-4 mt-4"
            />
          </div>
          <Box
            component="img"
            className="w-full mt-2"
            sx={{
              height: 800,
              maxHeight: { xs: 200, md: 467 },
              maxWidth: { xs: 800, md: 1150 },
              objectFit: "contain",
            }}
            src="/assets/admin-login.svg"
          />
        </Grid>
        <Grid
          item
          sm={8}
          md={6}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sm={8} md={7}>
            <AuthAdminLogin />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminSignIn;

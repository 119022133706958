import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GoogleMap, InfoWindow, MarkerF as Marker } from "@react-google-maps/api";
import moment from "moment";

interface WorkHomeLocationModalProps {
  iot_lat: number | undefined;
  iot_lng: number | undefined;
  mobile_lat: number | undefined;
  mobile_lng: number | undefined;
  qrcode: string | undefined;
  mobile_time: string | undefined;
  iot_time: string | undefined;
  modalOpen: boolean;
  onClose: () => void;
}

const googleMapApiKey = "AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const containerStyle = {
  width: "40vw",
  height: "19.5rem",
};

async function getLocality(
  apiKey: string,
  latitude: number | undefined,
  longitude: number | undefined
): Promise<string | null> {
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      throw new Error(`Failed to fetch address. Status: ${response.status}`);
    }

    const data = await response.json();

    if (data.status === "OK" && data.results.length > 0) {
      const result = data.results[0];
      const localityComponent = result.address_components.find(
        (component: any) => component.types.includes("sublocality_level_1")
      );
      const cityComponent = result.address_components.find((component: any) =>
        component.types.includes("locality")
      );
      const postalCodeComponent = result.address_components.find(
        (component: any) => component.types.includes("postal_code")
      );

      if (localityComponent && cityComponent && postalCodeComponent) {
        const locality = localityComponent.long_name;
        const city = cityComponent.long_name;
        const postalCode = postalCodeComponent.long_name;
        return `${locality}, ${city}, ${postalCode}`;
      } else {
        return result.formatted_address;
      }
    } else {
      throw new Error(`No results found for the provided coordinates.`);
    }
  } catch (error: any) {
    console.error(`Error fetching locality: ${error.message}`);
    return null;
  }
}

const WorkHomeLocationModal: React.FC<WorkHomeLocationModalProps> = ({
  iot_lat,
  iot_lng,
  mobile_lat,
  mobile_lng,
  qrcode,
  modalOpen,
  mobile_time,
  iot_time,
  onClose,
}) => {
  const [iotAddress, setIotAddress] = useState<string | null>(null);
  const [mobileAddress, setMobileAddress] = useState<string | null>(null);
  const [loadingWorkAddress, setLoadingWorkAddress] = useState(true);
  const [loadingHomeAddress, setLoadingHomeAddress] = useState(true);
  const [selectedMarkermobile, setSelectedMarkermobile] = useState<number | null>(null);
  const [selectedMarkeriot, setSelectedMarkeriot] = useState<number | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const fetchedWorkAddress = await getLocality(
          googleMapApiKey,
          iot_lat,
          iot_lng
        );
        const fetchedHomeAddress = await getLocality(
          googleMapApiKey,
          mobile_lat,
          mobile_lng
        );

        if (isMounted) {
          setIotAddress(fetchedWorkAddress);
          setLoadingWorkAddress(false);

          setMobileAddress(fetchedHomeAddress);
          setLoadingHomeAddress(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoadingWorkAddress(false);
        setLoadingHomeAddress(false);
      }
    };

    if (modalOpen) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [modalOpen, iot_lat, iot_lng, mobile_lat, mobile_lng]);

  const determineMarkerIconIot = (timestamp: string | undefined) => {

    const date = moment(timestamp);
    const now = moment();
    const diffDays = now.diff(date, 'days');

    if (diffDays === 0) {
      return "/assets/hyper-track-icon/green-iot.svg";
    } else if (diffDays <= 7) {
      return "/assets/hyper-track-icon/orange-iot.svg";
    } else {
      return "/assets/hyper-track-icon/red-iot.svg";
    }
  };
  const determineMarkerIconMobile = (timestamp: string | undefined) => {

    const date = moment(timestamp);
    const now = moment();
    const diffDays = now.diff(date, 'days');

    if (diffDays === 0) {
      return "/assets/hyper-track-icon/greenmobile.svg";
    } else if (diffDays <= 7) {
      return "/assets/hyper-track-icon/orange-mobile.svg";
    } else {
      return "/assets/hyper-track-icon/redmobile.svg";
    }
  };

  const mobileMarker = {
    icon: determineMarkerIconMobile(mobile_time),
    position: { lat: mobile_lat as number, lng: mobile_lng as number },
  };

  const iotMarker = {
    icon: determineMarkerIconIot(iot_time),
    position: { lat: iot_lat as number, lng: iot_lng as number },
  };

  const handleMobileMarker = (e: any) => {
    setSelectedMarkermobile(e);
  };
  const handleIotMarker = (e: any) => {
    setSelectedMarkeriot(e);
  };

  let MobileDate = moment(mobile_time).format("DD-MM-YYYY");
  let MobileTime = moment(mobile_time).format("HH:mm:ss");

  let IotDate = moment(iot_time).format("DD-MM-YYYY");
  let IotTime = moment(iot_time).format("HH:mm:ss");

  let center = {
    lat: mobile_lat || 28.7136,
    lng: mobile_lng || 77.1294,
  };

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-between  items-center">
            <div className="flex">
              <p>
                <b>Rider EB Number: {qrcode}</b>
              </p>
            </div>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Grid className="flex items-center mb-2">
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#00cb60",
                  borderRadius: 50,
                }}
              >
                &nbsp;
              </Typography>
              <Typography sx={{ ml: 1 }}>Today</Typography>
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center", ml: 4 }}>
              <Typography
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#ff7d14",
                  borderRadius: 50,
                }}
              >
                &nbsp;
              </Typography>
              <Typography sx={{ ml: 1 }}>1-7 Days</Typography>
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center", ml: 4 }}>
              <Typography
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#f94932",
                  borderRadius: 50,
                }}
              >
                &nbsp;
              </Typography>
              <Typography sx={{ ml: 1 }}>More than 7 days</Typography>
            </Grid>
          </Grid>
          <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={7}>
            {!loadingHomeAddress && !loadingWorkAddress && (
              <>
                {iot_lat !== undefined && iot_lng !== undefined && (
                  <Marker
                    icon={iotMarker.icon}
                    position={iotMarker.position}
                    onMouseOver={() => handleIotMarker(iotMarker)}
                  />
                )}
                {selectedMarkeriot !== null && (
                  <InfoWindow
                    position={iotMarker.position}
                    options={{ pixelOffset: new window.google.maps.Size(0, -50) }}
                    onCloseClick={() => setSelectedMarkeriot(null)}
                  >
                    <div>
                      <p>
                        <b>{IotDate} {IotTime}</b>
                      </p>
                      <p><b>IOT Location: </b>{iotAddress || "Unknown Address"}</p>
                      <p><b>Latitude:</b> {iot_lat} <b>Longitude:</b> {iot_lng}</p>
                      <p>
                        <b>Google Maps Link:</b> <a target="_blank" href={`https://maps.google.com/?q=${iot_lat},${iot_lng}`} style={{ cursor: "pointer", color: 'blue' }}>
                          https://maps.google.com/?q={iot_lat},{iot_lng}
                        </a>
                      </p>
                    </div>
                  </InfoWindow>
                )}
                {mobile_lat !== undefined && mobile_lng !== undefined && (
                  <Marker
                    icon={mobileMarker.icon}
                    position={mobileMarker.position}
                    onMouseOver={() => handleMobileMarker(mobileMarker)}
                  />
                )}
                {selectedMarkermobile !== null && (
                  <InfoWindow
                    position={mobileMarker.position}
                    options={{ pixelOffset: new window.google.maps.Size(0, -50) }}
                    onCloseClick={() => setSelectedMarkermobile(null)}
                  >
                    <div>
                      <p>
                        <b>{MobileDate} {MobileTime}</b>
                      </p>
                      <p><b>Mobile Location: </b>{mobileAddress || "Unknown Address"}</p>
                      <p><b>Latitude:</b> {mobile_lat} <b>Longitude:</b> {mobile_lng}</p>
                      <p>
                        <b>Google Maps Link:</b> <a target="_blank" href={`https://maps.google.com/?q=${mobile_lat},${mobile_lng}`} style={{ cursor: "pointer", color: 'blue' }}>
                          https://maps.google.com/?q={mobile_lat},{mobile_lng}
                        </a>
                      </p>
                    </div>
                  </InfoWindow>
                )}
              </>
            )}
          </GoogleMap>
        </Box>
      </Modal>
    </div>
  );
};

export default WorkHomeLocationModal;

import React from "react";
import { GoogleMap, MarkerF as Marker } from "@react-google-maps/api";
import { CreatehubMapsProps } from "../../../@Types/ComponentsInterface/CreateHubProps";

const containerStyle = {
  width: "100%",
  height: "19.5rem",
};

const CreatehubMaps: React.FC<CreatehubMapsProps> = ({
  latit = 28.7136,
  lngit = 77.1294,
  onPinChange = () => {},
}) => {
  const center = { lat: latit, lng: lngit };
  const geocoder = new google.maps.Geocoder();

  const handleDragEnd = async (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();

      try {
        const response = await geocoder.geocode({ location: { lat, lng } });
        if (response.results.length > 0) {
          const result = response.results[0];
          const components = result.address_components;


          onPinChange({
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
            city: components.find((e) => e.types.includes("locality"))?.long_name || "",
            address: result.formatted_address,
            administrative_area_level_1: components.find((e) =>
              e.types.includes("administrative_area_level_1")
            )?.long_name || "",
            country: components.find((e) => e.types.includes("country"))?.long_name || "",
            postcode: components.find((e) => e.types.includes("postal_code"))?.long_name || "",
            utc_offset: 0, // You can calculate the UTC offset using another service if needed
          });
        }
      } catch (error) {
        console.error("Error in geocoding: ", error);
      }
    }
  };

  return (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
      <Marker
        position={center}
        icon={"/assets/icons/location-pin.png"}
        draggable={true}
        onDragEnd={handleDragEnd}
      />
    </GoogleMap>
  );
};

export default CreatehubMaps;

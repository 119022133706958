import React from "react";
import { DashboardFrontCardHyperTrackProps } from "../../../@Types/ComponentsInterface/DashboardFrontCardHyperTrackProps";

const DashboardFrontCardHyperTrack: React.FC<
  DashboardFrontCardHyperTrackProps
> = ({
  fromColorClass,
  toColorClass,
  textFirstLine,
  totalcount,
  imageURL,
  shorttext,
}) => {
  const cardStyle = {
    background: `linear-gradient(to right, ${fromColorClass}, ${toColorClass})`,
  };

  return (
    <div
      className={`flex-grow w-4/12	 rounded-lg bg-[#fff] p-4 mx-2 shadow h-28  ${fromColorClass} ${toColorClass}`}
      style={cardStyle}
    >
      <h4 className="mb-2">
        <b>{textFirstLine}</b>
      </h4>
      <div className="flex items-center">
        <img src={imageURL} alt="" className="w-8 h-8" />

        <p className="ml-2">
          {totalcount} <span>{shorttext}</span>
        </p>
      </div>
      
    </div>
  );
};
export default DashboardFrontCardHyperTrack;

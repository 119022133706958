import React, { Component } from "react";
import Switch from "react-switch";

interface SwitchExampleProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

class SwitchExample extends Component<SwitchExampleProps> {
  render() {
    const { checked, onChange } = this.props;

    return (
      <Switch
        onChange={onChange}
        checked={checked}
        offColor="#ff0000"
        uncheckedHandleIcon={<div style={{ color: '#fff', marginLeft: 30 }}>Disable</div>}
        checkedHandleIcon={<div style={{ color: '#fff', position: 'absolute', right: '40px' }}>Enable</div>}
        width={110}
        uncheckedIcon={false}  // No icon when unchecked
        checkedIcon={false}
      />
    );
  }
}

export default SwitchExample;

import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  FormControl,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Stack,
  Link
} from "@mui/material";
import { HyperTrackHome } from "../../../API/HyperTrack/HomePage";
import DashboardFrontCard from "../../../components/Layouts/DashboardFrontCardMobileTracker/DashboardFrontCard";
import DashboardFrontCardSingle from "../../../components/Layouts/DashboardFrontCardMobileCount/DashboardFrontCard";
import "react-datepicker/dist/react-datepicker.css";
import "animate.css";
import { CSVLink } from "react-csv";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
} from "recharts";
import MapIcon from "@mui/icons-material/Map";
import TreeMap from "../../../components/Layouts/TreeMap/index";
import GoogleMapApi from "../../../components/Layouts/GoogleMapMobileTracker/Maps";
import LinearProgress from "@mui/material/LinearProgress";
import "react-datepicker/dist/react-datepicker.css";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
const tagManagerArgs = {
  gtmId: "G-NWTLSSYVR2",
};
TagManager.initialize(tagManagerArgs);
console.log(tagManagerArgs);

const Homepage = () => {
  const COLORS = [
    "#EC609C",
    "#00CD5B",
    "#EC609C",
    "#00CD5B",
    "#EC609C",
    "#00CD5B",
    "#EC609C",
  ];

  const [mobileIotConnected, setMobileIotConnected] = useState<any>([]);
  const [mobileIotDisonnected, setMobileIotDisonnected] = useState<any>([]);
  const [mobileIotTotal, setMobileIotTotal] = useState<any>([]);
  const [fleetTypeData, setFleetTypeData] = useState<any>([]);
  const [cityWiseDataCount, setCityWiseDataCount] = useState<any>();
  const [fleetStatusData, setFleetStatusData] = useState<any>();
  const [tableDetailData, setTableDetailData] = useState<any>();
  const [tableDetailCSVData, setTableDetailCSVData] = useState<any>();  
  const [iotDisconnections, setIotDisconnections] = useState<any>();
  const [iotDisconnectionsData, setIotDisconnectionsData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [cardloader, setCardloader] = useState(true);
  const [fleetloader, setFleetloader] = useState(true);
  const [glanceloader, setGlanceloader] = useState(true);
  const [fleettypeloader, setFleettypeloader] = useState(true);




  const [selectedCityValue, setSelectedCityValue] = useState<any>();
  const [selectedClientValue, setSelectedClientValue] = useState<any>();
  const [selectedVehicleTypeValue, setSelectedVehicleTypeValue] = useState<any>();
  const [selectedDisconnectionValue, setSelectedDisconnectionValue] = useState<any>();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [merchantList, setMerchantList] = useState<any>();
  const [cityList, setCityList] = useState<any>();
  const [searchValInput, setSearchValInput] = useState<string | null>(null);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleAdd = (uid: {
    qr_code: any;
   
    row: { original: { qr_code: any} };
    
  }) => {
    //   setUserId(uid);
    navigate("/zypptracker/mobiletrackermap", {
    
      
      state: { qr_code: uid.qr_code },
    });
  };
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (!selectedOptions.includes(selectedValue)) {
      setSelectedOptions([...selectedOptions, selectedValue]);
    }
  };
  const handleSelectChangeCity = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedCityValue(event.target.value);
    console.log("New value set: ", event.target.value);
    // Here, you can perform any additional actions based on the new value
  };
  const handleSelectChangeClient = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedClientValue(event.target.value);
    console.log("New value set: ", event.target.value);
    // Here, you can perform any additional actions based on the new value
  };
  const handleSelectChangeVehicleType = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedVehicleTypeValue(event.target.value);
    console.log("New value set: ", event.target.value);
    // Here, you can perform any additional actions based on the new value
  };
  const handleSelectChangeDisconnection = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedDisconnectionValue(event.target.value);
    console.log("New value set: ", event.target.value);
    // Here, you can perform any additional actions based on the new value
  };
  const handleChipDelete = (value: string) => {
    const updatedOptions = selectedOptions.filter((option) => option !== value);
    setSelectedOptions(updatedOptions);
  };
  const starttimeFormat = (dt: { split: (arg0: string) => string }) => {
    // debugger; // eslint-disable-line no-debugger
    //alert('lllrr', dt)

    let fdate = "";
    if (dt) {
      fdate = dt.split(" ");
      if (fdate.length) {
        fdate = fdate[fdate.length - 1];
      }
    }
    return fdate;
  };
  const handleSearchClick = () => {
    setSearchVal(searchValInput);
  };
  const handleClearSearch = () => {
    setSearchVal("");
    setSearchValInput("");
  };
  const handleKeyPress = (event: { key: string; }) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };
  const pageRange = 5;
  const pageCount = Math.ceil(totalCount / rowsPerPage);
  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(1, Math.min(newPage + 1, pageCount));
    setPage(newPageNumber - 1);
  };
  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index + 1;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber - 1)}
          className={page === pageNumber - 1 ? "active" : "unactive"}
          style={{ marginLeft: "6px" }}
        >
          {pageNumber}
        </span>
      );
    });
  };
  useEffect(() => {
    setLoading(true);
    setCardloader(true);
    setFleetloader(true);
    setGlanceloader(true);
    setFleettypeloader(true);
    

    HyperTrackHome.api.merchantListHyperTrack().then((e) => {
      setMerchantList(e.merchant_list);
    });
    HyperTrackHome.api.cityListHyperTrack().then((e) => {
      setCityList(e.city_list);
    });
    HyperTrackHome.api
      .fleetStatus(
        selectedCityValue,
        selectedClientValue,
        selectedVehicleTypeValue,
        selectedDisconnectionValue,
        selectedOptions
      )
      .then((e) => {
        setFleetStatusData(e.vehicle_status_bifurcations);
        setFleetloader(false)
      });
    HyperTrackHome.api
      .cityTypeMapping(
        selectedCityValue,
        selectedClientValue,
        selectedVehicleTypeValue,
        selectedDisconnectionValue,
        selectedOptions
      )
      .then((e) => {
        setCityWiseDataCount(e.data.city_wise_bifurcations);
      });
    HyperTrackHome.api
      .mobilefleetTypeBifurcation(
        selectedCityValue,
        selectedClientValue,
        selectedVehicleTypeValue,
        selectedDisconnectionValue,
        selectedOptions
      )
      .then((e) => {
        setFleetTypeData(e.data.fleet_type_bifurcations);
        setFleettypeloader(false)
      });
    HyperTrackHome.api
      .mobileconnected(
        selectedCityValue,
        selectedClientValue,
        selectedVehicleTypeValue,
        selectedDisconnectionValue,
        selectedOptions
      )
      .then((e) => {
        setMobileIotConnected(e);
        setCardloader(false)

      });
    HyperTrackHome.api
        .mobiledisconnected(
          selectedCityValue,
          selectedClientValue,
          selectedVehicleTypeValue,
          selectedDisconnectionValue,
          selectedOptions
        )
        .then((e) => {
          setMobileIotDisonnected(e);
          console.log("params---", selectedOptions);
        });   
    HyperTrackHome.api
        .mobiletotal(
          selectedCityValue,
          selectedClientValue,
          selectedVehicleTypeValue,
          selectedDisconnectionValue,
          selectedOptions
        )
        .then((e) => {
          setMobileIotTotal(e);
          console.log("params---", selectedOptions);
        });     
    HyperTrackHome.api
      .mobileiotDayWise(
        selectedCityValue,
        selectedClientValue,
        selectedVehicleTypeValue,
        selectedDisconnectionValue,
        selectedOptions
      )
      .then((e) => {
        setIotDisconnections(e.day_wise_bifurcations);
        setIotDisconnectionsData(e);
        setGlanceloader(false)
      });
      HyperTrackHome.api
      .mobileexporttrackerDetail(null,null,null,null,null,null,null)
      .then((e) => {
        setTableDetailCSVData(e.mobile_tracker_details);
        setLoading(false);
      });
  }, [
    selectedCityValue,
    selectedClientValue,
    selectedVehicleTypeValue,
    selectedDisconnectionValue,
    selectedOptions,
  ]);
  useEffect(() => {
   
    HyperTrackHome.api
      .mobiletrackerDetail(
        selectedCityValue,
        selectedClientValue,
        selectedVehicleTypeValue,
        selectedDisconnectionValue,
        selectedOptions,
        rowsPerPage,
        page + 1,
        searchVal,
        
      )
      .then((e) => {
        setTableDetailData(e.mobile_tracker_details);
        if (e && typeof e?.total_rows !== "undefined") {
          setTotalCount(e?.total_rows);
        }
      });
  }, [
    selectedCityValue,
    selectedClientValue,
    selectedVehicleTypeValue,
    selectedDisconnectionValue,
    selectedOptions,
    page,
    searchVal
  ]);
 

  const getRandomColor = (index: number) => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const merchantCategoryTransformedData =
  cityWiseDataCount?.map((item: any, index: number) => ({
      name: item.city_name,
      Count: item.counts,
      fill: getRandomColor(index),
    })) || [];
    
  

  const tabledetailHeaders = [
    { label: "Qr Code", key: "qr_code" },
    { label: "User Id", key: "user_id" },
    { label: "Rider Name", key: "rider_name" },
    { label: "Rider Mobile", key: "rider_mobile" },
    { label: "IMEI Number", key: "imei" },
    { label: "Chasis Number", key: "chasisNum" },
    { label: "Fleet Name", key: "fleet_name" },
    { label: "Ride Start Time", key: "ride_start_time" },
    { label: "Iot Type", key: "iot_type" },
    { label: "Vehicle Type", key: "vehicle_type" },
    { label: "Hub Name", key: "hub_name" },
    { label: "City Name", key: "city_name" },
    { label: "Merchant Name", key: "merchant_name" },
    { label: "Connection Status", key: "connection_status" },
  ];

  

  const tableHeaderCellStyle = {
    fontWeight: 600,
    fontSize: "14px",
    color: "#455A64",
    font: "Rubik",
  };
  const tableBodyCellStyle = {
    fontWeight: 400,
    fontSize: "14px",
    color: "#A4A2A2",
    font: "Rubik",
  };

  const getOptionLabel = (value: number): string => {
    switch (value) {
      case 0:
        return "No Data Available";
      case 1:
        return "1 Day";
      case 2:
        return "2-4 Days";
      case 3:
        return "5-7 Days";
      case 4:
        return "8-14 Days";
      case 5:
        return "15-30 Days";
      case 6:
        return "More Than a Month";
      default:
        return "";
    }
  };
  return (
    <>
      <div className="flex justify-evenly bg-[#D8FFEA] px-2  pt-8 pb-4">
        <div className="flex items-center w-[10%] bg-white border border-[#ECEEFF]	border-solid rounded-full px-2 py-1">
          <div>
            <Typography className="font-inter text-[#727597] text-xs mr-2 font-normal">
              City:{" "}
            </Typography>
          </div>
          <FormControl fullWidth>
            <select
              id="merchant"
              value={selectedCityValue}
              onChange={handleSelectChangeCity}
            >
              <option value="all" selected>
                All
              </option>
              {cityList?.map((city: string, index: number) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>
          </FormControl>
        </div>
        <div className="flex items-center w-[10%] bg-white border border-[#ECEEFF]	border-solid rounded-full px-2 py-1">
          <div>
            <Typography className="font-inter text-[#727597] text-xs mr-2 font-normal">
              Client:{" "}
            </Typography>
          </div>
          <FormControl fullWidth>
            <select
              id="merchant"
              value={selectedClientValue}
              onChange={handleSelectChangeClient}
            >
              <option value="all" selected>
                All
              </option>
              {merchantList?.map((merchant: string, index: number) => (
                <option key={index} value={merchant}>
                  {merchant}
                </option>
              ))}
            </select>
          </FormControl>
        </div>
        <div className="flex items-center w-[15%] bg-white border border-[#ECEEFF]	border-solid rounded-full px-2 py-1">
          <div>
            <Typography className="font-inter text-[#727597] text-xs mr-2 font-normal">
              Vehicle Type:{" "}
            </Typography>
          </div>
          <FormControl>
            <select
              id="merchant"
              value={selectedVehicleTypeValue}
              onChange={handleSelectChangeVehicleType}
            >
              <option value="all" selected>
                All
              </option>
              <option value="Loader">Loader</option>
              <option value="Fleet">2-W</option>
            </select>
          </FormControl>
        </div>
        <div className="flex items-center w-[23%] bg-white border border-[#ECEEFF]	border-solid rounded-full px-2 py-1">
          <div>
            <Typography className="font-inter text-[#727597] text-xs mr-2 font-normal">
              Data Disconnection:{" "}
            </Typography>
          </div>
          <FormControl>
            <select
              id="merchant"
              value={selectedDisconnectionValue}
              onChange={handleSelectChangeDisconnection}
            >
              <option value="all" selected>
                All
              </option>
              <option value="Connected">
                Connected
              </option>
              <option value="Disconnected">Disconnected</option>
            </select>
          </FormControl>
        </div>
        <div className="flex items-center w-[22%] bg-white border border-[#ECEEFF]	border-solid rounded-full px-2 py-1">
          <div>
            <Typography className="font-inter text-[#727597] text-xs mr-2 font-normal">
              Data Status:{" "}
            </Typography>
          </div>
          <FormControl>
            <select
              id="merchant"
              onChange={handleChange}
              value={selectedOptions}
            >
              <option value="0" selected>
                No Data Available
              </option>
              <option value="1">1 Day</option>
              <option value="2">2-4 Days</option>
              <option value="3">5-7 Days</option>
              <option value="4">8-14 Days</option>
              <option value="5">15-30 Days</option>
              <option value="6">More Than a Month</option>
              <option value="7">Connected</option>
            </select>
            {/* <MultipleSelectChips /> */}
          </FormControl>
        </div>
      </div>
      <div className="flex bg-[#D8FFEA] px-12 py-2">
        <div className="flex justify-evenly">
          {selectedOptions.map((value) => (
            <div
              key={value}
              className="border-[1px] flex items-center justify-between rounded-full px-2 py-1 border-[#00CB60] text-[#00CB60] mr-3"
            >
              {getOptionLabel(parseInt(value, 10))}
              <button onClick={() => handleChipDelete(value)}>
                <img
                  src="/assets/hyper-track-icon/cross.svg"
                  className="ml-2"
                />
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-slate-100 font-rubik text-sm">
        <div className="flex">
          <div className="w-full	 flex flex-wrap animate__animated animate__fadeInLeft">
          
          {cardloader ? (
              <div className="w-full h-60">
              <LinearProgress
                sx={{
                  backgroundColor: "white",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#00CD5B",
                  },
                }}
              />
              </div>
            ):(
            <div className="w-full flex">
              <DashboardFrontCard
                textFirstLine="Mobile Connected"
                textSecondLine="IOT Connected"
                textThirdLine="IOT Disconnected"
                clientData={mobileIotConnected?.mobile_connected}
                iotConnectedNumber={mobileIotConnected?.iot_connection_counts}
                iotDisconnectedNumber={mobileIotConnected?.iot_disconnection_counts}
                imageURL="/assets/hyper-track-icon/blue-mobile.svg"
                lineImageURL="/assets/hyper-track-icon/GreenLine.png"
                greylineImageURL="/assets/hyper-track-icon/Line-grey.svg"
                totalData={0}
                livetext="Live"
                notlivetext="Not Live"
                iotconnectedlivecounts={mobileIotConnected?.iot_connected_live_counts}
                iotconnectednonlivecounts={mobileIotConnected?.iot_connected_non_live_counts}
                iotdisconnectedlivecounts={mobileIotConnected?.iot_disconnected_live_counts}
                iotdisconnectednonlivecounts={mobileIotConnected?.iot_disconnected_non_live_counts}

                
              ></DashboardFrontCard>

              <DashboardFrontCard
                textFirstLine="Mobile Disconnected"
                textSecondLine="IOT Connected"
                textThirdLine="IOT Disconnected"
                totalData={0}
                clientData={mobileIotDisonnected?.mobile_disconnected}
                iotConnectedNumber={mobileIotDisonnected?.iot_connection_counts}
                iotDisconnectedNumber={mobileIotDisonnected?.iot_disconnection_counts}
                imageURL="/assets/hyper-track-icon/red-mobile.svg"
                lineImageURL="/assets/hyper-track-icon/RedLine.png"
                greylineImageURL="/assets/hyper-track-icon/Line-grey.svg"
                livetext="Live"
                notlivetext="Not Live"
                iotconnectedlivecounts={mobileIotDisonnected?.iot_connected_live_counts}
                iotconnectednonlivecounts={mobileIotDisonnected?.iot_connected_non_live_counts}
                iotdisconnectedlivecounts={mobileIotDisonnected?.iot_disconnected_live_counts}
                iotdisconnectednonlivecounts={mobileIotDisonnected?.iot_disconnected_non_live_counts}

              ></DashboardFrontCard>
               <DashboardFrontCardSingle
                textFirstBox="Grand Total of Mobiles"
                textSecondBox="Grand Total IOTs"
                firstclientData={mobileIotTotal?.total_mobile_counts}
                secondclientData={mobileIotTotal?.total_iot_counts}
                secondimageURL="/assets/hyper-track-icon/blue-iot-icon.svg"
                imageURL="/assets/hyper-track-icon/green-mobile.svg"
                lineImageURL="/assets/hyper-track-icon/blue-line.svg"
              ></DashboardFrontCardSingle> 
            </div>
              )}

            <div className="flex w-full">
              <div className="flex-grow  w-6/12 rounded-lg bg-[#fff] m-4 shadow px-2">
                <div className="flex justify-between items-center border-b">
                  <h2 className="text-center ml-2 mx-1 mt-2 font-bold text-base text-black pb-2">
                  Mobile Disconnection at a glance
                  </h2>
                </div>
                { glanceloader ? (
                <div className="w-full">
                <LinearProgress
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
                </div>
              ):(
                <div className="flex justify-center px-4 py-2">
                  <div className="w-2/5">
                    <table className="w-full border-collapse">
                      <tbody>
                        {iotDisconnections?.map((row: any) => (
                          <tr className="border-b">
                            <td className="text-left ml-1 font-semibold text-sm py-2 pl-1">
                              {row.days_bins}
                            </td>
                            <td className="text-[#00CD5B]  font-bold text-sm pb-2">
                              {row.total_counts}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="w-3/5">
                    <div className="flex flex-col ml-2">
                      <div className="border-2 rounded-lg	text-left	mt-2 border-green-500 p-4">
                        <div className="flex">
                          <div className="border-r-2 border-black-500 pr-4">
                            <p>No Disconnection</p>
                            <h1 className="text-2xl font-bold mt-1">
                              {iotDisconnectionsData?.connected_counts}
                            </h1>
                          </div>
                          <div className="pl-4">
                            <p>No Data Available</p>
                            <h1 className="text-2xl font-bold mt-1">
                              {iotDisconnectionsData?.no_data_available}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="border-2 rounded-lg	text-left	 border-green-500 p-4 mt-4">
                        <p>Total IOT Connections</p>
                        <h1 className="text-2xl font-bold mt-1">
                          {iotDisconnectionsData?.total_iot_counts}
                        </h1>
                      </div>
                    </div>
                  </div>
                  {/* <img src={"/assets/icons/map.svg"} alt="" className="w-9/12" /> */}
                </div>
              )}
              </div>
              <div className="flex-grow w-3/12 rounded-lg bg-[#fff] m-4 shadow pb-1">
                <div className="flex justify-between items-center border-b">
                  <p className="text-center ml-4 mx-1 mt-2 pb-2 font-bold text-base text-black">
                Fleet type mapping
              </p>
               </div>
            <div className="px-5 py-2 h-64" style={{ overflowY: "scroll" }}>
              {fleettypeloader ? (
                <LinearProgress
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
              ) :  (
                <table className="w-full border-collapse">
                  <tbody>
                    {fleetTypeData?.map((fleetType: any, index: number) => (
                      <tr key={index} className="border-b">
                        <td className="text-left ml-1 font-semibold text-sm py-2 pl-1">
                          {fleetType.fleet_name}
                        </td>
                        <td className="text-[#00CD5B]  font-bold text-sm py-2">
                          {fleetType.vehicle_counts}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
             
              )}
            </div>
          </div>
          <div className="flex-grow w-3/12 rounded-lg bg-[#fff] m-4 shadow">
            <div className="flex justify-between items-center border-b px-5">
              <h2 className="text-center mt-2 pb-2 font-bold text-base text-[#263238]">
              Fleet Status Overview
              </h2>
            </div>
            {fleetloader ? (
            <div className="w-full">
              <LinearProgress
                sx={{
                  backgroundColor: "white",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#00CD5B",
                  },
                }}
              />
              </div>
            ):(
            <div
              className="py-1 px-5 h-[370px]"
              style={{ overflowY: "auto" }}
            >
              
                <table className="w-full border-collapse">
                  <tbody>
                    {fleetStatusData.map((riders: any, index: number) => (
                        <tr className="border-b" key={index}>
                          <td className="text-left ml-1 font-semibold text-sm pb-2 pl-1">
                            {riders.vehicle_status}
                          </td>
                          <td className="text-[#00CD5B]  font-bold text-sm pb-2">
                            {riders.vehicle_counts}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
             
            </div>
            )}
          </div>
              
            </div>
          </div>
         
        </div>
        {/* { Creating second div elements for card view } */}
        <div className="flex">
        
          <div className="flex-grow w-5/12 rounded-lg bg-[#fff] m-4 shadow">
            <div className="flex justify-between items-center border-b">
              <h2 className="text-center mx-1 mt-2  font-bold ml-5 text-base text-[#263238] pb-2">
                City Mapping
              </h2>
            </div>
            <div className="py-1 pb-4">
              {merchantCategoryTransformedData &&
              merchantCategoryTransformedData.length > 0 ? (
                <BarChart
                  width={400}
                  height={350}
                  data={merchantCategoryTransformedData}
                  margin={{ left: -10, right: 10, top: -5, bottom: 8 }}
                >
                  <CartesianGrid vertical={false} horizontal={false} />
                  <XAxis dataKey="name" interval={0} tick={{ fontSize: 10 }}>
                    <Label
                      value="City Type ->"
                      fontSize={11}
                      // position="insideLeft"
                      dy={16} // Adjust the vertical position
                    />
                  </XAxis>
                  <YAxis tick={{ fontSize: 10 }} />
                  <Tooltip />
                  <Bar dataKey="Count" />
                </BarChart>
              ) : (
                <LinearProgress
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex-grow w-7/12 rounded-lg bg-[#fff] m-4 shadow">
            <div className="flex justify-between items-center border-b">
              <h2 className="text-center ml-4 mx-1 mt-2  font-bold text-base text-black pb-2">
                Client Mapping
              </h2>
            </div>
            <div className="py-1 pb-4">
             <TreeMap 
              selectedCityValue={selectedCityValue}
              selectedClientValue={selectedClientValue}
              selectedVehicleTypeValue={selectedVehicleTypeValue}
              selectedDisconnectionValue={selectedDisconnectionValue}
              selectedOptions={selectedOptions}
             /> 
            </div>
          </div>
        </div>
        

       
        <div className="flex-grow h-96 rounded-lg bg-[#fff] m-4 shadow px-5 font-rubik">
          

          <div style={{ width: "100%", height: "19rem" }}>
            <GoogleMapApi
              selectedCityValue={selectedCityValue}
              selectedClientValue={selectedClientValue}
              selectedVehicleTypeValue={selectedVehicleTypeValue}
              selectedDisconnectionValue={selectedDisconnectionValue}
              selectedOptions={selectedOptions}
            />
          </div>
        </div>
        <div className="flex-grow px-1 bg-white rounded-lg shadow m-4  pb-4">
          <div className="flex w-full justify-between mt-5 mb-3">
            
            <div className="flex mt-3">
              <CSVLink
                data={tableDetailCSVData || []}
                headers={tabledetailHeaders}
                filename={`MobileTrakerRiderData`}
                style={{ marginLeft: "10px" }}
              >
                <Button
                  style={{
                    backgroundColor: "#00CD5B",
                    padding: "8px 8px",
                    color: "#fff",
                    borderRadius: "5px",
                    width: "100%",
                    textTransform: "none",
                  }}
                >
                  Export Data
                </Button>
              </CSVLink>
            </div>

            <div className="flex mt-3">
              <TextField
                placeholder="Search  QR Code"
                id="outlined-basic"
                variant="outlined"
                onChange={(search: any) =>
                  setSearchValInput(search.target.value)
                }
                value={searchValInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={handleClearSearch}>
                      <ClearIcon style={{ cursor: "pointer" }} />
                    </InputAdornment>
                  ),
                  style: { height: "40px" },
                }}
                onKeyDown={handleKeyPress}
              />
              <Button
                sx={{
                  bgcolor: "#FF725E33",
                  color: "black",
                  ml: 2,
                  mr: 2,
                  height: "40px",
                  textTransform: "none",
                  "&:hover": { bgcolor: "#FF725E16" },
                }}
                onClick={handleSearchClick}
              >
                Search
              </Button>
            </div>
          </div> 
          {loading ? (
            <div className="w-full">
            <LinearProgress
              className="pl-6 pr-6"
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
                width: "100%",
              }}
            />
            </div>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ display: "flex" }}>
                  <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "130px" }}
                    >
                      QR Code
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "130px" }}
                    >
                      User Id
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "200px" }}
                    >
                      Rider Name
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "150px" }}
                    >
                      Rider Mobile
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "150px" }}
                    >
                      IMEI Number
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "200px" }}
                    >
                      Chasis Number
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "150px" }}
                    >
                      Fleet Name
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "150px" }}
                    >
                       Ride Start Time
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "150px" }}
                    >
                      Iot Type
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "150px" }}
                    >
                      Vehicle Type
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "150px" }}
                    >
                      Hub Name
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "100px" }}
                    >
                      City Name
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "150px" }}
                    >
                      Merchant Name
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "200px" }}
                    >
                      Connection Status
                    </TableCell>
                    <TableCell
                      style={tableHeaderCellStyle}
                      sx={{ width: "50px" }}
                    >
                     Map
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableDetailData?.map((row: any) => (
                    <TableRow sx={{ display: "flex" }} key={row.index}>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "130px" }}
                      >
                        {row.qr_code}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "130px" }}
                      >
                        {row.user_id}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "200px" }}
                      >
                        {row.rider_name}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "150px" }}
                      >
                        {row.rider_mobile}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "150px" }}
                      >
                        {row.imei}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "200px" }}
                      >
                        {row.chasisNum}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "150px" }}
                      >
                        {row.fleet_name}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "150px" }}
                      >
                        {starttimeFormat(row.ride_start_time)}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "150px" }}
                      >
                        {row.iot_type}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "150px" }}
                      >
                        {row.vehicle_type}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "150px" }}
                      >
                        {row.hub_name}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "100px" }}
                      >
                        {row.city_name}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "150px" }}
                      >
                        {row.merchant_name}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ width: "200px" }}
                      >
                        {row.connection_status}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        <Stack direction="row" spacing={0}>
                          <Link
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              handleAdd(row);
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "#00CD5B" }}
                            >
                              <MapIcon />
                            </Typography>
                          </Link>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div className="pl-2 pt-4 pb-2 pr-16">
            <div
              className="pagination"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <button
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 0}
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "20px",
                    marginRight: "6px",
                  }}
                >
                  {"<"}
                </button>
                {renderPageNumbers()}
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page >= pageCount - 1}
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "20px",
                    marginLeft: "6px",
                  }}
                >
                  {">"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <h3 className="text-center">
          © 2023 Zypp Electric. All rights reserved.
        </h3>
      </div>
    </>
  );
};

export default Homepage;
function fetchData() {
  throw new Error("Function not implemented.");
}

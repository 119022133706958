/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from "@mui/material";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import "./AppHeader.css";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import PeopleIcon from "@mui/icons-material/PeopleOutlineOutlined";
import AddBoxIcon from "@mui/icons-material/AddBoxOutlined";
import MapIcon from "@mui/icons-material/MapOutlined";
import HelpIcon from "@mui/icons-material/SupportOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import AccountIcon from "@mui/icons-material/AccountCircleOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Badge, Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { ClientApi } from "../../../../API/ClientApi";
import { TickerData } from "../../../../@Types/ComponentsInterface/TickerDataResponse";
import Tooltip from "@mui/material/Tooltip";
const AdminHeader: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorNl, setAnchorNl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const tickerRef = useRef<HTMLDivElement | null>(null);

  const handleLogout = () => {
    localStorage.removeItem("userData");
    navigate("/admin");
  };

  const isMenuItemActive = (menuItem: string) => {
    return location.pathname === menuItem ? "active" : "";
  };

  const navigateToTeamAndSpocs = () => {
    navigate("/zypptracker/distanceReport");
  };
  const navigateToHome = () => {
    navigate("/zypptracker");
  };
  const navigateToTicket = () => {
    navigate("/zypptracker/distanceReportYearWise");
  };
  const navigateToRiderWholeJourney = () => {
    navigate("/zypptracker/riderwholejourney");
  };
  const navigateToThreeWheeler = () => {
    navigate("/zypptracker/threewheeler");
  };
  const navigateToThreewheelerTracker = () => {
    navigate("/zypptracker/threewheelertracker");
  };
  const navigateToIotTracker = () => {
    navigate("/zypptracker/iottracker");
  };
  const navigateToMobileTracker = () => {
    navigate("/zypptracker/mobiletracker");
  };
  const navigateToMobileData = () => {
    navigate("/zypptracker/mobiledatatracker");
  };
  const navigateToProfile = () => {
    navigate("/profile");
  };

  const merchantLogo = localStorage.getItem("merchantLogoUrl") || undefined;
  const merchantName = localStorage.getItem("merchantName") || undefined;
  const [tickerData, setTickerData] = useState<any>();
  const [notificationdata, setNotificationdata] = useState<any>([]);
  const [notificationalert, setNotificationalert] = useState<any>([]);

  const ls = require("localstorage-ttl");
  const cachedTickerData = ls.get("tickerData");
  useEffect(() => {
    ClientApi.api.notificationList().then((e) => {
      setNotificationdata(e);
    });
    ClientApi.api.notificationalert().then((e) => {
      setNotificationalert(e.flag);
    });
    const fetchData = async () => {
      try {
        if (cachedTickerData) {
          setTickerData(JSON.parse(cachedTickerData));
        } else {
          const clientApi = new ClientApi();
          const token = localStorage.getItem("access_token");

          const tickerResponse = await clientApi.tickerData(token);
          setTickerData(tickerResponse.tickerData);
          ls.set(
            "tickerData",
            JSON.stringify(tickerResponse.tickerData),
            86400
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (tickerRef.current) {
      tickerRef.current.style.animationPlayState = "paused";
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (tickerRef.current) {
      tickerRef.current.style.animationPlayState = "running";
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const notificationopen = Boolean(anchorNl);
  const notificationhandleClose = () => {
    setAnchorNl(null);
  };
  const notificationhandleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorNl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="w-full text-sm text-[#455A64] font-rubik font-semibold">
      <AppBar
        position="sticky"
        className="bg-gradient-to-r from-slate-100 to-50"
        style={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #EBEBEB",
          boxShadow: "0px 4px 30px 4px #00000005",
        }}
      >
        <Toolbar className="justify-between">
          <div className="flex items-center ">
            <img
              src={"/assets/icons/invoice-logo.svg"}
              alt="Logo"
              className="h-8 mr-4"
            />
          </div>
          {/* Scrolling Marquee for News Ticker */}
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              cursor: isHovered ? "default" : "pointer",
              marginLeft: "80px",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="flex items-center"
          >
            <div
              ref={tickerRef}
              style={{
                animation: "marquee 20s linear infinite",
                width: "100%",
                color: "black",
              }}
            >
              {tickerData?.map((data: TickerData, index: number) => (
                <span key={index} className="pr-6">
                  {data.url ? (
                    <>
                      <a href={data.url} target="_blank" rel="noreferrer">
                        {data.heading}
                      </a>
                      {index !== tickerData.length - 1 && (
                        <span className="pl-6 text-lg">|</span>
                      )}
                    </>
                  ) : (
                    <>
                      <span>{data.heading}</span>
                      {index !== tickerData.length - 1 && (
                        <span className="pl-6 text-lg">|</span>
                      )}
                    </>
                  )}
                </span>
              ))}
            </div>
          </div>

          <div className="flex items-center w-2/5 justify-end">
            <div className="flex  items-center justify-end">
              <Box sx={{ flexShrink: 0, ml: 0.75 }}>
                <Tooltip title="Notification">
                  <IconButton
                    onClick={notificationhandleClick}
                    sx={{ ml: 2 }}
                    aria-controls={
                      notificationopen ? "account-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={notificationopen ? "true" : undefined}
                  >
                    {notificationalert == true ? (
                      <>
                        {" "}
                        <Badge
                          variant="dot"
                          overlap="circular"
                          color="success"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <NotificationsOutlinedIcon
                            color="success"
                            fontSize="large"
                          />
                        </Badge>
                      </>
                    ) : (
                      <>
                        <NotificationsOutlinedIcon
                          color="success"
                          fontSize="large"
                        />
                      </>
                    )}
                  </IconButton>
                </Tooltip>

                {notificationdata && notificationdata.length === 0 ? (
                  <>
                    <Menu
                      anchorEl={anchorNl}
                      id="account-menu"
                      open={notificationopen}
                      onClose={notificationhandleClose}
                      onClick={notificationhandleClose}
                    >
                      <MenuItem>No Notification alert</MenuItem>
                    </Menu>
                  </>
                ) : (
                  <>
                    <Menu
                      anchorEl={anchorNl}
                      id="account-menu"
                      open={notificationopen}
                      onClose={notificationhandleClose}
                      onClick={notificationhandleClose}
                    >
                      {notificationdata.map(
                        (
                          data: {
                            message:
                              | string
                              | number
                              | boolean
                              | React.ReactElement<
                                  any,
                                  string | React.JSXElementConstructor<any>
                                >
                              | Iterable<React.ReactNode>
                              | React.ReactPortal
                              | null
                              | undefined;
                          },
                          index: any
                        ) => (
                          <MenuItem key={`cell-${index}`}>
                            {data.message}
                          </MenuItem>
                        )
                      )}
                    </Menu>
                  </>
                )}
              </Box>
            </div>
            {location.pathname !== "/" && (
              <div className="flex  items-center justify-end">
                <Tooltip title="Account">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <AccountCircleOutlinedIcon
                      color="success"
                      fontSize="large"
                    />
                  </IconButton>
                </Tooltip>
                {/* Menu */}
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                >
                  {/* <MenuItem onClick={navigateToProfile}>
                    <ListItemIcon>
                      <AccountIcon fontSize="small" />
                    </ListItemIcon>
                    Profile
                  </MenuItem> */}
                  {/* <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                  </MenuItem> */}
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>

                {/* <p className="text-black">{merchantName}</p> */}
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {/* Space for the menu content */}
      <div
        className="h-30 "
        style={{ backgroundColor: "#FFFFFF", border: "0px solid #EBEBEB" }}
      >
        <AppBar position="static" color="inherit">
          <Toolbar className="p-4">
            <a
              onClick={navigateToHome}
              className={`cursor-pointer ${isMenuItemActive(
                "/zypptracker"
              )} mr-2 ml-1`}
            >
              <HomeIcon fontSize="small" className="mr-1  " /> Home
            </a>
            {/* <BagIcon className="mr-1" /> New Fleet Demand
            <ClipboardIcon className="mr-1" /> Payout */}
            <a
              onClick={navigateToTeamAndSpocs}
              className={`cursor-pointer ${isMenuItemActive(
                "/zypptracker/distanceReport"
              )} mr-2 ml-1`}
            >
              <PeopleIcon fontSize="small" className="mr-1 " />
              Distance Report
            </a>
            {/* <CubeIcon className="mr-1" /> On-Demand */}
            <a
              onClick={navigateToTicket}
              className={`cursor-pointer ${isMenuItemActive(
                "/zypptracker/distanceReportYearWise"
              )} mr-2 ml-1`}
            >
              <HelpIcon fontSize="small" className="mr-1 " />
              Year and Month Summary
            </a>
            <a
              onClick={navigateToRiderWholeJourney}
              className={`cursor-pointer ${isMenuItemActive(
                "/zypptracker/riderwholejourney"
              )} mr-2 ml-1`}
            >
              <HelpIcon fontSize="small" className="mr-1 " />
              Rider Whole Journey
            </a>
            <a
              onClick={navigateToThreeWheeler}
              className={`cursor-pointer ${isMenuItemActive(
                "/zypptracker/threewheeler"
              )} mr-2 ml-1`}
            >
              <HelpIcon fontSize="small" className="mr-1 " />
              3 Wheeler
            </a>
            <a
              onClick={navigateToThreewheelerTracker}
              className={`cursor-pointer ${isMenuItemActive(
                "/zypptracker/threewheelertracker"
              )} mr-2 ml-1`}
            >
              <HelpIcon fontSize="small" className="mr-1 " />
              L5 Journey
            </a>
            <a
              onClick={navigateToMobileData}
              className={`cursor-pointer ${isMenuItemActive(
                "/zypptracker/mobiledatatracker"
              )} mr-2 ml-1`}
            >
              <HelpIcon fontSize="small" className="mr-1 " />
             Mobile Data Tracker
            </a>
            <a
              onClick={navigateToIotTracker}
              className={`cursor-pointer ${isMenuItemActive(
                "/zypptracker/iottracker"
              )} mr-2 ml-1`}
            >
              <HelpIcon fontSize="small" className="mr-1 " />
             IOT Tracker
            </a>
            <a
              onClick={navigateToMobileTracker}
              className={`cursor-pointer ${isMenuItemActive(
                "/zypptracker/mobiletracker"
              )} mr-2 ml-1`}
            >
              <HelpIcon fontSize="small" className="mr-1 " />
             Mobile Tracker
            </a>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
};

export default AdminHeader;
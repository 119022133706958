import React, { useEffect, useState } from "react";
import {
  Toolbar,
  Typography,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  LinearProgress,
} from "@mui/material";

import { RiderKycApi } from "../../../API/RiderKycApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router";
import ToggleButton from "../../ToggleButton";
import "./ManageStore.css";


export default function RiderDetailKyc() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState<any>();
  const [filterMerchant, setFilterMerchant] = useState<any>();

  const [selectedCity, setSelectedCity] = useState<any>();
  const [clientReportList, setClientReportList] = useState<any[]>([]);
  const [merchantList, setMerchantList] = useState<any[]>([]);
  const [citylist, setCityList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isPrimaryClientEnabled, setIsPrimaryClientEnabled] = useState(true); // State for primary client toggle
  const [isSecondaryClientEnabled, setIsSecondaryClientEnabled] = useState(true); // State for secondary client toggle
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [cityError, setCityError] = useState(""); // State for city error
  const [merchantError, setMerchantError] = useState(""); // State for merchant error
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Track if the submit button should be disabled

  const pageRange = 5;
  const cityData = [
    {
      cityId: 1,
      name: "Delhi",
      code: "DL"
    },
    {
      cityId: 2,
      name: "Noida",
      code: "Noida"
    },
    {
      cityId: 3,
      name: "Gurgaon",
      code: "GGN"
    },
    {
      cityId: 4,
      name: "Bangalore",
      code: "BLR"
    },
    {
      cityId: 5,
      name: "Hyderabad",
      code: "HYD"
    },
    {
      cityId: 6,
      name: "Faridabad",
      code: "FDB"
    },
    {
      cityId: 7,
      name: "Mumbai",
      code: "MUM"
    },
    {
      cityId: 8,
      name: "Kolkata",
      code: "KOL"
    },
    {
      cityId: 9,
      name: "Bhopal",
      code: "BHP"
    }
  ];

  // Function to find the city name based on cityCode
  const getCityNameByCode = (code: any) => {
    const city = cityData.find(city => city.code === code);
    return city ? city.name : "Unknown City";
  };

  const fetchClientReportList = () => {
    setLoading(true);
    RiderKycApi.api?.clientReportList(page + 1, rowsPerPage, filterMerchant)
      .then((response) => {
        setLoading(false);
        if (response.data.clientOnBoardingConfigs.length > 0) {
          setClientReportList(response.data.clientOnBoardingConfigs);
          setTotalCount(response.data.records);
        } else {
          setClientReportList([]);
          setTotalCount(1);
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });
  };

  // Call fetchClientReportList in useEffect
  useEffect(() => {
    fetchClientReportList();
  }, [page, rowsPerPage, filterMerchant]);
  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const cityCode = e.target.value;
    setSelectedCity(cityCode);
    setCityError(""); // Clear error

    // Reset merchant selection
    setSelectedMerchant("");
    setIsButtonDisabled(!(cityCode && selectedMerchant));
  };

  const handleMerchantChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const merchantId = parseInt(e.target.value, 10); // Convert value to number
    setSelectedMerchant(merchantId);
    setMerchantError(""); // Clear error
    setIsButtonDisabled(!(selectedCity && merchantId));

  };

  useEffect(() => {
    if (selectedCity && selectedMerchant) {
      const matchingConfig = clientReportList.find(
        (config) =>
          config.cityCode === selectedCity &&
          config.merchantId === selectedMerchant
      );

      if (matchingConfig) {
        setIsPrimaryClientEnabled(!matchingConfig.primaryDisable);
        setIsSecondaryClientEnabled(!matchingConfig.secondaryDisable);
      } else {
        // Reset switches if no matching config is found
        setIsPrimaryClientEnabled(true);
        setIsSecondaryClientEnabled(true);
      }
    }
  }, [selectedCity, selectedMerchant]);
  useEffect(() => {
    setLoading(true);


    RiderKycApi.api?.AllCityList()
      .then((response) => {
        setLoading(false);
        if (response.data.length > 0) {
          setCityList(response.data);
        } else {
          setCityList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });

    RiderKycApi.api?.merchantDataList()
      .then((response) => {
        setLoading(false);
        if (response.data.length > 0) {
          setMerchantList(response.data);
        } else {
          setMerchantList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });
  }, [page, rowsPerPage]);


  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    setPage(Math.max(0, Math.min(newPage, pageCount - 1)));
  };
  const handleClose = () => {
    setShowSnackbar(false);
    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    setCityError(""); // Reset error messages
    setMerchantError(""); // Reset error messages
  };
  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${page === pageNumber ? "active" : "unactive"
            }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };
  const token = localStorage.getItem("access_token");
  const handleTogglePrimaryClient = () => {
    setIsPrimaryClientEnabled((prev) => !prev);
  };

  const handleToggleSecondaryClient = () => {
    setIsSecondaryClientEnabled((prev) => !prev);
  };


  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Validate selections
    if (!selectedCity) {
      setCityError("Please select a city");
    } else {
      setCityError("");
    }

    if (!selectedMerchant) {
      setMerchantError("Please select a merchant");
    } else {
      setMerchantError("");
    }

    // Prepare the request body
    const requestBody = {
      code: selectedCity,
      merchantId: selectedMerchant,
      primaryDisable: !isPrimaryClientEnabled,
      secondaryDisable: !isSecondaryClientEnabled,

    };

    try {
      const response = await fetch(
        "https://stag-micro.bcykal.com/mobycy/clientDash/update/clientMapping",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        setShowSuccessSnackbar(true);
        setSelectedCity("");
        setSelectedMerchant("");
        fetchClientReportList();


      } else {
        const errorData = await response.json();
        setSnackbarMessage(errorData.message || "Select City & Merchant");
        setShowErrorSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Network error");
      setShowErrorSnackbar(true);
    }
  };




  return (
    <>
      <div className="bg-gradient-to-r from-slate-100 to-slate-50 pl-8 pr-8">
        <div style={{ height: "40px", width: "100%" }}>
          <Toolbar
            sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}
            className="bg-gradient-to-r from-slate-100 to-slate-50"
          >
            <Typography
              sx={{ flex: "1 1 75%", textAlign: "left" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              <b>Manage Store</b>
            </Typography>

            <Typography
              sx={{
                flex: "1 1 100%",
                textAlign: "right",
                marginRight: "48px",
              }}
              component="div"
            >
              <HomeIcon className="mb-1" />
              {" / "}
              Manage Store
            </Typography>
          </Toolbar>
        </div>
        <div className="bg-[#fff] p-8 rounded-lg pb-4">
          <form onSubmit={handleSubmit}>
            <Grid container spacing={5} className="w-full flex justify-between">
              <Grid item md={6}>
                <div className="mt-4">
                  <p className="text-left mb-4 text-sm font-bold">City <sup style={{ color: 'red' }}>*</sup></p>
                  <FormControl fullWidth>
                    <select
                      id="merchant-select"
                      onChange={handleCityChange}
                      value={selectedCity} // bind to selectedCity state

                      style={{
                        border: "1px solid #c0baba",
                        padding: 7,
                        borderRadius: 3,
                        height: 56,
                      }}
                    >
                      <option value="" selected>Select City</option> {/* Default option */}

                      {citylist.map((merchant, index) => (
                        <option
                          key={index}
                          value={merchant.code}
                          data-merchant-id={merchant.code}
                          data-merchant-name={merchant.name}
                        >
                          {merchant.name}
                        </option>
                      ))}
                    </select>
                    {cityError && <div className="text-left text-[#cf3917]">{cityError}</div>}

                  </FormControl>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="w-full mt-4">
                  <p className="text-left mb-4 text-sm font-bold">
                    Merchant <sup style={{ color: 'red' }}>*</sup>
                  </p>
                  <FormControl fullWidth>
                    <select
                      id="merchant"
                      onChange={handleMerchantChange}
                      value={selectedMerchant} // bind to selectedMerchant state

                      style={{
                        border: 1,
                        borderColor: "#c0baba",
                        borderStyle: "solid",
                        padding: 7,
                        borderRadius: 3,
                        height: 56,
                      }}


                    >
                      <option value="" selected>Select Merchant</option> {/* Default option */}

                      {merchantList.map((merchant, index) => (
                        <option key={index} value={merchant.merchantId}>
                          {merchant.merchantName}
                        </option>
                      ))}
                    </select>
                    {merchantError && <div className="text-left text-[#cf3917]">{merchantError}</div>}

                  </FormControl>
                </div>
              </Grid>

            </Grid>

            <hr className="my-7" />
            <Grid container spacing={5} className="w-full flex justify-between">
              <Grid item xs={6}>
                <div className="w-full flex justify-between border-solid border-slate-300	 border items-center p-3">
                  <div className="flex">
                    <p className="m-0">Set Primary Client</p>
                  </div>
                  <div className="flex">
                    <ToggleButton
                      checked={isPrimaryClientEnabled}
                      onChange={handleTogglePrimaryClient}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="w-full flex justify-between border-solid border-slate-300	 border items-center p-3">
                  <div className="flex ">
                    <p className="m-0">Set Secondary Client</p>
                  </div>
                  <div className="flex">
                    <ToggleButton
                      checked={isSecondaryClientEnabled}
                      onChange={handleToggleSecondaryClient}
                    />
                  </div>
                </div>
              </Grid>

            </Grid>
            <div className="w-full flex  text-right justify-end">
              <div className="w-60 flex text-right">

                <button
                  type="submit"
                  disabled={isButtonDisabled}
                  className={`px-12 py-4 mt-8 text-white rounded-lg w-full ${isButtonDisabled ? "bg-gray-400" : "bg-[#FE5B44]"
                    }`}
                  style={{
                    cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
                    opacity: isButtonDisabled ? 0.6 : 1
                  }}

                >
                  Submit
                </button>
              </div>
            </div>
          </form>
          <Snackbar open={showSuccessSnackbar} autoHideDuration={6000} onClose={handleClose}>
            <Alert sx={{ width: "100%" }} onClose={handleClose} severity="success">
              UPDATE SUCCESSFULLY!
            </Alert>
          </Snackbar>
          <Snackbar open={showErrorSnackbar} autoHideDuration={6000} onClose={handleClose}>
            <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
              {SnackbarMessage.replace(/_/g, " ")}
            </Alert>
          </Snackbar>
          <div>
            <hr className="my-7" />
          </div>
          <div className="mt-3">
            <Grid container spacing={5} className="w-full flex justify-between">
              <Grid item md={6}>

                <Typography
                  sx={{ flex: "1 1 75%", textAlign: "left" }}
                  variant="h6"
                  component="div"
                >
                  <b>Client Report</b>
                </Typography>
              </Grid>
              <Grid item md={6}>
                <div className="w-full flex justify-end">
                  <div className="flex">
                  <p className="text-left mb-4 text-sm font-bold">
                   Filter 
                  </p>
                  </div>
                  <div className="flex ml-1">
                  <FormControl fullWidth>
                    <select
                      id="merchant"
                      onChange={(e) => {
                        setFilterMerchant(e.target.value);
                      }}

                      style={{
                        border: 1,
                        borderColor: "#c0baba",
                        borderStyle: "solid",
                        padding: 7,
                        borderRadius: 3,
                        height: 36,
                        width:180
                      }}


                    >
                      <option value="" selected>Select Merchant</option> {/* Default option */}

                      {merchantList.map((merchant, index) => (
                        <option key={index} value={merchant.merchantId}>
                          {merchant.merchantName}
                        </option>
                      ))}
                    </select>

                  </FormControl>
                  </div>
                </div>
              </Grid>

            </Grid>
            <div>
              {loading ? (
                <LinearProgress
                  className="pl-4 pr-4"
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
              ) : (
                <>
                  <TableContainer
                    component={Paper}
                    className="mt-3"
                    sx={{ boxShadow: "none" }}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>City</b>
                          </TableCell>
                          <TableCell >
                            <b>Merchant Name</b>
                          </TableCell>
                          <TableCell >
                            <b>Primary Client</b>
                          </TableCell>
                          <TableCell >
                            <b>Secondary Client</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {clientReportList.map((doc, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {/* Find and display the city name using the cityCode from doc */}
                              {getCityNameByCode(doc.cityCode)} {/* Displays the city name */}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {doc.merchantName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <span
                                style={{
                                  color: doc.primaryDisable ? 'red' : 'green', // Green if true, Red if false
                                  fontWeight: 'bold', // Optional: Make the text bold
                                }}
                              >
                                {doc.primaryDisable ? 'Disabled' : 'Enabled'} {/* Show text based on value */}
                              </span>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <span
                                style={{
                                  color: doc.secondaryDisable ? 'red' : 'green', // Green if true, Red if false
                                  fontWeight: 'bold', // Optional: Make the text bold
                                }}
                              >
                                {doc.secondaryDisable ? 'Disabled' : 'Enabled'} {/* Show text based on value */}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="pl-6 pt-4 pb-2 pr-16">
                    <div className="pagination" style={{ display: "flex", justifyContent: "space-between" }}>
                      <select
                        className="pl-2 pr-2 rounded"
                        value={rowsPerPage}
                        onChange={(e) => setRowsPerPage(Number(e.target.value))}
                        style={{ backgroundColor: "#EBEBEB", fontFamily: "Rubik", fontSize: "16px" }}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <div>
                        <button
                          onClick={() => handlePageChange(page - 1)}
                          disabled={page === 0}
                          style={{
                            fontFamily: "Rubik",
                            fontSize: "20px",
                            marginRight: "6px",
                          }}
                        >
                          {"<"}
                        </button>
                        {renderPageNumbers()}
                        <button
                          onClick={() => handlePageChange(page + 1)}
                          disabled={page >= pageCount - 1}
                          style={{
                            fontFamily: "Rubik",
                            fontSize: "20px",
                            marginLeft: "6px",
                          }}
                        >
                          {">"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleClose}>
                    <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">No data found</Alert>
                  </Snackbar>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
